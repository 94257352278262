import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import {action, autorun} from 'mobx';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import { withStyles } from '@material-ui/core/styles';
import {format} from 'date-fns';
import { subWeeks,subYears } from 'date-fns/esm';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveSunburst } from '@nivo/sunburst';
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Sync } from '@material-ui/icons';

const lineChartProperties = {
  margin:{
    "top": 10,
    "right": 200,
    "bottom": 80,
    "left": 60
  },
  xScale:{
      "type": "point"
  },
  yScale:{
      "type": "linear",
      "stacked": false,
      "min": 0,
      "max": "auto"
  },
  axisTop:null,
  axisRight:null,
  axisBottom:{
      "orient": "bottom",
      "tickSize": 5,
      "tickPadding": 5,
      "tickRotation": -60,
      "legend": " ",
      "legendOffset": 36,
      "legendPosition": "middle"
  },
  dotSize:10,
  dotColor:"inherit:darker(0.3)",
  dotBorderWidth:2,
  dotBorderColor:"#ffffff",
  enabledotLabel:true,
  dotLabel:"y",
  dotLabelYOffset:-12,
  enableArea:false,
  areaOpacity:0.2,
  motionStiffness:90,
  motionDamping:15,
  colors:"category10",
  curve:"monotoneX",
  legends:[
      {
          "anchor": "top-right",
          "direction": "column",
          "justify": false,
          "translateX": 100,
          "translateY": 0,
          "itemsSpacing": 0,
          "itemDirection": "left-to-right",
          "itemWidth": 80,
          "itemHeight": 20,
          "itemOpacity": 0.75,
          "symbolSize": 12,
          "symbolShape": "circle",
          "symbolBorderColor": "rgba(0, 0, 0, .5)",
          "effects": [
              {
                  "on": "hover",
                  "style": {
                      "itemBackground": "rgba(0, 0, 0, .03)",
                      "itemOpacity": 1
                  }
              }
          ]
      }
  ]
}

const styles = theme => ({
    dateModal: {
      margin : '10% auto',
      width: 'fit-content',
      '&>div':{
        padding: '1%'
      }
    },
    loader:{
      /*border: "16px solid #f3f3f3", 
      borderTop: "16px solid #3498db", 
      borderRadius: "50%",*/
      width: "120px",
      height: "120px",
      animation: "spin 2s linear infinite"
    },
    loaderMini:{
      /*border: "16px solid #f3f3f3", 
      borderTop: "16px solid #3498db", 
      borderRadius: "50%",*/
      width: "30px",
      height: "30px",
      animation: "spin 2s linear infinite"
    },
    '@keyframes spin': {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(-360deg)" }
    },
    chartSubContainer:{
      height:'70vh',
      textAlign:'center',
      marginBottom:'5vh'
    },
    sunburstSubContainer:{
      height:'70vh',
      textAlign:'center',
      marginBottom:'5vh'
    },
    tableContainer: {
      height: 320,
    },
    slabContainer:{
      width:'725px', padding:'10px 20px',margin:"10px 0 20px 0", backgroundColor:'#FFFFFF',
    },
    slabNeutral:{
      width:'280px', height:'100px', paddingTop:'5px', paddingRight:'20px' ,paddingLeft:'20px', backgroundColor:'#FFFFFF',
    },
    slabRed:{
      width:'280px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#F44336',
    },
    slabGreen:{
      width:'280px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#4CAF50',
    }
  });
  @withStyles(styles)
  @inject('applicationStore')


  export default class Dashboard extends Component {
    displayName = Dashboard.name
    state = {
      dataDashboard: [],
      dataVolume:[],
      dataCogestion:{},
      seuilAugment:5,
      seuilReduce:5,
      ModalOpen:false,
      blacklist:this.props.applicationStore.prestationBlacklist,
      loadingBlacklist:false,
    };

    handleModalOpen = () => {
      this.setState({
        ModalOpen: true
      })
    }

    handleModalClose = () => {
      this.setState({
        ModalOpen: false
      })
    }

    getTDB = (d1,d2,d3,d4) => {
      fetch('api/Generale/getStatDashboard', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, 
          Date1: format(d1, 'YYYY-MM-DD'), 
          Date2: format(d2, 'YYYY-MM-DD'),
          Agences: Object.keys(this.props.applicationStore.ListeAgences)})
      }).then(data => data.json()).then(dataDashboard => this.setState({dataDashboard}));
      fetch('api/Generale/getRepartitionVolumes', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, 
          Date1: format(d1, 'YYYY-MM-DD'), 
          Date2: format(d2, 'YYYY-MM-DD'),
          Agences: Object.keys(this.props.applicationStore.ListeAgences)})
      }).then(data => data.json()).then(dataVolume => this.setState({dataVolume}));
      fetch('api/Generale/getCogestion', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, 
          Date1: format(d1, 'YYYY-MM-DD'), 
          Date2: format(d2, 'YYYY-MM-DD'),
          Agences: Object.keys(this.props.applicationStore.ListeAgences)})
      }).then(data => data.json()).then(dataCogestion => this.setState({dataCogestion}));
    };
    transferToSeuils = () => {   
      const response = fetch('api/MainData/updateSeuils', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, sAugment: this.props.applicationStore.seuilAugment, sReduce: this.props.applicationStore.seuilReduce})
      });
    }

    transferToBools = () => {   
      const response = fetch('api/MainData/updateBools', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Alouer: this.props.applicationStore.InAlouer, Loue: this.props.applicationStore.PropLoue,CaTTC: this.props.applicationStore.CaTTC, CaRevenus: this.props.applicationStore.CaRevenus})
      }).then(() => {
        this.getTDB(this.props.applicationStore.GeneraleDate1, this.props.applicationStore.GeneraleDate2, this.props.applicationStore.GeneraleDate3, this.props.applicationStore.GeneraleDate4);
      });
    }

    transferToBlacklist = () => {
      this.setState({
        loadingBlacklist: true
      })   
      const response = fetch('api/MainData/updateBlacklist', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Blacklist: this.state.blacklist, Agences: Object.keys(this.props.applicationStore.ListeAgences)})
      }).then(() => {
        this.setState({
          loadingBlacklist: false
        })
        this.getTDB(this.props.applicationStore.GeneraleDate1, this.props.applicationStore.GeneraleDate2, this.props.applicationStore.GeneraleDate3, this.props.applicationStore.GeneraleDate4)
        this.updateBlacklist()
      });
    }

    @action handleSeuilChange = event => {
     if(event.target.name === 'seuilAugment')
     {
      this.props.applicationStore.seuilAugment = event.target.value;
     }
     if(event.target.name === 'seuilReduce')
     {
      this.props.applicationStore.seuilReduce = event.target.value;
     }
     this.transferToSeuils();
    };

    @action handleBoolChange = event => {
      if(event.target.name === 'InAlouer')
      {
       this.props.applicationStore.InAlouer = event.target.checked;
      }
      if(event.target.name === 'PropLoue')
      {
       this.props.applicationStore.PropLoue = event.target.checked;
      }
      if(event.target.name === 'CaTTC')
      {
       this.props.applicationStore.CaTTC = event.target.checked;
      }
      if(event.target.name === 'CaRevenus')
      {
       this.props.applicationStore.CaRevenus = event.target.checked;
      }
      this.transferToBools();
     };

     @action handleBlacklistChange = event => {
      this.setState({
        blacklist: event.target.value
      })
    }
  
    @action handleAllBlacklisted = () => {
      this.setState({
        blacklist: this.props.applicationStore.ListePrestations.reduce((acc,val) => {
          let name = val.name.trim().toUpperCase();
          if(!acc.includes(name)){
            acc.push(name)
          }
          return acc;
        },[])
      })
    };
  
    @action handleNoBlacklisted = () => {
      this.setState({
        blacklist: []
      })
    };
  
    @action handleRefreshBlacklist = () => {
      this.transferToBlacklist();
    };
  
    @action updateBlacklist = () => {
      this.props.applicationStore.prestationBlacklist = this.state.blacklist
    }

    @action handleDateChange = ranges => {
      this.props.applicationStore.GeneraleDate1 = ranges.selection.startDate;
      this.props.applicationStore.GeneraleDate2 = ranges.selection.endDate;
    };
    @action handleDateChangeN = ranges => {
      this.props.applicationStore.GeneraleDate3 = ranges.selection.startDate;
      this.props.applicationStore.GeneraleDate4 = ranges.selection.endDate;
    };

    generaleAutorun = autorun(() => {
      if(this.props.applicationStore.Logged){
        this.setState({
          dataDashboard:[],
          dataCogestion:{},
          dataVolume:[]
        })
        this.props.applicationStore.prestationBlacklist;
        this.props.applicationStore.InAlouer;
        this.props.applicationStore.CaTTC;
        this.props.applicationStore.CaRevenus;
        this.props.applicationStore.GeneraleDate1;
        this.props.applicationStore.GeneraleDate2;
        this.props.applicationStore.GeneraleDate3;
        this.props.applicationStore.GeneraleDate4;
        if(this.props.applicationStore.ListePrestations.length > 0){
          this.setState({
            blacklist: this.props.applicationStore.prestationBlacklist
          })
        }
        //this.getTDB(this.props.applicationStore.GeneraleDate1, this.props.applicationStore.GeneraleDate2, this.props.applicationStore.GeneraleDate3, this.props.applicationStore.GeneraleDate4);
      }
    });

    render() {
      const selectedDate1 = this.props.applicationStore.GeneraleDate1;
      const selectedDate2 = this.props.applicationStore.GeneraleDate2;
      const selectedDate3 = this.props.applicationStore.GeneraleDate3;
      const selectedDate4 = this.props.applicationStore.GeneraleDate4;
      const selectionRange = {
        startDate: selectedDate1,
        endDate: selectedDate2,
        key: 'selection',
      }
      const selectionRangeN = {
        startDate: selectedDate3,
        endDate: selectedDate4,
        key: 'selection',
        color: 'gray',
      }
      var PrestNames = [];
      return (
        <div>
        {!this.props.applicationStore.Logged ? (
          <>
          <h1>Connexion requise</h1>
            <Logon/>           
          </>
        ):(
        <>
           <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.props.applicationStore.NomUtilisateur + ' ' + this.props.applicationStore.PrenomUtilisateur}</h1>
                <h3>Bienvenue dans votre interface de gestion et de consultation de statistiques.</h3>
                <h4>Vos paramètres d'affichage</h4>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Paper className={this.props.classes.slabContainer}>
                  <p><b>Mise en valeur de la progression comparée à N-1</b></p>
                  <Grid container spacing={24}>
                    <Grid item>
                      <Paper className={this.props.classes.slabGreen}>
                      Seuil de mise en valeur
                        <TextField
                          select
                          id="seuilAugment"
                          name="seuilAugment"
                          value={this.props.applicationStore.seuilAugment}
                          onChange={this.handleSeuilChange}
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          <MenuItem value={1}>1%</MenuItem>
                          <MenuItem value={2}>2%</MenuItem>
                          <MenuItem value={3}>3%</MenuItem>
                          <MenuItem value={4}>4%</MenuItem>
                          <MenuItem value={5}>5%</MenuItem>
                          <MenuItem value={6}>6%</MenuItem>
                          <MenuItem value={7}>7%</MenuItem>
                          <MenuItem value={8}>8%</MenuItem>
                          <MenuItem value={9}>9%</MenuItem>
                          <MenuItem value={10}>10%</MenuItem>
                          <MenuItem value={15}>15%</MenuItem>
                          <MenuItem value={20}>20%</MenuItem>
                        </TextField>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={this.props.classes.slabRed}>
                        Seuil de mise en valeur
                        <TextField
                          select
                          id="seuilReduce"
                          name="seuilReduce"
                          value={this.props.applicationStore.seuilReduce}
                          onChange={this.handleSeuilChange}
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          <MenuItem value={1}>-1%</MenuItem>
                          <MenuItem value={2}>-2%</MenuItem>
                          <MenuItem value={3}>-3%</MenuItem>
                          <MenuItem value={4}>-4%</MenuItem>
                          <MenuItem value={5}>-5%</MenuItem>
                          <MenuItem value={6}>-6%</MenuItem>
                          <MenuItem value={7}>-7%</MenuItem>
                          <MenuItem value={8}>-8%</MenuItem>
                          <MenuItem value={9}>-9%</MenuItem>
                          <MenuItem value={10}>-10%</MenuItem>
                          <MenuItem value={15}>-15%</MenuItem>
                          <MenuItem value={20}>-20%</MenuItem>
                        </TextField>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={this.props.classes.slabContainer}>
                  <p><b>Calcul du taux d'occupation</b></p>
                  <Grid container spacing={24}>
                    <Grid item>
                    Mandats inactifs dans "Parc actif"
                      <Switch id="InAlouer" name="InAlouer" checked={this.props.applicationStore.InAlouer} onChange={this.handleBoolChange}/>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={this.props.classes.slabContainer}>
                  <Grid container spacing={8} style={{margin:"0"}}>
                    {this.props.applicationStore.ListePrestations.length > 0 ? (
                      <>
                        <Grid item style={{padding:"8px 4px"}}>
                          Prestations exclues : 
                        </Grid>
                        <Grid item>
                          <Select multiple value={this.state.blacklist} onChange={this.handleBlacklistChange} renderValue={selected => selected.join(', ')} style={{width:"300px"}}>
                            {this.props.applicationStore.ListePrestations.map(prest => {
                              let name = prest.name;
                                if(!PrestNames.includes(name)){
                                  PrestNames.push(name);
                                  return(
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={Object.values(this.state.blacklist).indexOf(name) > -1} />
                                      <ListItemText primary={prest.name} />
                                    </MenuItem>
                                  )
                                }
                              }
                            )}
                          </Select>
                        </Grid>
                        {this.props.applicationStore.ListePrestations.length > 10 ? (
                          <>
                            <Grid item>
                              <Button variant='contained' color='primary' onClick={this.handleAllBlacklisted}>Toutes</Button>
                            </Grid>
                            <Grid item>
                              <Button variant='contained' color='primary' onClick={this.handleNoBlacklisted}>Aucune</Button>
                            </Grid>
                          </>
                        ):""}
                        <Grid item>
                          <Button variant='contained' color='secondary' onClick={this.handleRefreshBlacklist}>Actualiser</Button>
                        </Grid>
                        <Grid item style={{textAlign:"center",visibility:this.state.loadingBlacklist ? "visible" : "hidden"}}>
                          <Sync className={this.props.classes.loaderMini}/>
                        </Grid>
                      </>
                    ):(
                      <Grid style={{textAlign:"center"}}>
                        <Sync className={this.props.classes.loader}/>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
                <Paper className={this.props.classes.slabContainer}>
                  <p><b>Calcul du chiffre d'affaire</b></p>
                  <Grid container spacing={24}>
                    <Grid item xs={6} style={{textAlign:"center"}}>
                      HT
                      <Switch id="CaTTC" name="CaTTC" checked={this.props.applicationStore.CaTTC} onChange={this.handleBoolChange} color="default"/>
                      TTC
                    </Grid>
                    <Grid item xs={6} style={{textAlign:"center"}}>
                      Encaissements
                      <Switch id="CaRevenus" name="CaRevenus" checked={this.props.applicationStore.CaRevenus} onChange={this.handleBoolChange} color="default"/>
                      C.A.
                    </Grid>
                  </Grid>
                </Paper>
                <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                <h4>Activité récente de vos agences</h4>
                {this.state.dataDashboard.length > 0 ? (
                  <>
                    <Grid container className={this.props.classes.chartSubContainer}>
                      <ResponsiveLine
                      data={
                        Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                        let tempdata = this.state.dataDashboard.filter(chunck => chunck.refAgence == element);
                          acc.push({
                            id:this.props.applicationStore.ListeAgences[element],
                            data:tempdata.map(n=>({
                              x:n.dateStat,
                              y:n.ca
                            }))
                          })
                          return acc
                      }, [])}
                      axisLeft={{
                          "orient": "left",
                          "tickSize": 5,
                          "tickPadding": 5,
                          "tickRotation": 0,
                          "legend": "Chiffre d'affaire",
                          "legendOffset": -50,
                          "legendPosition": "middle"
                      }}
                      tooltipFormat={value =>
                        Number(value).toLocaleString('fr')+' €'
                      }
                      {...lineChartProperties}
                    />
                    </Grid>
                    <Grid container className={this.props.classes.chartSubContainer}>
                      <ResponsiveLine
                        data={
                          Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                          let tempdata = this.state.dataDashboard.filter(chunck => chunck.refAgence == element);
                          acc.push({
                              id:this.props.applicationStore.ListeAgences[element],
                              data:tempdata.map(n=>({
                                x:n.dateStat,
                                y:n.nbActifs
                              }))
                            })
                            return acc
                          
                        }, [])}
                        axisLeft={{
                            "orient": "left",
                            "tickSize": 5,
                            "tickPadding": 5,
                            "tickRotation": 0,
                            "legend": "Mandats actifs",
                            "legendOffset": -50,
                            "legendPosition": "middle"
                        }}
                        tooltipFormat={value =>
                          Number(value).toLocaleString('fr')
                        }
                        {...lineChartProperties}
                      />
                    </Grid>
                    <Grid container className={this.props.classes.chartSubContainer}>
                        <ResponsiveLine
                        data={
                          Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                          let tempdata = this.state.dataDashboard.filter(chunck => chunck.refAgence == element);
                            acc.push({
                              id:this.props.applicationStore.ListeAgences[element],
                              data:tempdata.map(n=>({
                                x:n.dateStat,
                                y:n.dureeMoySejour
                              }))
                            })
                            return acc
                          
                        }, [])}
                        axisLeft={{
                            "orient": "left",
                            "tickSize": 5,
                            "tickPadding": 5,
                            "tickRotation": 0,
                            "legend": "Durée Moyenne",
                            "legendOffset": -50,
                            "legendPosition": "middle"
                        }}
                        tooltipFormat={value =>
                          Number(value).toLocaleString('fr',{maximumFractionDigits:1})+' jours'
                        }
                        {...lineChartProperties}
                      />
                    </Grid>
                    <Grid container className={this.props.classes.chartSubContainer}>
                      
                        <ResponsiveLine
                        data={
                          Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                          let tempdata = this.state.dataDashboard.filter(chunck => chunck.refAgence == element);
                            acc.push({
                              id:this.props.applicationStore.ListeAgences[element],
                              data:tempdata.map(n=>({
                                x:n.dateStat,
                                y:n.tauxOccupation
                              }))
                            })
                            return acc
                          
                        }, [])}
                        axisLeft={{
                            "orient": "left",
                            "tickSize": 5,
                            "tickPadding": 5,
                            "tickRotation": 0,
                            "legend": "Taux d'occupation",
                            "legendOffset": -50,
                            "legendPosition": "middle"
                        }}
                        tooltipFormat={value =>
                          Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' %'
                        }
                        {...lineChartProperties}
                      />
                      
                    </Grid>
                    <Grid container className={this.props.classes.chartSubContainer}>
                      <ResponsiveBar
                        data={
                          Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                            let tempdata = this.state.dataDashboard.filter(chunck => chunck.refAgence == element);
                            let NbContrats = 0;
                            let NbJours = 0;
                            for (var i=0;i<tempdata.length;i++){
                              NbContrats += tempdata[i].nbContrats;
                              NbJours += tempdata[i].nbJoursVendus;
                            }
                            acc.push({
                              "refAgence":this.props.applicationStore.ListeAgences[element],
                              "Volumes Contrats":NbContrats,
                              "Volumes Nuitées":NbJours
                            })
                            return acc
                          }, [])}
                        indexBy="refAgence"
                        keys={['Volumes Contrats','Volumes Nuitées']}
                        groupMode="stacked"
                        labelSkipWidth={24}
                        labelSkipHeight={18}
                        padding={0.1}
                        colors="category10"
                        margin={{
                          "top": 10,
                          "right": 200,
                          "bottom": 80,
                          "left": 60
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            "orient": "bottom",
                            "tickSize": 5,
                            "tickPadding": 5,
                            "tickRotation": -60,
                            "legend": "",
                            "legendOffset": 36,
                            "legendPosition": "middle"
                        }}
                        axisLeft={{
                            "orient": "left",
                            "tickSize": 5,
                            "tickPadding": 5,
                            "tickRotation": 0,
                            "legend": "Volumes",
                            "legendOffset": -50,
                            "legendPosition": "middle"
                        }}
                        legends={[
                          {
                              "anchor": "top-right",
                              "dataFrom":"keys",
                              "direction": "column",
                              "justify": false,
                              "translateX": 100,
                              "translateY": 0,
                              "itemsSpacing": 0,
                              "itemDirection": "left-to-right",
                              "itemWidth": 80,
                              "itemHeight": 20,
                              "itemOpacity": 0.75,
                              "symbolSize": 12,
                              "symbolShape": "circle",
                              "symbolBorderColor": "rgba(0, 0, 0, .5)",
                              "effects": [
                                  {
                                      "on": "hover",
                                      "style": {
                                          "itemBackground": "rgba(0, 0, 0, .03)",
                                          "itemOpacity": 1
                                      }
                                  }
                              ]
                          }
                      ]}
                      tooltipFormat={value=>Number(value).toLocaleString('fr')}
                      />
                    </Grid>
                  </>
                ):(
                  /*<Grid style={{textAlign:"center"}}>
                    <Sync className={this.props.classes.loader}/>
                  </Grid>*/
                  <br/>
                )}
                {this.state.dataVolume.length > 0 ? (
                  <Grid container className={this.props.classes.sunburstSubContainer}>
                    
                      <p style={{marginRight: 200, marginLeft: 60}}>Répartition des Volumes Contrats</p>
                      <ResponsiveSunburst
                        data = {
                          {
                            "name":"",
                            "children": Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                              let tempdata = this.state.dataVolume.filter(chunck => chunck.refAgence == element);
                                acc.push({
                                  name:this.props.applicationStore.ListeAgences[element],
                                  children:tempdata.map(n=>({
                                    name:n.name,
                                    val:n.nbContrats
                                  }))
                                })
                                return acc
                            }, [])
                        }
                        }
                        identity='name'
                        value='val'
                        colors="category10"
                        borderWidth={0.5}
                        margin={{
                          "top": 10,
                          "right": 200,
                          "bottom": 80,
                          "left": 60
                        }}
                      />
                    
                  </Grid>
                ):(
                  /*<Grid style={{textAlign:"center"}}>
                    <Sync className={this.props.classes.loader}/>
                  </Grid>*/
                  <br/>
                )}
                {Object.keys(this.state.dataCogestion).length > 0 ? (
                  <>
                    <Grid container className={this.props.classes.sunburstSubContainer}>
                      <p style={{marginRight: 200, marginLeft: 60}}>Part de Cogestion en Volume Contrats</p>
                      <ResponsiveSunburst
                        data = {
                          {
                            "name":"",
                            "children": 
                            this.state.dataCogestion.data ? Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                              let tempdata = this.state.dataCogestion.data.filter(chunck => chunck.refAgence == element);
                                acc.push({
                                  name:this.props.applicationStore.ListeAgences[element],
                                  children:tempdata.map(n=>({
                                    name:n.name,
                                    val:n.nbContrats
                                  }))
                                })
                                return acc
                            }, []) : []
                        }
                        }
                        identity='name'
                        value='val'
                        colors="category10"
                        borderWidth={3}
                        margin={{
                          "top": 10,
                          "right": 200,
                          "bottom": 80,
                          "left": 60
                        }}
                      />
                    </Grid>
                    <Grid container className={this.props.classes.sunburstSubContainer}>
                      <p style={{marginRight: 200, marginLeft: 60}}>Part de cogestion en Volume CA</p>
                      <ResponsiveSunburst
                        data = {
                          {
                            "name":"",
                            "children": 
                            this.state.dataCogestion.data ? Object.keys(this.props.applicationStore.ListeAgences).reduce((acc,element) => {
                              let tempdata = this.state.dataCogestion.data.filter(chunck => chunck.refAgence == element);
                                acc.push({
                                  name:this.props.applicationStore.ListeAgences[element],
                                  children:tempdata.map(n=>({
                                    name:n.name,
                                    val:n.ca
                                  }))
                                })
                                return acc
                            }, []) : []
                        }
                        }
                        identity='name'
                        value='val'
                        colors="category10"
                        borderWidth={3}
                        margin={{
                          "top": 10,
                          "right": 200,
                          "bottom": 80,
                          "left": 60
                        }}
                      />
                    </Grid>
                  </>
                ):(
                  /*<Grid style={{textAlign:"center"}}>
                    <Sync className={this.props.classes.loader}/>
                  </Grid>*/
                  <br/>
                )}
              </Grid>
            </Grid>
        </>
      )
    }
    </div>
  );
  }
}