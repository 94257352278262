import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
}  from '@material-ui/core';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { render } from 'react-dom'
import { ResponsivePie } from '@nivo/pie'
import { Save, Sync, Warning } from '@material-ui/icons';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export'

const CAPOPieProperties = {
  margin:{
    "top": 40,
    "right": 100,
    "bottom": 80,
    "left": 80
  },
  innerRadius:0.5,
  padAngle:0.7,
  cornerRadius:3,
  colors:"set2",
  colorBy:"id",
  borderWidth:1,
  borderColor:"inherit:darker(0.2)",
  radialLabelsSkipAngle:10,
  radialLabelsTextXOffset:6,
  radialLabelsTextColor:"#333333",
  radialLabelsLinkOffset:0,
  radialLabelsLinkDiagonalLength:16,
  radialLabelsLinkHorizontalLength:24,
  radialLabelsLinkStrokeWidth:1,
  radialLabelsLinkColor:"inherit",
  sliceLabel:(e =>
    Number(e.value).toLocaleString('fr',{maximumFractionDigits:0})
  ),
  slicesLabelsSkipAngle:360*5/100,
  slicesLabelsTextColor:"#333333",
  animate:true,
  motionStiffness:90,
  motionDamping:15,
  defs:[
      {
          "id": "dots",
          "type": "patternDots",
          "background": "inherit",
          "color": "rgba(255, 255, 255, 0.3)",
          "size": 4,
          "padding": 1,
          "stagger": true
      },
      {
          "id": "lines",
          "type": "patternLines",
          "background": "inherit",
          "color": "rgba(255, 255, 255, 0.3)",
          "rotation": -45,
          "lineWidth": 6,
          "spacing": 10
      }
  ]
}

const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
  },
  tableOld:{
    color: 'gray', 
  },
  tableCurrentWarning:{
    color: 'red',
    fontWeight: 'bold',
    backgroundColor: 'rgb(255, 240, 239)',
  },
  slabNeutral:{
    width:'200px', height:'100px', paddingTop:'5px', paddingRight:'20px' ,paddingLeft:'20px', backgroundColor:'#FFFFFF',
  },
  slabRed:{
    width:'200px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#F44336',
  },
  slabGreen:{
    width:'200px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#4CAF50',
  }
});

@withStyles(styles)
@inject('applicationStore')

export default class CAPO extends Component {
  displayName = CAPO.name
  state = {
    graphData: [],
    graphDataN: [],
    ModalOpen:false,
    loading:true,
    loadingN:true,
    selectedAgencies:Object.keys(this.props.applicationStore.CAOrigineAgences),
    loadingAgencies:false,
    mountLoaded:false,
    blacklist:this.props.applicationStore.prestationBlacklist,
    loadingBlacklist:false,
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }
   
  getTitleCA(){
    let title = 'Chiffre d\'affaire par origine de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.CAOrigineAgences).length > 1)
    {
      title = 'Chiffre d\'affaire par origine des agences ';
    }
    Object.keys(this.props.applicationStore.CAOrigineAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.CAOrigineAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.CAOrigineAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.CAOrigineAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.CAOrigineAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.CAOrigineAgences[element];
          }
        }
      }

    });
    return title + temp;
  } 
  getCAPO = () => {
    this.setState({
      loading: true,
      loadingN: true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "CAPO", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Capo/getStatCapo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.CAPODate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.CAPODate2, 'YYYY-MM-DD')})
    }).then(graphData => graphData.json()).then(graphData => this.setState({graphData,loading:false}));
    fetch('api/Capo/getStatCapo', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.CAPODate3, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.CAPODate4, 'YYYY-MM-DD')})
    }).then(graphDataN => graphDataN.json()).then(graphDataN => this.setState({graphDataN,loadingN:false}));
  }

  transferToBools = () => {   
    const response = fetch('api/MainData/updateBools', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Alouer: this.props.applicationStore.InAlouer, TauxLocatGlobal: this.props.applicationStore.TauxLocatGlobal,CaTTC: this.props.applicationStore.CaTTC, CaRevenus: this.props.applicationStore.CaRevenus})
    }).then(() => this.getCAPO());
  }

  transferToCAPOAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    }) 
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "ca_origine"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getCAPO()
      this.updateAgencies(Agences)
    });
  }

  transferToBlacklist = () => {
    this.setState({
      loadingBlacklist: true
    })   
    const response = fetch('api/MainData/updateBlacklist', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Blacklist: this.state.blacklist, Agences: Object.keys(this.props.applicationStore.ListeAgences)})
    }).then(() => {
      this.setState({
        loadingBlacklist: false
      })
      this.getCAPO()
      this.updateBlacklist()
    });
  }

  @action handleBoolChange = event => {
    if(event.target.name === 'CaTTC')
    {
     this.props.applicationStore.CaTTC = event.target.checked;
    }
    if(event.target.name === 'CaRevenus')
    {
     this.props.applicationStore.CaRevenus = event.target.checked;
    }
    this.transferToBools();
  };

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToCAPOAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.CAOrigineAgences = Agences
  };

  @action handleBlacklistChange = event => {
    this.setState({
      blacklist: event.target.value
    })
  }

  @action handleAllBlacklisted = () => {
    this.setState({
      blacklist: this.props.applicationStore.ListePrestations.reduce((acc,val) => {
        let name = val.name.trim().toUpperCase();
        if(!acc.includes(name)){
          acc.push(name)
        }
        return acc;
      },[])
    })
  };

  @action handleNoBlacklisted = () => {
    this.setState({
      blacklist: []
    })
  };

  @action handleRefreshBlacklist = () => {
    this.transferToBlacklist();
  };

  @action updateBlacklist = () => {
    this.props.applicationStore.prestationBlacklist = this.state.blacklist
  }

  @action handleDateChange = ranges => {
    this.props.applicationStore.CAPODate1 = ranges.selection.startDate;
    this.props.applicationStore.CAPODate2 = ranges.selection.endDate;
    this.props.applicationStore.CAPODate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.CAPODate4 = subYears(ranges.selection.endDate,1);
    this.getCAPO();
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.CAPODate3 = ranges.selection.startDate;
    this.props.applicationStore.CAPODate4 = ranges.selection.endDate;
    this.getCAPO();
  }; 

  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getCAPO();
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.CAOrigineAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.CAOrigineAgences)
          })
        }
        if(this.props.applicationStore.ListePrestations.length > 0){
          this.setState({
            blacklist: this.props.applicationStore.prestationBlacklist
          })
        }
      }
    });
  }

  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_CAPO", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData() {
    var CaDivider = this.props.applicationStore.CaTTC ? 1 : 1.2;
    var CaType = this.props.applicationStore.CaRevenus ? "revenus" : "ca";
    const Data = this.state.graphData.map(n => ({
      refAgence: n.refAgence,
      origine: n.origine,
      ca: Math.round(n[CaType]/CaDivider),
      caN: Math.round(n[CaType+'N']/CaDivider),
      reservations: n.reservations
    }));
    Object.keys(this.props.applicationStore.CAOrigineAgences).map((a,k) => {
      let DataAgences = Data.map(n => {return n.refAgence});
      let subtotal = Data.filter(n => n.refAgence == a).reduce((acc, val) => {
        acc += val[CaType];
        return acc;            
      },0);
      let subtotalN = this.state.graphDataN.filter(n => n.refAgence == a).reduce((acc, val) => {
        acc += val[CaType];
        return acc;            
      },0);
      Data.splice(DataAgences.lastIndexOf(a)+1,0,{
        refAgence: a,
        origine: "Total",
        ca: subtotal
      })
      Data.filter(d => d.refAgence == a).map(d => {
        d.proportion = d[CaType]/subtotal;
        if(isNaN(d.proportion)){
          d.proportion = "NaN"
        }
        d[CaType+'N'] = this.state.graphDataN.filter(dN => dN.refAgence == a && dN.origine == d.origine).reduce((acc, val) => {
          acc += val[CaType];
          return acc;            
        },0);
        d.proportionN = d[CaType+'N']/subtotalN;
        if(isNaN(d.proportionN)){
          d.proportionN = "NaN"
        }
      })
    });
    let origines = [];
    Data.map(n => {
      n.Agence = this.props.applicationStore.CAOrigineAgences[n.refAgence];
      if(!origines.includes(n.origine)){
        origines.push(n.origine);
      }
    });
    origines.map(o => {
      Data.push({
        refAgence: -1,
        Agence: "Toutes agences",
        origine: o,
        ca: Data.filter(n => n.origine == o).reduce((acc,val) => {
          acc += val[CaType];
          return acc;
        },0),
        caN: Data.filter(n => n.origine == o).reduce((acc,val) => {
          acc += val[CaType+'N'];
          return acc;
        },0),
        reservations: Data.filter(n => n.origine == o).reduce((acc,val) => {
          acc += val.reservations;
          return acc;
        },0)
      });
    });
    let total = Data.length>0 ? Data[Data.length-1][CaType] : 0;
    let totalN = Data.length>0 ? Data[Data.length-1][CaType+'N'] : 0;
    Data.filter(d => d.refAgence == -1).map(d => {
      d.proportion = d[CaType]/total;
      if(isNaN(d.proportion)){
        d.proportion = "NaN"
      }
      d.proportionN = d[CaType+'N']/totalN;
      if(isNaN(d.proportionN)){
        d.proportionN = "NaN"
      }
    })
    return Data
  }

  render() {
    const selectedDate1 = this.props.applicationStore.CAPODate1;
    const selectedDate2 = this.props.applicationStore.CAPODate2;
    const selectedDate3 = this.props.applicationStore.CAPODate3;
    const selectedDate4 = this.props.applicationStore.CAPODate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }
    
    var CaDivider = this.props.applicationStore.CaTTC ? 1 : 1.2;
    var CaType = this.props.applicationStore.CaRevenus ? "revenus" : "ca";
    var CaTypeName = this.props.applicationStore.CaRevenus ? /*"Revenus" : "C.A."*/ "C.A." : "Encaissements";

    let total = this.state.graphData.length>0 ? this.state.graphData.reduce((acc, val)=>{
      acc += (val[CaType]/CaDivider);
      return  Math.round(acc);            
    },0): 0;
    let totalResa = this.state.graphData.length>0 ? this.state.graphData.reduce((acc, val)=>{
      acc += val.reservations;
      return Math.round(acc);            
    },0): 0;
    let totalMoy = this.state.graphData.reduce((acc, val)=>{
      const index = acc.findIndex(ob=>ob.id===val.origine)
      acc[index].reservations += val.reservations
      acc[index].val += Math.round(val[CaType]/CaDivider)
      acc[index].value = Math.round(acc[index].val/acc[index].reservations)
      return acc             
    }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
      id:n,
      value:0,
      val:0,
      reservations:0
    }))).reduce((acc,val)=>{
      acc += val.value
      return acc
    },0)

    let totalN = this.state.graphDataN.length>0 ? this.state.graphDataN.reduce((acc, val)=>{
      acc += (val[CaType]/CaDivider);
      return Math.round(acc);            
    },0): 0;
    let totalResaN = this.state.graphDataN.length>0 ? this.state.graphDataN.reduce((acc, val)=>{
      acc += val.reservations;
      return Math.round(acc);            
    },0): 0;
    let totalMoyN = this.state.graphDataN.reduce((acc, val)=>{
      const index = acc.findIndex(ob=>ob.id===val.origine)
      acc[index].reservations += val.reservations
      acc[index].val += Math.round(val[CaType]/CaDivider)
      acc[index].value = Math.round(acc[index].val/acc[index].reservations)
      return acc             
    }, Array.from(new Set(this.state.graphDataN.map(n => n.origine))).map(n=>({
      id:n,
      value:0,
      val:0,
      reservations:0
    }))).reduce((acc,val)=>{
      acc += val.value
      return acc
    },0)
    var PrestNames = [];
    return (
      <div>
      {!this.props.applicationStore.Logged ? (
        <>
        <h1>Connexion requise</h1>
          <Logon/>           
        </>
      ):(
        <>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleCA()}</h1>
                <p>{CaTypeName} <b>en provenance d'internet</b>, par origine pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      HT
                      <Switch id="CaTTC" name="CaTTC" checked={this.props.applicationStore.CaTTC} onChange={this.handleBoolChange} color="default"/>
                      TTC
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      {/*CA*/}Encaissements
                      <Switch id="CaRevenus" name="CaRevenus" checked={this.props.applicationStore.CaRevenus} onChange={this.handleBoolChange} color="default"/>
                      {/*Revenus*/}C.A.
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {this.props.applicationStore.ListePrestations.length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Prestations exclues : 
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.blacklist} onChange={this.handleBlacklistChange} renderValue={selected => selected.join(', ')} style={{width:"300px"}}>
                                {this.props.applicationStore.ListePrestations.map(prest => {
                                  let name = prest.name.trim().toUpperCase();
                                    if(!PrestNames.includes(name)){
                                      PrestNames.push(name);
                                      return(
                                        <MenuItem key={name} value={name}>
                                          <Checkbox checked={Object.values(this.state.blacklist).indexOf(name) > -1} />
                                          <ListItemText primary={prest.name.trim()} />
                                        </MenuItem>
                                      )
                                    }
                                  }
                                )}
                              </Select>
                            </Grid>
                            {this.props.applicationStore.ListePrestations.length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllBlacklisted}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoBlacklisted}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefreshBlacklist}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingBlacklist ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {(this.state.loading <= 0 && this.state.loadingN <= 0) ? ((this.state.graphData.length > 0 || this.state.graphDataN.length > 0) ? (
              <>
              <Grid container spacing={24} style={{textAlign:'center'}}>
                <Grid item xs={12} style={{textAlign:'left'}}>
                  <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                  <ExcelExport
                    data={this.dataToExcelData()}
                    fileName="CAPO.xlsx"
                    ref={(exporter) => { this._exporter = exporter; }}
                  >
                    <ExcelExportColumn field="Agence" title="Agence"/>
                    <ExcelExportColumn field="origine" title="Origine"/>
                    <ExcelExportColumn field="ca" title={CaTypeName}/>
                    <ExcelExportColumn field="proportion" title="Proportion" cellOptions={{format:"##0.000 %"}}/>
                    <ExcelExportColumn field="caN" title={CaTypeName+" N-1"}/>
                    <ExcelExportColumn field="proportionN" title="Proportion N-1" cellOptions={{format:"##0.000 %"}}/>
                  </ExcelExport>
                </Grid>
                {/* N */}
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total {CaTypeName} : {total.toLocaleString('fr',{maximumFractionDigits:0})} €</h5>
                  <ResponsivePie
                    data={
                      this.state.graphData.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].value += Math.round(val[CaType]/CaDivider)  
                        return acc             
                      }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0
                    })))
                      }
                    {...CAPOPieProperties}
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € ('+Number(value/total*100).toLocaleString('fr')+' %)'
                    )}
                />
                </Grid>
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total Réservations : {totalResa.toLocaleString('fr',{maximumFractionDigits:0})}</h5>
                  <ResponsivePie
                    data={
                      this.state.graphData.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].value += val.reservations 
                        return acc             
                      }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0
                    })))
                    }
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' ('+Number(value/totalResa*100).toLocaleString('fr')+' %)'
                    )}
                    {...CAPOPieProperties}
                  />
                </Grid>
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total {CaTypeName} / Réservations : {totalMoy.toLocaleString('fr',{maximumFractionDigits:0})} € / Réservation</h5>
                  <ResponsivePie
                    data={
                      this.state.graphData.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].reservations += val.reservations
                        acc[index].val += Math.round(val[CaType]/CaDivider)
                        acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                        return acc             
                      }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0,
                        reservations:0,
                        val:0
                    })))
                    }
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € / Réservation ('+Number(value/totalMoy*100).toLocaleString('fr')+' %)'
                    )}
                    {...CAPOPieProperties}
                  />
                </Grid> 
                {/* N-1 */}
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total {CaTypeName} N-1 : {totalN.toLocaleString('fr',{maximumFractionDigits:0})} €</h5>
                  <ResponsivePie
                    data={
                      this.state.graphDataN.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].value += Math.round(val[CaType]/CaDivider)  
                        return acc             
                      }, Array.from(new Set(this.state.graphDataN.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0
                    })))
                      }
                    {...CAPOPieProperties}
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € ('+Number(value/totalN*100).toLocaleString('fr')+' %)'
                    )}
                />
                </Grid>
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total Réservations N-1 : {totalResaN.toLocaleString('fr',{maximumFractionDigits:0})}</h5>
                  <ResponsivePie
                    data={
                      this.state.graphDataN.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].value += val.reservations 
                        return acc             
                      }, Array.from(new Set(this.state.graphDataN.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0
                    })))
                    }
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' ('+Number(value/totalResaN*100).toLocaleString('fr')+' %)'
                    )}
                    {...CAPOPieProperties}
                  />
                </Grid>
                <Grid item xs={4} style={{height:'500px'}}>
                  <h5>Total {CaTypeName} / Réservations N-1 : {totalMoyN.toLocaleString('fr',{maximumFractionDigits:0})} € / Réservation</h5>
                  <ResponsivePie
                    data={
                      this.state.graphDataN.reduce((acc, val)=>{
                        const index = acc.findIndex(ob=>ob.id===val.origine)
                        acc[index].reservations += val.reservations
                        acc[index].val += Math.round(val[CaType]/CaDivider)
                        acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                        return acc             
                      }, Array.from(new Set(this.state.graphDataN.map(n => n.origine))).map(n=>({
                        id:n,
                        label:n,
                        value:0,
                        reservations:0,
                        val:0
                    })))
                    }
                    tooltipFormat={(value =>
                      Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € / Réservation ('+Number(value/totalMoyN*100).toLocaleString('fr')+' %)'
                    )}
                    {...CAPOPieProperties}
                  />
                </Grid> 
              </Grid>
                {Object.keys(this.props.applicationStore.CAOrigineAgences).length > 1 && Object.keys(this.props.applicationStore.CAOrigineAgences).map((refAg,idx) => {
                  let subtotal = this.state.graphData.filter(datan=>datan.refAgence === refAg).length>0 ? this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    acc += (val[CaType]/CaDivider);
                    return Math.round(acc);           
                  },0) : 0;
                  let subtotalResa = this.state.graphData.filter(datan=>datan.refAgence === refAg).length>0 ? this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    acc += val.reservations;
                    return acc;           
                  },0) : 0;
                  let subtotalMoy = this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    const index = acc.findIndex(ob=>ob.id===val.origine)
                    acc[index].reservations += val.reservations
                    acc[index].val += Math.round(val[CaType]/CaDivider)
                    acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                    return acc             
                  }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
                    id:n,
                    value:0,
                    reservations:0,
                    val:0
                  }))).reduce((acc,val)=>{
                    acc += val.value
                    return acc
                  },0)
                  let subtotalN = this.state.graphDataN.filter(datan=>datan.refAgence === refAg).length>0 ? this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    acc += (val[CaType]/CaDivider);
                    return Math.round(acc);           
                  },0) : 0;
                  let subtotalResaN = this.state.graphDataN.filter(datan=>datan.refAgence === refAg).length>0 ? this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    acc += val.reservations;
                    return acc;           
                  },0) : 0;
                  let subtotalMoyN = this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                    const index = acc.findIndex(ob=>ob.id===val.origine)
                    if(index > 0)
                    {
                      acc[index].reservations += val.reservations
                      acc[index].val += Math.round(val[CaType]/CaDivider)
                      acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                    }
                    return acc             
                  }, Array.from(new Set(this.state.graphData.map(n => n.origine))).map(n=>({
                    id:n,
                    value:0,
                    reservations:0,
                    val:0
                  }))).reduce((acc,val)=>{
                    acc += val.value
                    return acc
                  },0)
                  return (
                    <Grid container spacing={24} key={idx}>
                      <Grid item xs={12}>
                          <Paper style={{paddingTop:'2px', paddingLeft:'10px'}}>
                            <p style={{width:"100%",textAlign:"center"}}><b>{this.props.applicationStore.CAOrigineAgences[refAg]}</b></p>
                            <Grid container spacing={24} align='center'>
                              <Grid item xs={4} style={{height:'420px'}} >
                                <h5>Total {CaTypeName} : {subtotal.toLocaleString('fr',{maximumFractionDigits:0})} €</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].value += (val[CaType]/CaDivider)  
                                      return acc             
                                    }, Array.from(new Set(this.state.graphData.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € ('+Number(value/subtotal*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                              />
                              </Grid>
                              <Grid item xs={4} style={{height:'420px'}}>
                                <h5>Total Réservations : {subtotalResa.toLocaleString('fr',{maximumFractionDigits:0})}</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].value += val.reservations 
                                      return acc             
                                    }, Array.from(new Set(this.state.graphData.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' ('+Number(value/subtotalResa*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                                />
                              </Grid>
                              <Grid item xs={4} style={{height:'420px'}}>
                                <h5>Total {CaTypeName} / Réservations : {subtotalMoy.toLocaleString('fr',{maximumFractionDigits:0})} € / Réservation</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphData.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].reservations += val.reservations
                                      acc[index].val += Math.round(val[CaType]/CaDivider)
                                      acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                                      return acc             
                                    }, Array.from(new Set(this.state.graphData.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0,
                                      reservations:0,
                                      val:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € / Réservation ('+Number(value/subtotalMoy*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                                />
                              </Grid>
                              <Grid item xs={4} style={{height:'420px'}} >
                              <h5>Total {CaTypeName} N-1 : {subtotalN.toLocaleString('fr',{maximumFractionDigits:0})} €</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].value += (val[CaType]/CaDivider)  
                                      return acc             
                                    }, Array.from(new Set(this.state.graphDataN.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € ('+Number(value/subtotalN*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                              />
                              </Grid>
                              <Grid item xs={4} style={{height:'420px'}}>
                                <h5>Total Réservations N-1 : {subtotalResaN.toLocaleString('fr',{maximumFractionDigits:0})}</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].value += val.reservations 
                                      return acc             
                                    }, Array.from(new Set(this.state.graphDataN.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' ('+Number(value/subtotalResaN*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                                />
                              </Grid>
                              <Grid item xs={4} style={{height:'420px'}}>
                                <h5>Total {CaTypeName} / Réservations N-1 : {subtotalMoyN.toLocaleString('fr',{maximumFractionDigits:0})} € / Réservation</h5>
                                <ResponsivePie
                                  data={
                                    this.state.graphDataN.filter(datan=>datan.refAgence === refAg).reduce((acc, val)=>{
                                      const index = acc.findIndex(ob=>ob.id===val.origine)
                                      acc[index].reservations += val.reservations
                                      acc[index].val += Math.round(val[CaType]/CaDivider)
                                      acc[index].value = Math.round(acc[index].val/acc[index].reservations)
                                      return acc             
                                    }, Array.from(new Set(this.state.graphDataN.filter(datan=>datan.refAgence === refAg).map(n => n.origine))).map(n=>({
                                      id:n,
                                      label:n,
                                      value:0,
                                      reservations:0,
                                      val:0
                                    })))
                                  }
                                  tooltipFormat={(value =>
                                    Number(value).toLocaleString('fr',{maximumFractionDigits:0})+' € / Réservation ('+Number(value/subtotalMoyN*100).toLocaleString('fr')+' %)'
                                  )}
                                  {...CAPOPieProperties}
                                />
                              </Grid>
                            </Grid>
                          </Paper>  
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            ):(
              <Grid style={{margin:"25% auto",textAlign:"center"}}>
                  <Warning color="error"/>
                  <Typography variant="overline" color="error">
                    Aucune donnée
                  </Typography>
              </Grid>
            )):(
              <Grid style={{margin:"25% auto",textAlign:"center"}}>
                  <Sync className={this.props.classes.loader}/>
              </Grid>
            )}
          </>
        )
      }
      </div>
    );
  }
}