import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {observer, inject} from "mobx-react";

@inject('applicationStore')
export default class Logon extends Component {
  displayName = Logon.name

   state = {txtRefAgence:'',txtCode:''}
   handleTextInputChange = inputName => evt => this.setState({
    [inputName]: evt.target.value
  })
  btnClick=() =>{
    this.props.applicationStore.changeagence(this.state.txtRefAgence, this.state.txtCode);
  }

  render() {

    return (
      <div>
        <TextField
          id="txtRefAgence"
          label="Référence agence"
          value={this.state.txtRefAgence}
          helpertext="votre refAgence"
          margin="normal"
          onChange={this.handleTextInputChange('txtRefAgence')}
        />    
        <br/>
        <TextField
          id="txtCode"
          label="Votre code"
          value={this.state.txtCode}
          margin="normal"
          type="password"
          onChange={this.handleTextInputChange('txtCode')}
        />   
        <br/>  
        <Button variant="contained" color="secondary" onClick={this.btnClick}>
        Valider
      </Button>  
    </div>
    );
  }
}
