import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { ResponsiveLine } from '@nivo/line';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Save, Sync, Warning } from '@material-ui/icons';

const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableOld:{
    color: 'gray', 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentWarning:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'darkred',
    textShadow: '0 0 10px #F44336',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentHappy:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'darkgreen',
    textShadow: '0 0 10px #4CAF50',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});

@withStyles(styles)
@inject('applicationStore')
export default class Volumes extends Component {
  displayName = Volumes.name
  state = {
    data: [],
    graphCTdata: [],
    graphPdata: [],
    ModalOpen:false,
    loading:true,
    loadingCT:true,
    loadingP:true,
    selectedAgencies:Object.keys(this.props.applicationStore.VolumeAgences),
    loadingAgencies:false,
    mountLoaded:false,
    dataGlobal: {},
    cp: this.props.applicationStore.VolumeCP,
    loadingGlobal: false
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTitleVolumes(){
    let title = 'Volumes de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.VolumeAgences).length > 1)
    {
      title = 'Volumes des agences ';
    }
    Object.keys(this.props.applicationStore.VolumeAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.VolumeAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.VolumeAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.VolumeAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.VolumeAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.VolumeAgences[element];
          }
        }
      }

    });
    return title + temp;
  } 
  getVolumes = () => {
    this.setState({
      loading: true,
      loadingCT: true,
      loadingP: true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "Volumes", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Volumes/getStatVolumes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.VolumeDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.VolumeDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.VolumeDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.VolumeDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(data => this.setState({data,loading:false}));
    fetch('api/Volumes/getStatVolumesGraphCt', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.VolumeDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.VolumeDate2, 'YYYY-MM-DD')})
    }).then(graphCTdata => graphCTdata.json()).then(graphCTdata => this.setState({graphCTdata,loadingCT:false}));
    fetch('api/Volumes/getStatVolumesGraphP', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.VolumeDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.VolumeDate2, 'YYYY-MM-DD')})
    }).then(graphPdata => graphPdata.json()).then(graphPdata => this.setState({graphPdata,loadingP:false}));
  }

  getVolumesGlobal = () => {
    this.setState({
      loadingGlobal: true
    })
    fetch('api/Volumes/getStatVolumesGlobal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({CP: this.props.applicationStore.VolumeCP, Date1: format(this.props.applicationStore.VolumeDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.VolumeDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.VolumeDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.VolumeDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(dataGlobal => this.setState({dataGlobal,loadingGlobal: false}));
    
  }

  transferToVolumeAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    })
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "volumes"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getVolumes()
      this.updateAgencies(Agences)
    });
  }

  transferCP = () => {
    const response = fetch('api/MainData/updateCP', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, CP: this.props.applicationStore.VolumeCP, Table: "volumes"})
    }).then(() => {
      this.getVolumesGlobal()
    });
  }

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToVolumeAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.VolumeAgences = Agences
  };

  @action handleDateChange = ranges => {
    this.props.applicationStore.VolumeDate1 = ranges.selection.startDate;
    this.props.applicationStore.VolumeDate2 = ranges.selection.endDate;
    this.props.applicationStore.VolumeDate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.VolumeDate4 = subYears(ranges.selection.endDate,1);
    this.getVolumes()
    this.getVolumesGlobal()
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.VolumeDate3 = ranges.selection.startDate;
    this.props.applicationStore.VolumeDate4 = ranges.selection.endDate;
    this.getVolumes()
    this.getVolumesGlobal()
  };
  @action handleCPChange = () => {
    this.props.applicationStore.VolumeCP = this.state.cp
    this.transferCP()
    this.getVolumesGlobal()
  }
  @action CPCheck = event => {
    var vals = event.target.value.split(/[\s,;/.]/)
    var cp = vals.map(val => val.substr(0,2)).join(',')
    this.setState({cp})
  }
  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getVolumes()
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.VolumeAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.VolumeAgences)
          })
        }
        if(this.props.applicationStore.VolumeCP != ""){
          this.setState({
            cp:this.props.applicationStore.VolumeCP
          })
          this.getVolumesGlobal()
        }
      }
    })
  }

  tableChooseCurrent = (val, valN) => {
    let look = this.props.classes.tableCurrent;
    
      if(val > valN)
      {
        if((((val/valN)*100) -100) > this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.tableCurrentHappy;
        } 
      }
      if(valN > val)
      {
        if(((1-(val/valN))*100) > this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.tableCurrentWarning;
        }
      }
   
    return look;
  }

  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_Volumes", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData = () => {
    const Data = this.state.data.map(n => ({
      refAgence: n.refAgence,
      Agence: this.props.applicationStore.VolumeAgences[n.refAgence],
      nbMandats: n.nbMandats,
      nbMandatsN: n.nbMandatsN,
      nbContrats: n.nbContrats,
      nbContratsN: n.nbContratsN,
      nbJoursProposes: n.nbJoursProposes,
      nbJoursProposesN: n.nbJoursProposes,
      nbPropositions: n.nbPropositions,
      nbPropositionsN: n.nbPropositionsN,
      ctParMandat: n.ctParMandat,
      ctParMandatN: n.ctParMandatN,
      nbJoursVendus: n.nbJoursVendus,
      nbJoursVendusN: n.nbJoursVendusN,
      dureeMoyen: n.dureeMoyen,
      dureeMoyenN: n.dureeMoyenN,
      nbSem: n.nbSem,
      nbSemN: n.nbSemN
    }));
    const excelData = [...Data];
    const l = Data.length;
    excelData[l] = {
      Agence:'Moyennes',
      nbContrats:Math.round((Data.reduce((t,n) => t+n.nbContrats,0))/l),
      nbContratsN:Math.round((Data.reduce((t,n) => t+n.nbContratsN,0))/l),
      nbPropositions:Math.round((Data.reduce((t,n) => t+n.nbPropositions,0))/l),
      nbPropositionsN:Math.round((Data.reduce((t,n) => t+n.nbPropositionsN,0))/l),
      ctParMandat:((Data.reduce((t,n) => t+n.nbContrats,0))/Data.reduce((t,n) => t+n.nbMandats,0)),
      ctParMandatN:((Data.reduce((t,n) => t+n.nbContratsN,0))/Data.reduce((t,n) => t+n.nbMandatsN,0)),
      nbJoursVendus:Math.round((Data.reduce((t,n) => t+n.nbJoursVendus,0))/l),
      nbJoursVendusN:Math.round((Data.reduce((t,n) => t+n.nbJoursVendusN,0))/l),
      dureeMoyen:((Data.reduce((t,n) => t+n.nbJoursVendus,0))/Data.reduce((t,n) => t+n.nbContrats,0)),
      dureeMoyenN:((Data.reduce((t,n) => t+n.nbJoursVendusN,0))/Data.reduce((t,n) => t+n.nbContratsN,0)),
      nbSem:(((Data.reduce((t,n) => t+n.nbJoursVendus,0))/Data.reduce((t,n) => t+n.nbContrats,0))/7),
      nbSemN:(((Data.reduce((t,n) => t+n.nbJoursVendusN,0))/Data.reduce((t,n) => t+n.nbContratsN,0))/7)
    }
    if(isNaN(excelData[l].ctParMandat)){
      excelData[l].ctParMandat = "NaN"
    }
    if(isNaN(excelData[l].ctParMandatN)){
      excelData[l].ctParMandatN = "NaN"
    }
    if(isNaN(excelData[l].dureeMoyen)){
      excelData[l].dureeMoyen = "NaN"
    }
    if(isNaN(excelData[l].dureeMoyenN)){
      excelData[l].dureeMoyenN = "NaN"
    }
    if(isNaN(excelData[l].nbSem)){
      excelData[l].nbSem = "NaN"
    }
    if(isNaN(excelData[l].nbSemN)){
      excelData[l].nbSemN = "NaN"
    }
    excelData[(l+1)] = {
      Agence:'Total',
      nbContrats:Data.reduce((t,n) => t+n.nbContrats,0),
      nbContratsN:Data.reduce((t,n) => t+n.nbContratsN,0),
      nbPropositions:Data.reduce((t,n) => t+n.nbPropositions,0),
      nbPropositionsN:Data.reduce((t,n) => t+n.nbPropositionsN,0),
      ctParMandat:Data.reduce((t,n) => t+n.ctParMandat,0),
      ctParMandatN:Data.reduce((t,n) => t+n.ctParMandatN,0),
      nbJoursVendus:Data.reduce((t,n) => t+n.nbJoursVendus,0),
      nbJoursVendusN:Data.reduce((t,n) => t+n.nbJoursVendusN,0),
      dureeMoyen:Data.reduce((t,n) => t+n.dureeMoyen,0),
      dureeMoyenN:Data.reduce((t,n) => t+n.dureeMoyenN,0),
      nbSem:Data.reduce((t,n) => t+n.nbSem,0),
      nbSemN:Data.reduce((t,n) => t+n.nbSemN,0)
    }
    if(isNaN(excelData[l+1].ctParMandat)){
      excelData[l+1].ctParMandat = "NaN"
    }
    if(isNaN(excelData[l+1].ctParMandatN)){
      excelData[l+1].ctParMandatN = "NaN"
    }
    if(isNaN(excelData[l+1].dureeMoyen)){
      excelData[l+1].dureeMoyen = "NaN"
    }
    if(isNaN(excelData[l+1].dureeMoyenN)){
      excelData[l+1].dureeMoyenN = "NaN"
    }
    if(isNaN(excelData[l+1].nbSem)){
      excelData[l+1].nbSem = "NaN"
    }
    if(isNaN(excelData[l+1].nbSemN)){
      excelData[l+1].nbSemN = "NaN"
    }
    
    /*excelData[l+2] = {
      Agence:'Moyenne départementale',
      nbContrats:'',
      nbContratsN:'',
      nbPropositions:'',
      nbPropositionsN:'',
      ctParMandat:'',
      ctParMandatN:'',
      nbJoursVendus:'',
      nbJoursVendusN:'',
      dureeMoyen:Object.keys(this.state.dataGlobal).length > 0 ? this.state.dataGlobal.dureeMoyen : '',
      dureeMoyenN:Object.keys(this.state.dataGlobal).length > 0 ? this.state.dataGlobal.dureeMoyenN : '',
      nbSem:Object.keys(this.state.dataGlobal).length > 0 ? this.state.dataGlobal.nbSem : '',
      nbSemN:Object.keys(this.state.dataGlobal).length > 0 ? this.state.dataGlobal.nbSemN : ''
    }
    if(isNaN(excelData[l+2].ctParMandat)){
      excelData[l+2].ctParMandat = "NaN"
    }
    if(isNaN(excelData[l+2].ctParMandatN)){
      excelData[l+2].ctParMandatN = "NaN"
    }
    if(isNaN(excelData[l+2].dureeMoyen)){
      excelData[l+2].dureeMoyen = "NaN"
    }
    if(isNaN(excelData[l+2].dureeMoyenN)){
      excelData[l+2].dureeMoyenN = "NaN"
    }
    if(isNaN(excelData[l+2].nbSem)){
      excelData[l+2].nbSem = "NaN"
    }
    if(isNaN(excelData[l+2].nbSemN)){
      excelData[l+2].nbSemN = "NaN"
    }*/

    return excelData
  }


  render() {
    const selectedDate1 = this.props.applicationStore.VolumeDate1;
    const selectedDate2 = this.props.applicationStore.VolumeDate2;
    const selectedDate3 = this.props.applicationStore.VolumeDate3;
    const selectedDate4 = this.props.applicationStore.VolumeDate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }
    
    return (
      <div>
        {!this.props.applicationStore.Logged ? (
          <>
          <h1>Connexion requise</h1>
            <Logon/>           
          </>
        ):(
          <>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleVolumes()}</h1>
                <p>Nombres de contrats, jours loués, propositions, et mandats pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  {/*<Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        <Grid item style={{padding:"8px 4px"}}>
                          Code Département (Moyenne départementale) : &nbsp;
                        </Grid>
                        <Grid item>
                          <TextField id="CP" name="CP" type='text' onChange={this.CPCheck} value={this.state.cp} style={{ marginRight: '5px' }}/>
                          <Button variant='contained' color='secondary' onClick={this.handleCPChange}>Actualiser</Button>
                        </Grid>
                        <Grid item style={{textAlign:"center",visibility:this.state.loadingGlobal ? "visible" : "hidden"}}>
                          <Sync className={this.props.classes.loaderMini}/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {this.state.loading <= 0 ? (this.state.data.length > 0 ? (
                <Grid item xs={12} style={{maxWidth:'fit-content'}}>
                  <Paper style={{width:'fit-content'}}>
                    <Table padding='dense'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Agence</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Contrats</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>Ct. N-1</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Propositions</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>Prop. N-1</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Ct./mandat</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>Ct./mandat N-1</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Jours loués</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>J. loués N-1</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Durée moy. j.</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>Durée moy. j. N-1</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>Durée moy. s.</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>Durée moy. s. N-1</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.dataToExcelData().map((n,i) => {
                          if(i<this.state.data.length){
                            return (
                              <TableRow key={i}>
                                <TableCell>
                                  {n.Agence}
                                </TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.nbContrats, n.nbContratsN)}>{n.nbContrats.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbContratsN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.nbPropositions, n.nbPropositionsN)}>{n.nbPropositions.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbPropositionsN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.ctParMandat.toLocaleString('fr',{maximumFractionDigits:2})}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.ctParMandatN.toLocaleString('fr',{maximumFractionDigits:2})}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.nbJoursVendus.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbJoursVendusN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.dureeMoyen.toLocaleString('fr',{maximumFractionDigits:1})} jours</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.dureeMoyenN.toLocaleString('fr',{maximumFractionDigits:1})} jours</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.nbSem.toLocaleString('fr',{maximumFractionDigits:1})} sem.</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbSemN.toLocaleString('fr',{maximumFractionDigits:1})} sem.</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                      <TableFooter>
                      {this.dataToExcelData().map((n,i) => {
                          if(i>=this.state.data.length){
                            return (
                              <TableRow key={i} className={i>this.state.data.length ? this.props.classes.tableTotal:''}>
                                <TableCell>
                                  {n.Agence}
                                </TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.nbContrats, n.nbContratsN)}>{n.nbContrats.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbContratsN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.nbPropositions, n.nbPropositionsN)}>{n.nbPropositions.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbPropositionsN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.ctParMandat.toLocaleString('fr',{maximumFractionDigits:2})}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.ctParMandatN.toLocaleString('fr',{maximumFractionDigits:2})}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.nbJoursVendus.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbJoursVendusN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.dureeMoyen.toLocaleString('fr',{maximumFractionDigits:1})} jours</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.dureeMoyenN.toLocaleString('fr',{maximumFractionDigits:1})} jours</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.nbSem.toLocaleString('fr',{maximumFractionDigits:1})} sem.</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.nbSemN.toLocaleString('fr',{maximumFractionDigits:1})} sem.</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableFooter>
                    </Table>
                    <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                    <ExcelExport
                      data={this.dataToExcelData()}
                      fileName="Volumes.xlsx"
                      ref={(exporter) => { this._exporter = exporter; }}
                    >
                      <ExcelExportColumn field="Agence" title="Agence"/>
                      <ExcelExportColumn field="nbContrats" title="Contrats"/>
                      <ExcelExportColumn field="nbContratsN" title="Ct. N-1"/>
                      <ExcelExportColumn field="nbPropositions" title="Propositions"/>
                      <ExcelExportColumn field="nbPropositionsN" title="Prop. N-1"/>
                      <ExcelExportColumn field="ctParMandat" title="Ct./Mandat" cellOptions={{ format: '# ##0.00' }} />
                      <ExcelExportColumn field="ctParMandatN" title="Ct./Mandat N-1" cellOptions={{ format: '# ##0.00' }} />
                      <ExcelExportColumn field="nbJoursVendus" title="Jours loués" />
                      <ExcelExportColumn field="nbJoursVendusN" title="J. loués N-1" />
                      <ExcelExportColumn field="dureeMoyen" title="Durée moy. j." cellOptions={{ format: '# ##0.0" jours"' }} />
                      <ExcelExportColumn field="dureeMoyenN" title="Durée moy. j. N-1" cellOptions={{ format: '# ##0.0" jours"' }} />
                      <ExcelExportColumn field="nbSem" title="Durée moy. s." cellOptions={{ format: '# ##0.0" sem."' }} />
                      <ExcelExportColumn field="nbSemN" title="Durée moy. s. N-1" cellOptions={{ format: '# ##0.0" sem."' }} />
                    </ExcelExport>
                  </Paper>
                </Grid>
              ):(
                <Grid style={{margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={24}>
              {this.state.loadingCT <= 0 ? (this.state.graphCTdata.length > 0 ? (
                <Grid item xs={12} style={{height:'440px'}}>
                  <ResponsiveLine
                    data={
                      Object.keys(this.props.applicationStore.VolumeAgences).reduce((acc,element) => {
                      let tempdata = this.state.graphCTdata.filter(chunck => chunck.refAgence == element);
                        acc.push({
                          id:this.props.applicationStore.VolumeAgences[element],
                          data:tempdata.map(n=>({
                            x:n.dateStat,
                            y:n.nbContrats
                          }))
                        })
                        return acc
                      
                    }, [])}
                    margin={{
                        "top": 20,
                        "right": 200,
                        "bottom": 80,
                        "left": 60
                    }}
                    xScale={{
                        "type": "point"
                    }}
                    yScale={{
                        "type": "linear",
                        "stacked": false,
                        "min": 0,
                        "max": "auto"
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        "orient": "bottom",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": -60,
                        "legend": " ",
                        "legendOffset": 36,
                        "legendPosition": "middle"
                    }}
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "Contrats",
                        "legendOffset": -40,
                        "legendPosition": "middle"
                    }}
                    dotSize={10}
                    dotColor="inherit:darker(0.3)"
                    dotBorderWidth={2}
                    dotBorderColor="#ffffff"
                    enableDotLabel={true}
                    dotLabel="y"
                    dotLabelYOffset={-12}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    colors="category10"
                    curve="monotoneX"
                    legends={[
                        {
                            "anchor": "top-right",
                            "direction": "column",
                            "justify": false,
                            "translateX": 100,
                            "translateY": 0,
                            "itemsSpacing": 0,
                            "itemDirection": "left-to-right",
                            "itemWidth": 80,
                            "itemHeight": 20,
                            "itemOpacity": 0.75,
                            "symbolSize": 12,
                            "symbolShape": "circle",
                            "symbolBorderColor": "rgba(0, 0, 0, .5)",
                            "effects": [
                                {
                                    "on": "hover",
                                    "style": {
                                        "itemBackground": "rgba(0, 0, 0, .03)",
                                        "itemOpacity": 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
                </Grid>
              ):(
                <Grid style={{height:'440px', margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{height:'440px', margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
              {this.state.loadingP <= 0 ? (this.state.graphPdata.length > 0 ? (
                <Grid item xs={12} style={{height:'440px'}}>
                  <ResponsiveLine
                    data={
                      Object.keys(this.props.applicationStore.VolumeAgences).reduce((acc,element) => {
                      let tempdata = this.state.graphPdata.filter(chunck => chunck.refAgence == element);
                        acc.push({
                          id:this.props.applicationStore.VolumeAgences[element],
                          data:tempdata.map(n=>({
                            x:n.dateStat,
                            y:n.nbPropositions
                          }))
                        })
                        return acc
                      
                    }, [])}
                    margin={{
                        "top": 20,
                        "right": 200,
                        "bottom": 80,
                        "left": 60
                    }}
                    xScale={{
                        "type": "point"
                    }}
                    yScale={{
                        "type": "linear",
                        "stacked": false,
                        "min": 0,
                        "max": "auto"
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        "orient": "bottom",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": -60,
                        "legend": " ",
                        "legendOffset": 36,
                        "legendPosition": "middle"
                    }}
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "Propositions",
                        "legendOffset": -40,
                        "legendPosition": "middle"
                    }}
                    dotSize={10}
                    dotColor="inherit:darker(0.3)"
                    dotBorderWidth={2}
                    dotBorderColor="#ffffff"
                    enableDotLabel={true}
                    dotLabel="y"
                    dotLabelYOffset={-12}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    colors="category10"
                    curve="monotoneX"
                    legends={[
                        {
                            "anchor": "top-right",
                            "direction": "column",
                            "justify": false,
                            "translateX": 100,
                            "translateY": 0,
                            "itemsSpacing": 0,
                            "itemDirection": "left-to-right",
                            "itemWidth": 80,
                            "itemHeight": 20,
                            "itemOpacity": 0.75,
                            "symbolSize": 12,
                            "symbolShape": "circle",
                            "symbolBorderColor": "rgba(0, 0, 0, .5)",
                            "effects": [
                                {
                                    "on": "hover",
                                    "style": {
                                        "itemBackground": "rgba(0, 0, 0, .03)",
                                        "itemOpacity": 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
                </Grid>
              ):(
                <Grid style={{height:'440px',margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{height:'440px', margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </div>
    );
  }
}