import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { ResponsiveLine } from '@nivo/line'
import { Save, Sync, Warning } from '@material-ui/icons';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export'


const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableOld:{
    color: 'gray', 
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  slabNeutral:{
    width:'225px', height:'100px', paddingTop:'5px', paddingRight:'20px' ,paddingLeft:'20px', backgroundColor:'#FFFFFF',
  },
  slabRed:{
    width:'225px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#F44336',
  },
  slabGreen:{
    width:'225px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#4CAF50',
  },
  slabPurple:{
    width:'225px', height:'100px', paddingTop:'5px', paddingRight:'20px', paddingLeft:'20px', backgroundColor:'#9979CD',
  },
  tableCurrentWarning:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkred',
    textShadow: '0 0 10px #F44336',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentHappy:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkgreen',
    textShadow: '0 0 10px #4CAF50',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});

@withStyles(styles)
@inject('applicationStore')


export default class CA extends Component {
  displayName = CA.name
  state = {
    data: [],
    graphData: [],
    ModalOpen:false,
    loading:true,
    graphLoading:true,
    selectedAgencies:Object.keys(this.props.applicationStore.CAAgences),
    loadingAgencies:false,
    mountLoaded:false,
    blacklist:this.props.applicationStore.prestationBlacklist,
    loadingBlacklist:false
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTitleCA(){
    let title = 'Chiffre d\'affaire de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.CAAgences).length > 1)
    {
      title = 'Chiffre d\'affaire des agences ';
    }
    Object.keys(this.props.applicationStore.CAAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.CAAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.CAAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.CAAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.CAAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.CAAgences[element];
          }
        }
      }

    });
    return title + temp;
  } 
  getCA = () => {
    this.setState({
      graphLoading: true,
      loading: true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "CA", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Ca/getStatCa', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.CADate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.CADate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.CADate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.CADate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(data => this.setState({data,loading:false}));
    fetch('api/Ca/getStatCaGraph', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.CADate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.CADate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.CADate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.CADate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(graphData => this.setState({graphData,graphLoading:false}));
  }

  transferToBools = () => {   
    const response = fetch('api/MainData/updateBools', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Alouer: this.props.applicationStore.InAlouer, TauxLocatGlobal: this.props.applicationStore.TauxLocatGlobal,CaTTC: this.props.applicationStore.CaTTC, CaRevenus: this.props.applicationStore.CaRevenus})
    }).then(() => this.getCA());
  }

  transferToCAAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    })
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "ca"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getCA()
      this.updateAgencies(Agences)
    });
  }

  transferToBlacklist = () => {
    this.setState({
      loadingBlacklist: true
    })   
    const response = fetch('api/MainData/updateBlacklist', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Blacklist: this.state.blacklist, Agences: Object.keys(this.props.applicationStore.ListeAgences)})
    }).then(() => {
      this.setState({
        loadingBlacklist: false
      })
      this.getCA()
      this.updateBlacklist()
    });
  }

  @action handleBoolChange = event => {
    if(event.target.name === 'CaTTC')
    {
     this.props.applicationStore.CaTTC = event.target.checked;
    }
    if(event.target.name === 'CaRevenus')
    {
     this.props.applicationStore.CaRevenus = event.target.checked;
    }
    this.transferToBools();
  };

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToCAAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.CAAgences = Agences
  };

  @action handleBlacklistChange = event => {
    this.setState({
      blacklist: event.target.value
    })
  }

  @action handleAllBlacklisted = () => {
    this.setState({
      blacklist: this.props.applicationStore.ListePrestations.reduce((acc,val) => {
        let name = val.name.trim().toUpperCase();
        if(!acc.includes(name)){
          acc.push(name)
        }
        return acc;
      },[])
    })
  };

  @action handleNoBlacklisted = () => {
    this.setState({
      blacklist: []
    })
  };

  @action handleRefreshBlacklist = () => {
    this.transferToBlacklist();
  };

  @action updateBlacklist = () => {
    this.props.applicationStore.prestationBlacklist = this.state.blacklist
  }

  @action handleDateChange = ranges => {
    this.props.applicationStore.CADate1 = ranges.selection.startDate;
    this.props.applicationStore.CADate2 = ranges.selection.endDate;
    this.props.applicationStore.CADate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.CADate4 = subYears(ranges.selection.endDate,1);
    this.getCA()
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.CADate3 = ranges.selection.startDate;
    this.props.applicationStore.CADate4 = ranges.selection.endDate;
    this.getCA()
  }; 

  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getCA()
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.CAAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.CAAgences)
          })
        }
        if(this.props.applicationStore.ListePrestations.length > 0){
          this.setState({
            blacklist: this.props.applicationStore.prestationBlacklist
          })
        }
      }
    });
  }

  calcPC = (val, valN) => {
    let pourcent = '';
    if((val != 0) && (valN != 0))
    {
      if(val > valN)
      {
        pourcent = "(+"+(((val/valN)*100) -100).toLocaleString('fr',{maximumFractionDigits:1}) +'%)';
        
      }
      if(valN > val)
      {
        pourcent = "(-"+((1-(val/valN))*100).toLocaleString('fr',{maximumFractionDigits:1}) +'%)';
      }
      pourcent = pourcent.replace("--","+");
      pourcent = pourcent.replace("+-","-");
    }
    return pourcent;
  }
  slabChoose = (val, valN) => {
    let look = this.props.classes.slabNeutral;
    
      if(val > valN)
      {
        if((((val/valN)*100) -100) > this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.slabGreen;
        } 
      }
      if(valN > val)
      {
        if(((1-(val/valN))*100) > this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.slabRed;
        }
      }
   
    return look;
  }
  tableChooseCurrent = (val, valN) => {
    let look = this.props.classes.tableCurrent;
    
      if(val > valN)
      {
        if((((val/valN)*100) -100) > this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.tableCurrentHappy;
        } 
      }
      if(valN > val)
      {
        if(((1-(val/valN))*100) > this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.tableCurrentWarning;
        }
      }
   
    return look;
  }
  
  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_CA", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData = () => {
    var CaDivider = this.props.applicationStore.CaTTC ? 1 : 1.2;
    const Data = this.state.data.map(n => ({
      Agence: this.props.applicationStore.CAAgences[n.refAgence],
      ca: Math.round(n.ca/CaDivider),
      caN: Math.round(n.caN/CaDivider),
      caTTC: n.ca,
      caTTCN: n.caN,
      revenus: Math.round(n.revenus/CaDivider),
      revenusN: Math.round(n.revenusN/CaDivider),
      revenusHC: Math.round(n.revenus/1.2),
      revenusHCN: Math.round(n.revenusN/1.2),
      fdd: Math.round(n.fdd/CaDivider),
      fddN: Math.round(n.fddN/CaDivider),
      assur: Math.round(n.assur/CaDivider),
      assurN: Math.round(n.assurN/CaDivider),
      menage: Math.round(n.menage/CaDivider),
      menageN: Math.round(n.menageN/CaDivider),
      option: Math.round(n.option/CaDivider),
      optionN: Math.round(n.optionN/CaDivider),
      taxe_sejour: Math.round(n.taxe_sejour/CaDivider),
      taxe_sejourN: Math.round(n.taxe_sejourN/CaDivider),
      hono: Math.round(n.hono/CaDivider),
      honoN: Math.round(n.honoN/CaDivider),
      autre: Math.round(n.autre/CaDivider),
      autreN: Math.round(n.autreN/CaDivider),
      remise: Math.round(n.remise/CaDivider),
      remiseN: Math.round(n.remiseN/CaDivider)
    }));
    const excelData = [...Data];
    const l = Data.length;
    excelData[l] = {
      Agence:'Moyennes',
      ca:Math.round((Data.reduce((t,n) => t+n.ca,0))/l),
      caN:Math.round((Data.reduce((t,n) => t+n.caN,0))/l),
      caTTC: Math.round((Data.reduce((t,n) => t+n.caTTC,0))/l),
      caTTCN: Math.round((Data.reduce((t,n) => t+n.caTTCN,0))/l),
      revenus:Math.round((Data.reduce((t,n) => t+n.revenus,0))/l),
      revenusN:Math.round((Data.reduce((t,n) => t+n.revenusN,0))/l),
      revenusHC:Math.round((Data.reduce((t,n) => t+n.revenusHC,0))/l),
      revenusHCN:Math.round((Data.reduce((t,n) => t+n.revenusHCN,0))/l),
      fdd:Math.round((Data.reduce((t,n) => t+n.fdd,0))/l),
      fddN:Math.round((Data.reduce((t,n) => t+n.fddN,0))/l),
      assur:Math.round((Data.reduce((t,n) => t+n.assur,0))/l),
      assurN:Math.round((Data.reduce((t,n) => t+n.assurN,0))/l),
      menage:Math.round((Data.reduce((t,n) => t+n.menage,0))/l),
      menageN:Math.round((Data.reduce((t,n) => t+n.menageN,0))/l),
      option:Math.round((Data.reduce((t,n) => t+n.option,0))/l),
      optionN:Math.round((Data.reduce((t,n) => t+n.optionN,0))/l),
      taxe_sejour:Math.round((Data.reduce((t,n) => t+n.taxe_sejour,0))/l),
      taxe_sejourN:Math.round((Data.reduce((t,n) => t+n.taxe_sejourN,0))/l),
      hono:Math.round((Data.reduce((t,n) => t+n.hono,0))/l),
      honoN:Math.round((Data.reduce((t,n) => t+n.honoN,0))/l),
      autre:Math.round((Data.reduce((t,n) => t+n.autre,0))/l),
      autreN:Math.round((Data.reduce((t,n) => t+n.autreN,0))/l),
      remise:Math.round((Data.reduce((t,n) => t+n.remise,0))/l),
      remiseN:Math.round((Data.reduce((t,n) => t+n.remiseN,0))/l)
    }
    excelData[(l+1)] = {
      Agence:'Total',
      ca:((Data.reduce((t,n) => t+n.ca,0))),
      caN:((Data.reduce((t,n) => t+n.caN,0))),
      caTTC:((Data.reduce((t,n) => t+n.caTTC,0))),
      caTTCN:((Data.reduce((t,n) => t+n.caTTCN,0))),
      revenus:((Data.reduce((t,n) => t+n.revenus,0))),
      revenusN:((Data.reduce((t,n) => t+n.revenusN,0))),
      revenusHC:((Data.reduce((t,n) => t+n.revenusHC,0))),
      revenusHCN:((Data.reduce((t,n) => t+n.revenusHCN,0))),
      fdd:((Data.reduce((t,n) => t+n.fdd,0))),
      fddN:((Data.reduce((t,n) => t+n.fddN,0))),
      assur:((Data.reduce((t,n) => t+n.assur,0))),
      assurN:((Data.reduce((t,n) => t+n.assurN,0))),
      menage:((Data.reduce((t,n) => t+n.menage,0))),
      menageN:((Data.reduce((t,n) => t+n.menageN,0))),
      option:((Data.reduce((t,n) => t+n.option,0))),
      optionN:((Data.reduce((t,n) => t+n.optionN,0))),
      taxe_sejour:((Data.reduce((t,n) => t+n.taxe_sejour,0))),
      taxe_sejourN:((Data.reduce((t,n) => t+n.taxe_sejourN,0))),
      hono:((Data.reduce((t,n) => t+n.hono,0))),
      honoN:((Data.reduce((t,n) => t+n.honoN,0))),
      autre:((Data.reduce((t,n) => t+n.autre,0))),
      autreN:((Data.reduce((t,n) => t+n.autreN,0))),
      remise:((Data.reduce((t,n) => t+n.remise,0))),
      remiseN:((Data.reduce((t,n) => t+n.remiseN,0)))
    }
    return excelData
  }
  
  render() {
    const selectedDate1 = this.props.applicationStore.CADate1;
    const selectedDate2 = this.props.applicationStore.CADate2;
    const selectedDate3 = this.props.applicationStore.CADate3;
    const selectedDate4 = this.props.applicationStore.CADate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }
    
    const calculCA = /* 'C.A'+ */'Encaissements'+' = Net Locataire + Frais de dossier (F.D.D.) + Assurance + Menage + Prestations';
    const calculRevenus = /*'Revenus'*/'C.A.'+' = Frais de dossier (F.D.D.) + Assurance + Menage + Prestations + Honoraires + Autres + Remises';
    var CaTaxe = this.props.applicationStore.CaTTC ? "TTC" : "HT";
    var CaType = this.props.applicationStore.CaRevenus ? "revenus" : "ca";
    var CaTypeName = this.props.applicationStore.CaRevenus ? /*"Revenus" : "C.A."*/ "C.A." : "Encaissements";
    var CaDivider = this.props.applicationStore.CaTTC ? 1 : 1.2;
    const data = this.dataToExcelData();
    var PrestNames = [];
    return (
      <div>
      {!this.props.applicationStore.Logged ? (
        <>
        <h1>Connexion requise</h1>
          <Logon/>           
        </>
      ):(
        <>
  
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleCA()}</h1>
                <p>{CaTypeName} pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      HT
                      <Switch id="CaTTC" name="CaTTC" checked={this.props.applicationStore.CaTTC} onChange={this.handleBoolChange} color="default"/>
                      TTC
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      <b>Graphique : </b>
                      {/*CA*/}Encaissements
                      <Switch id="CaRevenus" name="CaRevenus" checked={this.props.applicationStore.CaRevenus} onChange={this.handleBoolChange} color="default"/>
                      {/*Revenus*/}C.A.
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {this.props.applicationStore.ListePrestations.length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Prestations exclues : 
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.blacklist} onChange={this.handleBlacklistChange} renderValue={selected => selected.join(', ')} style={{width:"300px"}}>
                                {this.props.applicationStore.ListePrestations.map(prest => {
                                  let name = prest.name;
                                    if(!PrestNames.includes(name)){
                                      PrestNames.push(name);
                                      return(
                                        <MenuItem key={name} value={name}>
                                          <Checkbox checked={Object.values(this.state.blacklist).indexOf(name) > -1} />
                                          <ListItemText primary={prest.name} />
                                        </MenuItem>
                                      )
                                    }
                                  }
                                )}
                              </Select>
                            </Grid>
                            {this.props.applicationStore.ListePrestations.length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllBlacklisted}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoBlacklisted}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefreshBlacklist}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingBlacklist ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.state.loading <= 0 ? (this.state.data.length > 0 ? (
              <Grid container spacing={24}>
                <Grid item>
                  <Paper className={this.slabChoose(data[data.length-1].caTTC, data[data.length-1].caTTCN)}>
                  <p><b>{/*C.A.*/}Encaissements TTC <br/>{data[data.length-1].caTTC.toLocaleString('fr')} € {this.calcPC(data[data.length-1].caTTC, data[data.length-1].caTTCN)}</b></p>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={this.slabChoose(data[data.length-1].revenusHC, data[data.length-1].revenusHCN)}>
                  <p><b>{/*Revenus*/}C.A. HT <br/>{data[data.length-1].revenusHC.toLocaleString('fr')} € {this.calcPC(data[data.length-1].revenusHC, data[data.length-1].revenusHCN)}</b></p>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={this.slabChoose(data[data.length-1].hono, data[data.length-1].honoN)}>
                  <p><b>Honoraires {CaTaxe}<br/>{data[data.length-1].hono.toLocaleString('fr')} € {this.calcPC(data[data.length-1].hono, data[data.length-1].honoN)}</b></p>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={this.props.classes.slabPurple}>
                  <p><b>Remises {CaTaxe}<br/>{data[data.length-1].remise.toLocaleString('fr')} € {this.calcPC(data[data.length-1].remise, data[data.length-1].remiseN)}</b></p>
                  </Paper>
                </Grid>
              </Grid>
            ):(
              <Grid style={{textAlign:"center"}}>
                  <Warning color="error"/>
                  <Typography variant="overline" color="error">
                    Aucune donnée
                  </Typography>
              </Grid>
            )):(
              <Grid style={{textAlign:"center"}}>
                  <Sync className={this.props.classes.loader}/>
              </Grid>
            )}
            <Grid container spacing={24}>
              {this.state.loading <= 0 ? (this.state.data.length > 0 ? (
                <Grid item xs={12} style={{maxWidth:'fit-content'}}>
                  <Paper style={{width:'fit-content'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Agence</TableCell>
                          <Tooltip title={calculCA} placement='top'>
                            <TableCell align='right' className={this.props.classes.tableCurrent}>{/*C.A.*/}Encaissements TTC</TableCell>
                          </Tooltip>
                          <Tooltip title={calculCA} placement='top'>
                            <TableCell align='right' className={this.props.classes.tableOld}>{/*C.A.*/}Encaissements N-1 TTC</TableCell>
                          </Tooltip>
                          <Tooltip title={calculRevenus} placement='top'>
                            <TableCell align='right' className={this.props.classes.tableCurrent}>{/*Revenus*/}C.A. HT</TableCell>
                          </Tooltip>
                          <Tooltip title={calculRevenus} placement='top'>
                            <TableCell align='right' className={this.props.classes.tableOld}>{/*Revenus*/}C.A. N-1 HT</TableCell>
                          </Tooltip>
                          {((this.props.applicationStore.CaTTC && this.props.applicationStore.CaRevenus) || (!this.props.applicationStore.CaTTC && !this.props.applicationStore.CaRevenus)) && 
                            (
                              <>
                                <Tooltip title={this.props.applicationStore.CaRevenus ? calculRevenus : calculCA} placement='top'>
                                  <TableCell align='right' className={this.props.classes.tableCurrent}>{CaTypeName+" "+CaTaxe}</TableCell>
                                </Tooltip>
                                <Tooltip title={this.props.applicationStore.CaRevenus ? calculRevenus : calculCA} placement='top'>
                                  <TableCell align='right' className={this.props.classes.tableOld}>{CaTypeName+" N-1 "+CaTaxe}</TableCell>
                                </Tooltip>
                              </>
                            )
                          }
                          <Tooltip title='Frais de dossier' placement='top'>
                            <TableCell align='right' className={this.props.classes.tableCurrent}>{"F.D.D. "+CaTaxe}</TableCell>
                          </Tooltip>
                          <Tooltip title='Frais de dossier' placement='top'>
                            <TableCell align='right' className={this.props.classes.tableOld}>{"F.D.D. N-1 "+CaTaxe}</TableCell>
                          </Tooltip>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Assurance "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Assurance N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Menage "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Menage N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Prestations "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Prest. N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Taxe de séjour "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Taxe Sej. N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Honoraires "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Hono. N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Autres "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Autres N-1 "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableCurrent}>{"Remises "+CaTaxe}</TableCell>
                          <TableCell align='right' className={this.props.classes.tableOld}>{"Remises N-1 "+CaTaxe}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((n,i) => {
                          if(i<this.state.data.length){
                            return (
                              <TableRow key={i}>
                                <TableCell style={{paddingLeft:'14px', paddingRight:'4px'}}>
                                  {n.Agence}
                                </TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.caTTC, n.caTTCN)}>{n.caTTC.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.caTTCN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.revenusHC, n.revenusHCN)}>{n.revenusHC.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.revenusHCN.toLocaleString('fr')}</TableCell>
                                {((this.props.applicationStore.CaTTC && this.props.applicationStore.CaRevenus) || (!this.props.applicationStore.CaTTC && !this.props.applicationStore.CaRevenus)) && 
                                  (
                                    <>
                                      <TableCell align='right' className={this.tableChooseCurrent(n[CaType], n[CaType+'N'])}>{n[CaType].toLocaleString('fr')}</TableCell>
                                      <TableCell align='right' className={this.props.classes.tableOld}>{n[CaType+'N'].toLocaleString('fr')}</TableCell>
                                    </>
                                  )
                                }
                                <TableCell align='right' className={this.tableChooseCurrent(n.fdd, n.fddN)}>{n.fdd.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.fddN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.assur, n.assurN)}>{n.assur.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.assurN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.menage, n.menageN)}>{n.menage.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.menageN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.option, n.optionN)}>{n.option.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.optionN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.taxe_sejour, n.taxe_sejourN)}>{n.taxe_sejour.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.taxe_sejourN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.hono, n.honoN)}>{n.hono.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.honoN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.autre, n.autreN)}>{n.autre.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.autreN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.remise.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.remiseN.toLocaleString('fr')}</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                      <TableFooter>
                      {data.map((n,i) => {
                          if(i>=this.state.data.length){
                            return (
                              <TableRow key={i} className={i>this.state.data.length ? this.props.classes.tableTotal:''}>
                                <TableCell>
                                  {n.Agence}
                                </TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.caTTC, n.caTTCN)}>{n.caTTC.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.caTTCN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.revenusHC, n.revenusHCN)}>{n.revenusHC.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.revenusHCN.toLocaleString('fr')}</TableCell>
                                {((this.props.applicationStore.CaTTC && this.props.applicationStore.CaRevenus) || (!this.props.applicationStore.CaTTC && !this.props.applicationStore.CaRevenus)) && 
                                  (
                                    <>
                                      <TableCell align='right' className={this.tableChooseCurrent(n[CaType], n[CaType+'N'])}>{n[CaType].toLocaleString('fr')}</TableCell>
                                      <TableCell align='right' className={this.props.classes.tableOld}>{n[CaType+'N'].toLocaleString('fr')}</TableCell>
                                    </>
                                  )
                                }
                                <TableCell align='right' className={this.tableChooseCurrent(n.fdd, n.fddN)}>{n.fdd.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.fddN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.assur, n.assurN)}>{n.assur.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.assurN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.menage, n.menageN)}>{n.menage.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.menageN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.option, n.optionN)}>{n.option.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.optionN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.taxe_sejour, n.taxe_sejourN)}>{n.taxe_sejour.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.taxe_sejourN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.hono, n.honoN)}>{n.hono.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.honoN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.tableChooseCurrent(n.autre, n.autreN)}>{n.autre.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.autreN.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableCurrent}>{n.remise.toLocaleString('fr')}</TableCell>
                                <TableCell align='right' className={this.props.classes.tableOld}>{n.remiseN.toLocaleString('fr')}</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableFooter>
                    </Table>
                    <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                    <ExcelExport
                      data={this.dataToExcelData()}
                      fileName="CA.xlsx"
                      ref={(exporter) => { this._exporter = exporter; }}
                    >
                      <ExcelExportColumn field="Agence" title="Agence"/>
                        <ExcelExportColumn field="caTTC" title={/*C.A.*/"Encaissements"+" TTC"} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="caTTCN" title={/*C.A.*/"Encaissements"+" N-1 TTC"} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="revenusHC" title={/*Revenus*/"C.A."+" HT"} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="revenusHCN" title={/*Revenus*/"C.A."+" N-1 HT"} cellOptions={{format:"##,##0 €"}}/>
                        {((this.props.applicationStore.CaTTC && this.props.applicationStore.CaRevenus) || (!this.props.applicationStore.CaTTC && !this.props.applicationStore.CaRevenus)) && 
                          (
                            <>
                              <ExcelExportColumn field={CaType} title={CaTypeName+' '+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                              <ExcelExportColumn field={CaType+'N'} title={CaTypeName+' N-1 '+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                            </>
                          )
                        }
                        <ExcelExportColumn field="fdd" title={"F.D.D. "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="fddN" title={"F.D.D. N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="assur" title={"Assurance "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="assurN" title={"Assur N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="menage" title={"Menage "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="menageN" title={"Menage N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="option" title={"Prestations "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="optionN" title={"Prest. N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="taxe_sejour" title={"Taxe de séjour "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="taxe_sejourN" title={"Taxe Sej. N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="hono" title={"Honoraires "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="honoN" title={"Hono. N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="autre" title={"Autre "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="autreN" title={"Autre N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="remise" title={"Remise "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                        <ExcelExportColumn field="remiseN" title={"Remise N-1 "+CaTaxe} cellOptions={{format:"##,##0 €"}}/>
                    </ExcelExport>
                  </Paper>
                </Grid>
              ):(
                <Grid item style={{margin:"0 auto", textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={24}>
              {this.state.graphLoading <= 0 ? (this.state.graphData.length > 0 ? (
                <Grid item xs={12} style={{height:'440px'}}>
                  <ResponsiveLine
                      data={[
                      {
                        id:CaTypeName+" TTC N-1",                      
                        data:this.state.graphData.map(n=>({
                          x:n.dateStat,
                          y:this.props.applicationStore.CaRevenus ? n.revenusN : n.caN
                        }))
                      },
                      {
                        id:CaTypeName+" TTC",                      
                        data:this.state.graphData.map(n=>({
                          x:n.dateStat,
                          y:this.props.applicationStore.CaRevenus ? n.revenus : n.ca
                        }))
                      }
                      ]
                      }
                      margin={{
                          "top": 20,
                          "right": 110,
                          "bottom": 80,
                          "left": 60
                      }}
                      xScale={{
                          "type": "point"
                      }}
                      yScale={{
                          "type": "linear",
                          "stacked": false,
                          "min": "auto",
                          "max": "auto"
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                          "orient": "bottom",
                          "tickSize": 5,
                          "tickPadding": 5,
                          "tickRotation": -60,
                          "legend": " ",
                          "legendOffset": 36,
                          "legendPosition": "middle"
                      }}
                      axisLeft={{
                          "orient": "left",
                          "tickSize": 5,
                          "tickPadding": 5,
                          "tickRotation": 0,
                          "legend": CaTypeName+" général",
                          "legendOffset": -40,
                          "legendPosition": "middle"
                      }}
                      dotSize={10}
                      dotColor="inherit:darker(0.3)"
                      dotBorderWidth={2}
                      dotBorderColor="#ffffff"
                      enableDotLabel={true}
                      dotLabel={e => Number(e.y).toLocaleString('fr')}
                      dotLabelYOffset={-12}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      colors={[
                        "#CCCCCC",
                        "#3D91FF"                     
                      ]}
                      curve="monotoneX"
                      legends={[
                          {
                              "anchor": "top-right",
                              "direction": "column",
                              "justify": false,
                              "translateX": 100,
                              "translateY": 0,
                              "itemsSpacing": 0,
                              "itemDirection": "left-to-right",
                              "itemWidth": 80,
                              "itemHeight": 20,
                              "itemOpacity": 0.75,
                              "symbolSize": 12,
                              "symbolShape": "circle",
                              "symbolBorderColor": "rgba(0, 0, 0, .5)",
                              "effects": [
                                  {
                                      "on": "hover",
                                      "style": {
                                          "itemBackground": "rgba(0, 0, 0, .03)",
                                          "itemOpacity": 1
                                      }
                                  }
                              ]
                          }
                      ]}
                      tooltipFormat={value => Number(value).toLocaleString('fr')+' €'}
                  />
                </Grid>
              ):(
                <Grid item style={{height:'440px', margin:"0 auto", textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{height:'440px', margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
          </>
        )
      }
      </div>
    );
  }
}
