import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import BarChartIcon from '@material-ui/icons/BarChart';
import {Link} from 'react-router-dom';


export const mainListItems = (
  <div>
    <ListItem button component={Link} to={'/'}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Tableau de bord" />
    </ListItem>
  </div>
);

export const adminListItems = (
  <ListItem button component={Link} to={'/trace'}>
    <ListItemIcon>
      <ControlCameraIcon />
    </ListItemIcon>
    <ListItemText primary="Trace" />
  </ListItem>
)

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Statistiques</ListSubheader>
    <ListItem button component={Link} to={'/mandats'}>
      <ListItemIcon>
      MDT
      </ListItemIcon>
      <ListItemText primary="Mandats" />
    </ListItem>
    <ListItem button component={Link} to={'/volumes'}>
      <ListItemIcon>
      VOL
      </ListItemIcon>
      <ListItemText primary="Volumes" />
    </ListItem>
    <ListItem button component={Link} to={'/ca'}>
      <ListItemIcon>
      CA&nbsp;
      </ListItemIcon>
      <ListItemText primary="Chiffre d'affaire" />
    </ListItem>
    <ListItem button component={Link} to={'/capo'}>
      <ListItemIcon>
      CAO
      </ListItemIcon>
      <ListItemText primary="CA par Origine" />
    </ListItem>
    <ListItem button component={Link} to={'/generale'}>
      <ListItemIcon>
      GEN
      </ListItemIcon>
      <ListItemText primary="Stat.Générale" />
    </ListItem>
    <ListItem button component={Link} to={'/occupation'}>
      <ListItemIcon>
      OCC
      </ListItemIcon>
      <ListItemText primary="Taux d'occupation" />
    </ListItem>
  </div>
);
