import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import {action, autorun} from 'mobx';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import { withStyles } from '@material-ui/core/styles';
import {format} from 'date-fns';
import { subWeeks,subYears } from 'date-fns/esm';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Save, Sync, Warning } from '@material-ui/icons';

const lineChartProperties = {
    margin:{
      "top": 10,
      "right": 200,
      "bottom": 80,
      "left": 60
    },
    xScale:{
        "type": "point"
    },
    yScale:{
        "type": "linear",
        "stacked": false,
        "min": 0,
        "max": "auto"
    },
    axisTop:null,
    axisRight:null,
    axisBottom:{
        "orient": "bottom",
        "tickSize": 5,
        "tickPadding": 5,
        "tickRotation": -60,
        "legend": " ",
        "legendOffset": 36,
        "legendPosition": "middle"
    },
    dotSize:10,
    dotColor:"inherit:darker(0.3)",
    dotBorderWidth:2,
    dotBorderColor:"#ffffff",
    enabledotLabel:true,
    dotLabel:"y",
    dotLabelYOffset:-12,
    enableArea:false,
    areaOpacity:0.2,
    motionStiffness:90,
    motionDamping:15,
    colors:"category10",
    curve:"monotoneX",
    legends:[
        {
            "anchor": "top-right",
            "direction": "column",
            "justify": false,
            "translateX": 100,
            "translateY": 0,
            "itemsSpacing": 0,
            "itemDirection": "left-to-right",
            "itemWidth": 80,
            "itemHeight": 20,
            "itemOpacity": 0.75,
            "symbolSize": 12,
            "symbolShape": "circle",
            "symbolBorderColor": "rgba(0, 0, 0, .5)",
            "effects": [
                {
                    "on": "hover",
                    "style": {
                        "itemBackground": "rgba(0, 0, 0, .03)",
                        "itemOpacity": 1
                    }
                }
            ]
        }
    ]
}

const pieProperties = {
    margin:{
      "top": 40,
      "right": 100,
      "bottom": 80,
      "left": 80
    },
    innerRadius:0.5,
    padAngle:0.7,
    cornerRadius:3,
    colors:"set2",
    colorBy:"id",
    borderWidth:1,
    borderColor:"inherit:darker(0.2)",
    radialLabelsSkipAngle:10,
    radialLabelsTextXOffset:6,
    radialLabelsTextColor:"#333333",
    radialLabelsLinkOffset:0,
    radialLabelsLinkDiagonalLength:16,
    radialLabelsLinkHorizontalLength:24,
    radialLabelsLinkStrokeWidth:1,
    radialLabelsLinkColor:"inherit",
    sliceLabel:(e =>
      Number(e.value).toLocaleString('fr',{maximumFractionDigits:0})
    ),
    slicesLabelsSkipAngle:360*5/100,
    slicesLabelsTextColor:"#333333",
    animate:true,
    motionStiffness:90,
    motionDamping:15,
    defs:[
        {
            "id": "dots",
            "type": "patternDots",
            "background": "inherit",
            "color": "rgba(255, 255, 255, 0.3)",
            "size": 4,
            "padding": 1,
            "stagger": true
        },
        {
            "id": "lines",
            "type": "patternLines",
            "background": "inherit",
            "color": "rgba(255, 255, 255, 0.3)",
            "rotation": -45,
            "lineWidth": 6,
            "spacing": 10
        }
    ]
  }

const styles = theme => ({
    chartSubContainer:{
        height:'70vh',
        textAlign:'center',
        marginBottom:'5vh'
    },
    dateModal: {
      margin : '10% auto',
      width: 'fit-content',
      '&>div':{
        padding: '1%'
      }
    },
    excelButton:{
      margin:'10px',
      backgroundColor:'#00a500',
      color:'#ffffff'
    },
    loader:{
      /*border: "16px solid #f3f3f3", 
      borderTop: "16px solid #3498db", 
      borderRadius: "50%",*/
      width: "120px",
      height: "120px",
      animation: "spin 2s linear infinite"
    },
    loaderMini:{
      /*border: "16px solid #f3f3f3", 
      borderTop: "16px solid #3498db", 
      borderRadius: "50%",*/
      width: "30px",
      height: "30px",
      animation: "spin 2s linear infinite"
    },
    '@keyframes spin': {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(-360deg)" }
    }
  });

@withStyles(styles)
@inject('applicationStore')
export default class Trace extends Component {
  displayName = Trace.name
  state = {
      data: [],
      dataOptions: [],
      ModalOpen: false,
      loading: true,
      loadingOptions: true
  }

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTrace = () => {
    this.setState({
        data: [],
        dataOptions: [],
        loading: true,
        loadingOptions: true
      })
      fetch('api/Trace/getTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Date1: format(this.props.applicationStore.TraceDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.TraceDate2, 'YYYY-MM-DD')})
      }).then(data => data.json()).then(data => this.setState({data,loading:false}));
      fetch('api/Trace/getOptionsTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(data => data.json()).then(dataOptions => this.setState({dataOptions,loadingOptions:false}));
  }

  @action handleDateChange = ranges => {
    this.props.applicationStore.TraceDate1 = ranges.selection.startDate;
    this.props.applicationStore.TraceDate2 = ranges.selection.endDate;
    this.getTrace();
  };

  TraceAutorun = autorun(() => {
    if(this.props.applicationStore.Logged && this.state.data.length == 0 && this.state.dataOptions.length == 0){
      this.getTrace();
    }
  });

  render() {
    const selectedDate1 = this.props.applicationStore.TraceDate1;
    const selectedDate2 = this.props.applicationStore.TraceDate2;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const targets = [];
    this.state.data.forEach(t => {
        if(!targets.includes(t.cible)){
            targets.push(t.cible)
        }
    })
    return (
      <div>
        {!this.props.applicationStore.Logged ? (
          <>
            <h1>Connexion requise</h1>
            <Logon/>           
          </>
        ):(!(this.props.applicationStore.NomUtilisateur == "Administration" && this.props.applicationStore.PrenomUtilisateur == "Statistiques SPI LS") ? (
            <Redirect to='/'/>
        ):(
          <>
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <h1>Utilisation de l'application 'Statistiques'</h1>
                    <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                        <Paper>
                            <Grid container spacing={24}>
                                <Grid item width={400}>
                                    <DateRangePicker
                                        ranges={[selectionRange]}
                                        onChange={this.handleDateChange}
                                        locale={fr}
                                        staticRanges={defaultStaticRanges}
                                        inputRanges={defaultInputRanges}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Modal>
                    <Grid container spacing={24}>
                        <Grid item>
                            <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={24}>
            {!this.state.loading ? (this.state.data.length > 0 ? (
            <>
                <Grid item xs={12} style={{height:'440px'}}>
                    <ResponsiveLine
                    data={
                        targets.filter(d => !d.includes("export_")).reduce((acc,val) => {
                            acc.push({
                                id:val,
                                data:this.state.data.filter(d => d.cible == val).map(d=>({
                                    x:d.dateStat,
                                    y:d.nbClics
                                }))
                            })
                            return acc
                        }, [])
                    }
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "Nombre de Calculs",
                        "legendOffset": -50,
                        "legendPosition": "middle"
                    }}
                    tooltipFormat={value =>
                        Number(value).toLocaleString('fr')+' calculs'
                    }
                    {...lineChartProperties}
                    />
                </Grid>
                <Grid item xs={12} style={{height:'440px'}}>
                    <ResponsiveLine
                    data={
                        targets.filter(d => d.includes("export_")).reduce((acc,val) => {
                            acc.push({
                                id:val.substring(7),
                                data:this.state.data.filter(d => d.cible == val).map(d=>({
                                    x:d.dateStat,
                                    y:d.nbClics
                                }))
                            })
                            return acc
                        }, [])
                    }
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "Nombre d'exports",
                        "legendOffset": -50,
                        "legendPosition": "middle"
                    }}
                    tooltipFormat={value =>
                        Number(value).toLocaleString('fr')+' exports'
                    }
                    {...lineChartProperties}
                    />
                </Grid>
                <Grid item xs={12} style={{height:'440px'}}>
                    <ResponsiveLine
                    data={
                        targets.filter(d => !d.includes("export_")).reduce((acc,val) => {
                            acc.push({
                                id:val,
                                data:this.state.data.filter(d => d.cible == val).map(d=>({
                                    x:d.dateStat,
                                    y:d.nbAgences/d.nbClics
                                }))
                            })
                            return acc
                        }, [])
                    }
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "Nombre d'agences sélectionnées en moyenne",
                        "legendOffset": -50,
                        "legendPosition": "middle"
                    }}
                    tooltipFormat={value =>
                        Number(value).toLocaleString('fr')+' agences, en moyenne'
                    }
                    {...lineChartProperties}
                    />
                </Grid>
                <Grid item xs={12} style={{height:'440px'}}>
                    <ResponsiveLine
                    data={
                        targets.filter(d => !d.includes("export_")).reduce((acc,val) => {
                            acc.push({
                                id:val,
                                data:this.state.data.filter(d => d.cible == val).map(d=>({
                                    x:d.dateStat,
                                    y:d.ratioAgences*100
                                }))
                            })
                            return acc
                        }, [])
                    }
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": "% d'agences sélectionnées en moyenne",
                        "legendOffset": -50,
                        "legendPosition": "middle"
                    }}
                    tooltipFormat={value =>
                        Number(value).toLocaleString('fr',{maximumFractionDigits:2})+' % des agences, en moyenne'
                    }
                    {...lineChartProperties}
                    />
                </Grid>
            </>
            ):(
                <Grid item style={{height:'440px',margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                        Aucune donnée
                    </Typography>
                </Grid>
            )):(
                <Grid item style={{height:'440px',margin:"0 auto",textAlign:"center"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
            )}
            {!this.state.loadingOptions ? (Object.keys(this.state.dataOptions).length > 0 ? (
            <>
                <Grid item xs={12}>
                    <h1>Options</h1>
                </Grid>
                <Grid item xs={3} style={{height:'440px',textAlign:"center"}}>
                    <h3>Mandats inactifs dans "Parc actif"</h3>
                    <ResponsivePie
                        data={
                        [
                            {
                                id:"On",
                                label:"On",
                                value:this.state.dataOptions.inAlouer
                            },
                            {
                                id:"Off",
                                label:"Off",
                                value:this.state.dataOptions.comptes-this.state.dataOptions.inAlouer
                            }
                        ]
                        }
                        tooltipFormat={(value =>
                        value+' ('+Number(value/this.state.dataOptions.comptes*100).toLocaleString('fr')+' %)'
                        )}
                        {...pieProperties}
                    />
                </Grid>
                <Grid item xs={3} style={{height:'440px',textAlign:"center"}}>
                    <h3>Affichage du taux "Loués / Parc actif"</h3>
                    <ResponsivePie
                        data={
                        [
                            {
                                id:"On",
                                label:"On",
                                value:this.state.dataOptions.tauxLocatGlobal
                            },
                            {
                                id:"Off",
                                label:"Off",
                                value:this.state.dataOptions.comptes-this.state.dataOptions.tauxLocatGlobal
                            }
                        ]
                        }
                        tooltipFormat={(value =>
                        value+' ('+Number(value/this.state.dataOptions.comptes*100).toLocaleString('fr')+' %)'
                        )}
                        {...pieProperties}
                    />
                </Grid>
                <Grid item xs={3} style={{height:'440px',textAlign:"center"}}>
                    <h3>Mode d'affichage du CA</h3>
                    <ResponsivePie
                        data={
                        [
                            {
                                id:"TTC",
                                label:"TTC",
                                value:this.state.dataOptions.caTTC
                            },
                            {
                                id:"HT",
                                label:"HT",
                                value:this.state.dataOptions.comptes-this.state.dataOptions.caTTC
                            }
                        ]
                        }
                        tooltipFormat={(value =>
                        value+' ('+Number(value/this.state.dataOptions.comptes*100).toLocaleString('fr')+' %)'
                        )}
                        {...pieProperties}
                    />
                </Grid>
                <Grid item xs={3} style={{height:'440px',textAlign:"center"}}>
                    <h3>Méthode de calcul du CA (Graphiques)</h3>
                    <ResponsivePie
                        data={
                        [
                            {
                                id:"Revenus",
                                label:"Revenus (CA)",
                                value:this.state.dataOptions.caRevenus
                            },
                            {
                                id:"CA",
                                label:"CA (Encaissements)",
                                value:this.state.dataOptions.comptes-this.state.dataOptions.caRevenus
                            }
                        ]
                        }
                        tooltipFormat={(value =>
                        value+' ('+Number(value/this.state.dataOptions.comptes*100).toLocaleString('fr')+' %)'
                        )}
                        {...pieProperties}
                    />
                </Grid>
            </>
            ):(
                <Grid item style={{height:'440px',margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                        Aucune donnée
                    </Typography>
                </Grid>
            )):(
                <Grid item style={{height:'440px',margin:"0 auto",textAlign:"center"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
            )}
            </Grid>
          </>
        ))
        }
      </div>
      
    );
  }
}