import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {fr} from 'date-fns/esm/locale';
import { 
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow, 
  TextField,
  Tooltip,
  Typography 
} from '@material-ui/core';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { ResponsiveCalendar } from '@nivo/calendar';
import { Save, Sync, Warning } from '@material-ui/icons';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export'

const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableOld:{
    color: 'gray', 
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentWarning:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkred',
    textShadow: '0 0 10px #F44336',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentHappy:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkgreen',
    textShadow: '0 0 10px #4CAF50',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});

@withStyles(styles)
@inject('applicationStore')

export default class Occupation extends Component {
  displayName = Occupation.name
  state = {
    data: [],
    dataSem: [],
    ModalOpen:false,
    loading:true,
    loadingSem:true,
    selectedAgencies:Object.keys(this.props.applicationStore.OccupationAgences),
    loadingAgencies:false,
    mountLoaded:false,
    dataGlobal: {},
    cp: this.props.applicationStore.OccupationCP,
    loadingGlobal: false
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTitleOccupation(){
    let title = 'Taux d\'occupation de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.OccupationAgences).length> 1)
    {
      title = 'Taux d\'occupation des agences ';
    }
    Object.keys(this.props.applicationStore.OccupationAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.OccupationAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.OccupationAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.OccupationAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.OccupationAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.OccupationAgences[element];
          }
        }
      }

    });
    return title + temp;
  } 
  getOccupation = () => {
    this.setState({
      loading: true,
      loadingSem: true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "Occupation", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Occupation/getStatOccupation', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.OccupationDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.OccupationDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.OccupationDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.OccupationDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(data => this.setState({data,loading:false}));
    fetch('api/Occupation/getStatOccupationSem', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.OccupationDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.OccupationDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.OccupationDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.OccupationDate4, 'YYYY-MM-DD')})
    }).then(dataSem => dataSem.json()).then(dataSem => this.setState({dataSem,loadingSem:false}));
  }

  getOccupationGlobal = () => {
    this.setState({
      loadingGlobal: true
    })
    fetch('api/Occupation/getStatOccupationSemGlobal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, CP: this.props.applicationStore.OccupationCP, Date1: format(this.props.applicationStore.OccupationDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.OccupationDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.OccupationDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.OccupationDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(dataGlobal => this.setState({dataGlobal,loadingGlobal: false}));
    
  }

  transferToBools = () => {   
    const response = fetch('api/MainData/updateBools', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Alouer: this.props.applicationStore.InAlouer, TauxLocatGlobal: this.props.applicationStore.TauxLocatGlobal,CaTTC: this.props.applicationStore.CaTTC, CaRevenus: this.props.applicationStore.CaRevenus})
    }).then(() => {
      this.getOccupation()
      this.getOccupationGlobal()
    });
  }

  transferToOccupationAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    })
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "occupation"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getOccupation()
      this.updateAgencies(Agences)
    });
  }

  transferCP = () => {
    const response = fetch('api/MainData/updateCP', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, CP: this.props.applicationStore.OccupationCP, Table: "occupation"})
    }).then(() => {
      this.getOccupationGlobal()
    });
  }

  @action handleBoolChange = event => {
    if(event.target.name === 'InAlouer')
    {
      this.props.applicationStore.InAlouer = event.target.checked;
    }
    if(event.target.name === 'TauxLocatGlobal')
    {
      this.props.applicationStore.TauxLocatGlobal = event.target.checked;
    }
    this.transferToBools();
  };

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToOccupationAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.OccupationAgences = Agences
  };

  @action handleDateChange = ranges => {
    this.props.applicationStore.OccupationDate1 = ranges.selection.startDate;
    this.props.applicationStore.OccupationDate2 = ranges.selection.endDate;
    this.props.applicationStore.OccupationDate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.OccupationDate4 = subYears(ranges.selection.endDate,1);
    this.getOccupation()
    this.getOccupationGlobal()
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.OccupationDate3 = ranges.selection.startDate;
    this.props.applicationStore.OccupationDate4 = ranges.selection.endDate;
    this.getOccupation()
    this.getOccupationGlobal()
  };
  @action handleCPChange = () => {
    this.props.applicationStore.OccupationCP = this.state.cp
    this.transferCP()
    this.getOccupationGlobal()
  }
  @action CPCheck = event => {
    var vals = event.target.value.split(/[\s,;/.]/)
    var cp = vals.map(val => val.substr(0,2)).join(',')
    this.setState({cp})
  }
  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getOccupation()
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.OccupationAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.OccupationAgences)
          })
        }
        if(this.props.applicationStore.OccupationCP != ""){
          this.setState({
            cp:this.props.applicationStore.OccupationCP
          })
          this.getOccupationGlobal()
        }
      }
    });
  }

  calcPC = (val, valN) => {
    let pourcent = '';
    if(!isNaN(valN) && valN != 0)
    {
      if(val>= valN)
      {
        pourcent = "+"+(((val/valN)*100) -100).toLocaleString('fr',{maximumFractionDigits:1}) +'%';
      }
      if(valN> val)
      {
        pourcent = "-"+((1-(val/valN))*100).toLocaleString('fr',{maximumFractionDigits:1}) +'%';
      }
    }else{
      pourcent = "NaN"
    }
    return pourcent;
  }
  tableChooseCurrent = (val, valN) => {
    let look = this.props.classes.tableCurrent;
    
      if(val> valN)
      {
        if((((val/valN)*100) -100)> this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.tableCurrentHappy;
        } 
      }
      if(valN> val)
      {
        if(((1-(val/valN))*100)> this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.tableCurrentWarning;
        }
      }
   
    return look;
  }

  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_Occupation", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData = () => {
    var data = this.state.dataSem.map(n => ({
      refAgence: n.refAgence,
      dateStat: n.dateStat,
      aLouer: n.aLouer,
      aLouerN: n.aLouerN,
      occupLocat: n.occupLocat,
      occupLocatN: n.occupLocatN,
      occupProp: n.occupProp,
      occupPropN: n.occupPropN
    }))
    let excelData = [];
    let dateList = data.filter(chunck => chunck.refAgence == Object.keys(this.props.applicationStore.OccupationAgences)[0]);
    if(dateList !== []){
      dateList.map((n,i) => {
        //Valeurs
        excelData[i]={};
        excelData[i]["date"]=format(n.dateStat, 'DD-MM-YYYY');
        Object.keys(this.props.applicationStore.OccupationAgences).map(m => {
          let dataDateAg = data.filter(chunck => chunck.refAgence == m && chunck.dateStat == n.dateStat);
          let lineDateAg = dataDateAg[0] || {aLouer:0,occupProp:0,occupLocat:0};
          excelData[i][m+"_aLouer"]=lineDateAg.aLouer;
          excelData[i][m+"_occupProp"]=lineDateAg.occupProp;
          excelData[i][m+"_dispoLocat"]=excelData[i][m+"_aLouer"]-excelData[i][m+"_occupProp"];
          excelData[i][m+"_occupLocat"]=lineDateAg.occupLocat;
          excelData[i][m+"_tauxLocat"]=excelData[i][m+"_occupLocat"]/excelData[i][m+"_dispoLocat"];
          if(isNaN(excelData[i][m+"_tauxLocat"])){
            excelData[i][m+"_tauxLocat"]="NaN";
          }
          excelData[i][m+"_tauxLocatGlobal"]=excelData[i][m+"_occupLocat"]/excelData[i][m+"_aLouer"];
          if(isNaN(excelData[i][m+"_tauxLocatGlobal"])){
            excelData[i][m+"_tauxLocatGlobal"]="NaN";
          }
          excelData[i][m+"_tauxProp"]=excelData[i][m+"_occupProp"]/excelData[i][m+"_aLouer"];
          if(isNaN(excelData[i][m+"_tauxProp"])){
            excelData[i][m+"_tauxProp"]="NaN";
          }
          excelData[i][m+"_tauxGlobal"]=(excelData[i][m+"_occupLocat"]+excelData[i][m+"_occupProp"])/excelData[i][m+"_aLouer"];
          if(isNaN(excelData[i][m+"_tauxGlobal"])){
            excelData[i][m+"_tauxGlobal"]="NaN";
          }
          excelData[i][m+"_aLouerRestant"]=excelData[i][m+"_aLouer"]-(excelData[i][m+"_occupLocat"]+excelData[i][m+"_occupProp"]);
        })
        //Total Date
        let dataDate = data.filter(chunck => chunck.dateStat == n.dateStat);
        excelData[i]["total_aLouer"]=dataDate.reduce((t,n) => t+n.aLouer,0);
        excelData[i]["total_occupProp"]=dataDate.reduce((t,n) => t+n.occupProp,0);
        excelData[i]["total_dispoLocat"]=excelData[i]["total_aLouer"]-excelData[i]["total_occupProp"];
        excelData[i]["total_occupLocat"]=dataDate.reduce((t,n) => t+n.occupLocat,0);
        excelData[i]["total_tauxLocat"]=excelData[i]["total_occupLocat"]/excelData[i]["total_dispoLocat"];
        if(isNaN(excelData[i]["total_tauxLocat"])){
          excelData[i]["total_tauxLocat"]="NaN";
        }
        excelData[i]["total_tauxLocatGlobal"]=excelData[i]["total_occupLocat"]/excelData[i]["total_aLouer"];
          if(isNaN(excelData[i]["total_tauxLocatGlobal"])){
            excelData[i]["total_tauxLocatGlobal"]="NaN";
          }
        excelData[i]["total_tauxProp"]=excelData[i]["total_occupProp"]/excelData[i]["total_aLouer"];
        if(isNaN(excelData[i]["total_tauxProp"])){
          excelData[i]["total_tauxProp"]="NaN";
        }
        excelData[i]["total_tauxGlobal"]=(excelData[i]["total_occupLocat"]+excelData[i]["total_occupProp"])/excelData[i]["total_aLouer"];
        if(isNaN(excelData[i]["total_tauxGlobal"])){
          excelData[i]["total_tauxGlobal"]="NaN";
        }
        excelData[i]["total_aLouerRestant"]=excelData[i]["total_aLouer"]-(excelData[i]["total_occupLocat"]+excelData[i]["total_occupProp"]);

        //Total Date N
        excelData[i]["totalN_aLouer"]=dataDate.reduce((t,n) => t+n.aLouerN,0);
        excelData[i]["totalN_occupProp"]=dataDate.reduce((t,n) => t+n.occupPropN,0);
        excelData[i]["totalN_dispoLocat"]=excelData[i]["totalN_aLouer"]-excelData[i]["totalN_occupProp"];
        excelData[i]["totalN_occupLocat"]=dataDate.reduce((t,n) => t+n.occupLocatN,0);
        excelData[i]["totalN_tauxLocat"]=excelData[i]["totalN_occupLocat"]/excelData[i]["totalN_dispoLocat"];
        if(isNaN(excelData[i]["totalN_tauxLocat"])){
          excelData[i]["totalN_tauxLocat"]="NaN";
        }
        excelData[i]["totalN_tauxLocatGlobal"]=excelData[i]["totalN_occupLocat"]/excelData[i]["totalN_aLouer"];
          if(isNaN(excelData[i]["totalN_tauxLocatGlobal"])){
            excelData[i]["totalN_tauxLocatGlobal"]="NaN";
          }
        excelData[i]["totalN_tauxProp"]=excelData[i]["totalN_occupProp"]/excelData[i]["totalN_aLouer"];
        if(isNaN(excelData[i]["totalN_tauxProp"])){
          excelData[i]["totalN_tauxProp"]="NaN";
        }
        excelData[i]["totalN_tauxGlobal"]=(excelData[i]["totalN_occupLocat"]+excelData[i]["totalN_occupProp"])/excelData[i]["totalN_aLouer"];
        if(isNaN(excelData[i]["totalN_tauxGlobal"])){
          excelData[i]["totalN_tauxGlobal"]="NaN";
        }
        excelData[i]["totalN_aLouerRestant"]=excelData[i]["totalN_aLouer"]-(excelData[i]["totalN_occupLocat"]+excelData[i]["totalN_occupProp"]);
        
        //Diff
        excelData[i]["diff"]=(excelData[i]["total_tauxGlobal"]/excelData[i]["totalN_tauxGlobal"])-1;
        if(isNaN(excelData[i]["diff"])){
          excelData[i]["diff"]="NaN";
        }
      })
      let l = excelData.length;
      if(l !== 0){
        //Moyennes
        excelData[l]={};
        excelData[l]["date"]="Moyenne";
        Object.keys(this.props.applicationStore.OccupationAgences).map(m => {
          let dataAg = data.filter(chunck => chunck.refAgence == m);
          excelData[l][m+"_aLouer"]=Math.round(dataAg.reduce((t,n) => t+n.aLouer,0)/dataAg.length);
          excelData[l][m+"_occupProp"]=Math.round(dataAg.reduce((t,n) => t+n.occupProp,0)/dataAg.length);
          excelData[l][m+"_dispoLocat"]=excelData[l][m+"_aLouer"]-excelData[l][m+"_occupProp"];
          excelData[l][m+"_occupLocat"]=Math.round(dataAg.reduce((t,n) => t+n.occupLocat,0)/dataAg.length);
          excelData[l][m+"_tauxLocat"]=excelData[l][m+"_occupLocat"]/excelData[l][m+"_dispoLocat"];
          if(isNaN(excelData[l][m+"_tauxLocat"])){
            excelData[l][m+"_tauxLocat"]="NaN";
          }
          excelData[l][m+"_tauxLocatGlobal"]=excelData[l][m+"_occupLocat"]/excelData[l][m+"_aLouer"];
          if(isNaN(excelData[l][m+"_tauxLocatGlobal"])){
            excelData[l][m+"_tauxLocatGlobal"]="NaN";
          }
          excelData[l][m+"_tauxProp"]=excelData[l][m+"_occupProp"]/excelData[l][m+"_aLouer"];
          if(isNaN(excelData[l][m+"_tauxProp"])){
            excelData[l][m+"_tauxProp"]="NaN";
          }
          excelData[l][m+"_tauxGlobal"]=(excelData[l][m+"_occupLocat"]+excelData[l][m+"_occupProp"])/excelData[l][m+"_aLouer"];
          if(isNaN(excelData[l][m+"_tauxGlobal"])){
            excelData[l][m+"_tauxGlobal"]="NaN";
          }
          excelData[l][m+"_aLouerRestant"]=excelData[l][m+"_aLouer"]-(excelData[l][m+"_occupLocat"]+excelData[l][m+"_occupProp"]);
        })
        excelData[l]["total_aLouer"]=Math.round(data.reduce((t,n) => t+n.aLouer,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["total_occupProp"]=Math.round(data.reduce((t,n) => t+n.occupProp,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["total_dispoLocat"]=excelData[l]["total_aLouer"]-excelData[l]["total_occupProp"];
        excelData[l]["total_occupLocat"]=Math.round(data.reduce((t,n) => t+n.occupLocat,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["total_tauxLocat"]=excelData[l]["total_occupLocat"]/excelData[l]["total_dispoLocat"];
        if(isNaN(excelData[l]["total_tauxLocat"])){
          excelData[l]["total_tauxLocat"]="NaN";
        }
        excelData[l]["total_tauxLocatGlobal"]=excelData[l]["total_occupLocat"]/excelData[l]["total_aLouer"];
        if(isNaN(excelData[l]["total_tauxLocatGlobal"])){
          excelData[l]["total_tauxLocatGlobal"]="NaN";
        }
        excelData[l]["total_tauxProp"]=excelData[l]["total_occupProp"]/excelData[l]["total_aLouer"];
        if(isNaN(excelData[l]["total_tauxProp"])){
          excelData[l]["total_tauxProp"]="NaN";
        }
        excelData[l]["total_tauxGlobal"]=(excelData[l]["total_occupLocat"]+excelData[l]["total_occupProp"])/excelData[l]["total_aLouer"];
        if(isNaN(excelData[l]["total_tauxGlobal"])){
          excelData[l]["total_tauxGlobal"]="NaN";
        }
        excelData[l]["total_aLouerRestant"]=excelData[l]["total_aLouer"]-(excelData[l]["total_occupLocat"]+excelData[l]["total_occupProp"]);

        excelData[l]["totalN_aLouer"]=Math.round(data.reduce((t,n) => t+n.aLouerN,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["totalN_occupProp"]=Math.round(data.reduce((t,n) => t+n.occupPropN,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["totalN_dispoLocat"]=excelData[l]["totalN_aLouer"]-excelData[l]["totalN_occupProp"];
        excelData[l]["totalN_occupLocat"]=Math.round(data.reduce((t,n) => t+n.occupLocatN,0)/(data.length/Object.keys(this.props.applicationStore.OccupationAgences).length));
        excelData[l]["totalN_tauxLocat"]=excelData[l]["totalN_occupLocat"]/excelData[l]["totalN_dispoLocat"];
        if(isNaN(excelData[l]["totalN_tauxLocat"])){
          excelData[l]["totalN_tauxLocat"]="NaN";
        }
        excelData[l]["totalN_tauxLocatGlobal"]=excelData[l]["totalN_occupLocat"]/excelData[l]["totalN_aLouer"];
        if(isNaN(excelData[l]["totalN_tauxLocatGlobal"])){
          excelData[l]["totalN_tauxLocatGlobal"]="NaN";
        }
        excelData[l]["totalN_tauxProp"]=excelData[l]["totalN_occupProp"]/excelData[l]["totalN_aLouer"];
        if(isNaN(excelData[l]["totalN_tauxProp"])){
          excelData[l]["totalN_tauxProp"]="NaN";
        }
        excelData[l]["totalN_tauxGlobal"]=(excelData[l]["totalN_occupLocat"]+excelData[l]["totalN_occupProp"])/excelData[l]["totalN_aLouer"];
        if(isNaN(excelData[l]["totalN_tauxGlobal"])){
          excelData[l]["totalN_tauxGlobal"]="NaN";
        }
        excelData[l]["totalN_aLouerRestant"]=excelData[l]["totalN_aLouer"]-(excelData[l]["totalN_occupLocat"]+excelData[l]["totalN_occupProp"]);
        
        excelData[l]["diff"]=(excelData[l]["total_tauxGlobal"]/excelData[l]["totalN_tauxGlobal"])-1;
        if(isNaN(excelData[l]["diff"])){
          excelData[l]["diff"]="NaN";
        }

        //Totaux
        excelData[l+1]={};
        excelData[l+1]["date"]="Total";
        Object.keys(this.props.applicationStore.OccupationAgences).map(m => {
          let dataAg = data.filter(chunck => chunck.refAgence == m);
          excelData[l+1][m+"_aLouer"]=dataAg.reduce((t,n) => t+n.aLouer,0);
          excelData[l+1][m+"_occupProp"]=dataAg.reduce((t,n) => t+n.occupProp,0);
          excelData[l+1][m+"_dispoLocat"]=excelData[l+1][m+"_aLouer"]-excelData[l+1][m+"_occupProp"];
          excelData[l+1][m+"_occupLocat"]=dataAg.reduce((t,n) => t+n.occupLocat,0);
          excelData[l+1][m+"_tauxLocat"]=excelData[l+1][m+"_occupLocat"]/excelData[l+1][m+"_dispoLocat"];
          if(isNaN(excelData[l+1][m+"_tauxLocat"])){
            excelData[l+1][m+"_tauxLocat"]="NaN";
          }
          excelData[l+1][m+"_tauxLocatGlobal"]=excelData[l+1][m+"_occupLocat"]/excelData[l+1][m+"_aLouer"];
          if(isNaN(excelData[l+1][m+"_tauxLocatGlobal"])){
            excelData[l+1][m+"_tauxLocatGlobal"]="NaN";
          }
          excelData[l+1][m+"_tauxProp"]=excelData[l+1][m+"_occupProp"]/excelData[l+1][m+"_aLouer"];
          if(isNaN(excelData[l+1][m+"_tauxProp"])){
            excelData[l+1][m+"_tauxProp"]="NaN";
          }
          excelData[l+1][m+"_tauxGlobal"]=(excelData[l+1][m+"_occupLocat"]+excelData[l+1][m+"_occupProp"])/excelData[l+1][m+"_aLouer"];
          if(isNaN(excelData[l+1][m+"_tauxGlobal"])){
            excelData[l+1][m+"_tauxGlobal"]="NaN";
          }
          excelData[l+1][m+"_aLouerRestant"]=excelData[l+1][m+"_aLouer"]-(excelData[l+1][m+"_occupLocat"]+excelData[l+1][m+"_occupProp"]);
        })
        excelData[l+1]["total_aLouer"]=data.reduce((t,n) => t+n.aLouer,0);
        excelData[l+1]["total_occupProp"]=data.reduce((t,n) => t+n.occupProp,0);
        excelData[l+1]["total_dispoLocat"]=excelData[l+1]["total_aLouer"]-excelData[l+1]["total_occupProp"];
        excelData[l+1]["total_occupLocat"]=data.reduce((t,n) => t+n.occupLocat,0);
        excelData[l+1]["total_tauxLocat"]=excelData[l+1]["total_occupLocat"]/excelData[l+1]["total_dispoLocat"];
        if(isNaN(excelData[l+1]["total_tauxLocat"])){
          excelData[l+1]["total_tauxLocat"]="NaN";
        }
        excelData[l+1]["total_tauxLocatGlobal"]=excelData[l+1]["total_occupLocat"]/excelData[l+1]["total_aLouer"];
        if(isNaN(excelData[l+1]["total_tauxLocatGlobal"])){
          excelData[l+1]["total_tauxLocatGlobal"]="NaN";
        }
        excelData[l+1]["total_tauxProp"]=excelData[l+1]["total_occupProp"]/excelData[l+1]["total_aLouer"];
        if(isNaN(excelData[l+1]["total_tauxProp"])){
          excelData[l+1]["total_tauxProp"]="NaN";
        }
        excelData[l+1]["total_tauxGlobal"]=(excelData[l+1]["total_occupLocat"]+excelData[l+1]["total_occupProp"])/excelData[l+1]["total_aLouer"];
        if(isNaN(excelData[l+1]["total_tauxGlobal"])){
          excelData[l+1]["total_tauxGlobal"]="NaN";
        }
        excelData[l+1]["total_aLouerRestant"]=excelData[l+1]["total_aLouer"]-(excelData[l+1]["total_occupLocat"]+excelData[l+1]["total_occupProp"]);

        excelData[l+1]["totalN_aLouer"]=data.reduce((t,n) => t+n.aLouerN,0);
        excelData[l+1]["totalN_occupProp"]=data.reduce((t,n) => t+n.occupPropN,0);
        excelData[l+1]["totalN_dispoLocat"]=excelData[l+1]["totalN_aLouer"]-excelData[l+1]["totalN_occupProp"];
        excelData[l+1]["totalN_occupLocat"]=data.reduce((t,n) => t+n.occupLocatN,0);
        excelData[l+1]["totalN_tauxLocat"]=excelData[l+1]["totalN_occupLocat"]/excelData[l+1]["totalN_dispoLocat"];
        if(isNaN(excelData[l+1]["totalN_tauxLocat"])){
          excelData[l+1]["totalN_tauxLocat"]="NaN";
        }
        excelData[l+1]["totalN_tauxLocatGlobal"]=excelData[l+1]["totalN_occupLocat"]/excelData[l+1]["totalN_aLouer"];
        if(isNaN(excelData[l+1]["totalN_tauxLocatGlobal"])){
          excelData[l+1]["totalN_tauxLocatGlobal"]="NaN";
        }
        excelData[l+1]["totalN_tauxProp"]=excelData[l+1]["totalN_occupProp"]/excelData[l+1]["totalN_aLouer"];
        if(isNaN(excelData[l+1]["totalN_tauxProp"])){
          excelData[l+1]["totalN_tauxProp"]="NaN";
        }
        excelData[l+1]["totalN_tauxGlobal"]=(excelData[l+1]["totalN_occupLocat"]+excelData[l+1]["totalN_occupProp"])/excelData[l+1]["totalN_aLouer"];
        if(isNaN(excelData[l+1]["totalN_tauxGlobal"])){
          excelData[l+1]["totalN_tauxGlobal"]="NaN";
        }
        excelData[l+1]["totalN_aLouerRestant"]=excelData[l+1]["totalN_aLouer"]-(excelData[l+1]["totalN_occupLocat"]+excelData[l+1]["totalN_occupProp"]);
        
        excelData[l+1]["diff"]=(excelData[l+1]["total_tauxGlobal"]/excelData[l+1]["totalN_tauxGlobal"])-1;
        if(isNaN(excelData[l+1]["diff"])){
          excelData[l+1]["diff"]="NaN";
        }

        //Moyenne Départementale
        /*excelData[l+2]={};
        if(Object.keys(this.state.dataGlobal).length > 0){
          let dataAg = this.state.dataGlobal
          Object.keys(this.props.applicationStore.OccupationAgences).map(m => {
            excelData[l+2][m+"_aLouer"]='';
            excelData[l+2][m+"_occupProp"]='';
            excelData[l+2][m+"_dispoLocat"]=dataAg.aLouer-dataAg.occupProp;
            excelData[l+2][m+"_occupLocat"]='';
            excelData[l+2][m+"_tauxLocat"]=dataAg.occupLocat/excelData[l+2][m+"_dispoLocat"];
            if(isNaN(excelData[l+2][m+"_tauxLocat"])){
              excelData[l+2][m+"_tauxLocat"]="NaN";
            }
            excelData[l+2][m+"_tauxLocatGlobal"]=dataAg.occupLocat/dataAg.aLouer;
            if(isNaN(excelData[l+2][m+"_tauxLocatGlobal"])){
              excelData[l+2][m+"_tauxLocatGlobal"]="NaN";
            }
            excelData[l+2][m+"_tauxProp"]=dataAg.occupProp/dataAg.aLouer;
            if(isNaN(excelData[l+2][m+"_tauxProp"])){
              excelData[l+2][m+"_tauxProp"]="NaN";
            }
            excelData[l+2][m+"_tauxGlobal"]=(dataAg.occupLocat+dataAg.occupProp)/dataAg.aLouer;
            if(isNaN(excelData[l+2][m+"_tauxGlobal"])){
              excelData[l+2][m+"_tauxGlobal"]="NaN";
            }
            excelData[l+2][m+"_aLouerRestant"]=dataAg.aLouer-(dataAg.occupLocat+dataAg.occupProp);
          })
          excelData[l+2]["total_aLouer"]='';
          excelData[l+2]["total_occupProp"]='';
          excelData[l+2]["total_dispoLocat"]=dataAg.aLouer-dataAg.occupProp;
          excelData[l+2]["total_occupLocat"]='';
          excelData[l+2]["total_tauxLocat"]=dataAg.occupLocat/excelData[l+2]["total_dispoLocat"];
          if(isNaN(excelData[l+2]["total_tauxLocat"])){
            excelData[l+2]["total_tauxLocat"]="NaN";
          }
          excelData[l+2]["total_tauxLocatGlobal"]=dataAg.occupLocat/dataAg.aLouer;
          if(isNaN(excelData[l+2]["total_tauxLocatGlobal"])){
            excelData[l+2]["total_tauxLocatGlobal"]="NaN";
          }
          excelData[l+2]["total_tauxProp"]=dataAg.occupProp/dataAg.aLouer;
          if(isNaN(excelData[l+2]["total_tauxProp"])){
            excelData[l+2]["total_tauxProp"]="NaN";
          }
          excelData[l+2]["total_tauxGlobal"]=(dataAg.occupLocat+dataAg.occupProp)/dataAg.aLouer;
          if(isNaN(excelData[l+2]["total_tauxGlobal"])){
            excelData[l+2]["total_tauxGlobal"]="NaN";
          }
          excelData[l+2]["total_aLouerRestant"]=dataAg.aLouer-(excelData[l+2]["total_occupLocat"]+dataAg.occupProp);

          excelData[l+2]["totalN_aLouer"]='';
          excelData[l+2]["totalN_occupProp"]='';
          excelData[l+2]["totalN_dispoLocat"]=dataAg.aLouerN-dataAg.occupPropN;
          excelData[l+2]["totalN_occupLocat"]='';
          excelData[l+2]["totalN_tauxLocat"]=dataAg.occupLocatN/excelData[l+2]["totalN_dispoLocat"];
          if(isNaN(excelData[l+2]["totalN_tauxLocat"])){
            excelData[l+2]["totalN_tauxLocat"]="NaN";
          }
          excelData[l+2]["totalN_tauxLocatGlobal"]=dataAg.occupLocatN/dataAg.aLouerN;
          if(isNaN(excelData[l+2]["totalN_tauxLocatGlobal"])){
            excelData[l+2]["totalN_tauxLocatGlobal"]="NaN";
          }
          excelData[l+2]["totalN_tauxProp"]=dataAg.occupPropN/dataAg.aLouerN;
          if(isNaN(excelData[l+2]["totalN_tauxProp"])){
            excelData[l+2]["totalN_tauxProp"]="NaN";
          }
          excelData[l+2]["totalN_tauxGlobal"]=(dataAg.occupLocatN+dataAg.occupPropN)/dataAg.aLouerN;
          if(isNaN(excelData[l+2]["totalN_tauxGlobal"])){
            excelData[l+2]["totalN_tauxGlobal"]="NaN";
          }
          excelData[l+2]["totalN_aLouerRestant"]=dataAg.aLouerN-(excelData[l+2]["totalN_occupLocat"]+dataAg.occupPropN);
          
          excelData[l+2]["diff"]=(excelData[l+2]["total_tauxGlobal"]/excelData[l+2]["totalN_tauxGlobal"])-1;
          if(isNaN(excelData[l+2]["diff"])){
            excelData[l+2]["diff"]="NaN";
          }
        }else{
          excelData[l+2]=excelData[l];
        }
        excelData[l+2]["date"]="Moyenne départementale";*/
      }
    }

    return excelData
  }

  render() {
    const selectedDate1 = this.props.applicationStore.OccupationDate1;
    const selectedDate2 = this.props.applicationStore.OccupationDate2;
    const selectedDate3 = this.props.applicationStore.OccupationDate3;
    const selectedDate4 = this.props.applicationStore.OccupationDate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }
    let dateList = this.state.dataSem.filter(chunck => chunck.refAgence == Object.keys(this.props.applicationStore.OccupationAgences)[0])
    let data=this.dataToExcelData();
    let calculAlouer = "Parc Actif = Mandats actifs";
    if(this.props.applicationStore.InAlouer){
      calculAlouer += " + inactifs";
    }
    let tooltipProp = "Occupation propriétaire = Réservations propriétaires";
    let tooltipLocat = "Loués = Réservations Locataires";
    let calculDispo = "Disponible à la location = Parc actif - Occupation propriétaire";
    let calculTauxLocat = "Taux d'occupation locataire = Loués / Disponible à la location";
    let calculTauxLocatGlobal = "Taux d'occupation locataire global = Loués / Parc actif";
    let calculTauxProp = "Taux d'occupation propriétaire = Occupation propriétaire / Parc actif";
    let calculTauxGlobal = "Taux d'occupation global = (Occupation propriétaire + Loués) / Parc actif";
    let calculRestantALouer = "Restant à louer = Disponible à la location - Loués";
    return (
      <div>
      {!this.props.applicationStore.Logged ? (
        <>
        <h1>Connexion requise</h1>
          <Logon/>           
        </>
      ):(
        <>
  
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleOccupation()}</h1>
                <p>Taux d'occupation pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                    Mandats inactifs dans "Parc actif"
                      <Switch id="InAlouer" name="InAlouer" checked={this.props.applicationStore.InAlouer} onChange={this.handleBoolChange}/>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      Afficher le taux "Loués / Parc Actif"
                      <Switch id="TauxLocatGlobal" name="TauxLocatGlobal" checked={this.props.applicationStore.TauxLocatGlobal} onChange={this.handleBoolChange}/>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  {/*<Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        <Grid item style={{padding:"8px 4px"}}>
                          Code Département (Moyenne départementale) : &nbsp;
                        </Grid>
                        <Grid item>
                          <TextField id="CP" name="CP" type='text' onChange={this.CPCheck} value={this.state.cp} style={{ marginRight: '5px' }}/>
                          <Button variant='contained' color='secondary' onClick={this.handleCPChange}>Actualiser</Button>
                        </Grid>
                        <Grid item style={{textAlign:"center",visibility:this.state.loadingGlobal ? "visible" : "hidden"}}>
                          <Sync className={this.props.classes.loaderMini}/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {this.state.loadingSem <= 0 ? (this.state.dataSem.length> 0 ? (
                <Grid item xs={12} style={{maxWidth:'fit-content'}}>
                  <Paper style={{width:'fit-content'}}>
                    <Table padding='dense'>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{borderBottom:'none', borderRight: '1px solid silver'}}></TableCell>
                          {Object.values(this.props.applicationStore.OccupationAgences).map((n,i) => {
                            return (
                              <TableCell key={i} colSpan={this.props.applicationStore.TauxLocatGlobal ? 9 : 8} align='center' style={{borderBottom:'none', borderRight: '1px solid silver'}}>{n}</TableCell>
                            );
                          })}
                          <TableCell colSpan={this.props.applicationStore.TauxLocatGlobal ? 9 : 8} align='center' style={{borderBottom:'none', borderRight: '1px solid silver', borderLeft: '1px solid silver'}}>Total</TableCell>
                          <TableCell colSpan={this.props.applicationStore.TauxLocatGlobal ? 9 : 8} align='center' style={{borderBottom:'none', borderRight: '1px solid silver'}}>Total N-1</TableCell>
                          <TableCell align='center' style={{borderBottom:'none'}}>Différence</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{borderRight: '1px solid silver'}}>Semaine du</TableCell>
                          {Object.keys(this.props.applicationStore.OccupationAgences).map((n,i) => {
                              return (
                                <>
                                  <Tooltip title={calculAlouer} placement='top'>
                                    <TableCell>Parc actif</TableCell>
                                  </Tooltip>
                                  <Tooltip title={tooltipProp} placement='top'>
                                    <TableCell>Occupation propriétaire</TableCell>
                                  </Tooltip>
                                  <Tooltip title={calculTauxProp} placement='top'>
                                    <TableCell>Taux d'occupation propriétaire</TableCell>
                                  </Tooltip>
                                  <Tooltip title={calculDispo} placement='top'>
                                    <TableCell>Disponible à la location</TableCell>
                                  </Tooltip>
                                  <Tooltip title={tooltipLocat} placement='top'>
                                    <TableCell>Loués</TableCell>
                                  </Tooltip>
                                  <Tooltip title={calculTauxLocat} placement='top'>
                                    <TableCell>Loués / Disponible à la location</TableCell>
                                  </Tooltip>
                                  {this.props.applicationStore.TauxLocatGlobal && (
                                    <Tooltip title={calculTauxLocatGlobal} placement='top'>
                                      <TableCell>Loués / Parc actif</TableCell>
                                    </Tooltip>
                                  )}
                                  <Tooltip title={calculRestantALouer} placement='top'>
                                    <TableCell>Restant A Louer</TableCell>
                                  </Tooltip>
                                  <Tooltip title={calculTauxGlobal} placement='top'>
                                    <TableCell  style={{borderRight: '1px solid silver'}}>Taux d'occupation global</TableCell>
                                  </Tooltip>
                                </>
                              );
                            })}
                              <Tooltip title={calculAlouer} placement='top'>
                                <TableCell>Parc actif</TableCell>
                              </Tooltip>
                              <Tooltip title={tooltipProp} placement='top'>
                                <TableCell>Occupation propriétaire</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxProp} placement='top'>
                                <TableCell>Taux d'occupation propriétaire</TableCell>
                              </Tooltip>
                              <Tooltip title={calculDispo} placement='top'>
                                <TableCell>Disponible à la location</TableCell>
                              </Tooltip>
                              <Tooltip title={tooltipLocat} placement='top'>
                                <TableCell>Loués</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxLocat} placement='top'>
                                <TableCell>Loués / Disponible à la location</TableCell>
                              </Tooltip>
                              {this.props.applicationStore.TauxLocatGlobal && (
                                <Tooltip title={calculTauxLocatGlobal} placement='top'>
                                  <TableCell>Loués / Parc actif</TableCell>
                                </Tooltip>
                              )}
                              <Tooltip title={calculRestantALouer} placement='top'>
                                <TableCell>Restant A Louer</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxGlobal} placement='top'>
                                <TableCell  style={{borderRight: '1px solid silver'}}>Taux d'occupation Global</TableCell>
                              </Tooltip><Tooltip title={calculAlouer} placement='top'>
                                <TableCell>Parc actif</TableCell>
                              </Tooltip>
                              <Tooltip title={tooltipProp} placement='top'>
                                <TableCell>Occupation propriétaire</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxProp} placement='top'>
                                <TableCell>Taux d'occupation propriétaire</TableCell>
                              </Tooltip>
                              <Tooltip title={calculDispo} placement='top'>
                                <TableCell>Disponible à la location</TableCell>
                              </Tooltip>
                              <Tooltip title={tooltipLocat} placement='top'>
                                <TableCell>Loués</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxLocat} placement='top'>
                                <TableCell>Loués / Disponible à la location</TableCell>
                              </Tooltip>
                              {this.props.applicationStore.TauxLocatGlobal && (
                                <Tooltip title={calculTauxLocatGlobal} placement='top'>
                                  <TableCell>Loués / Parc actif</TableCell>
                                </Tooltip>
                              )}
                              <Tooltip title={calculRestantALouer} placement='top'>
                                <TableCell>Restant A Louer</TableCell>
                              </Tooltip>
                              <Tooltip title={calculTauxGlobal} placement='top'>
                                <TableCell  style={{borderRight: '1px solid silver'}}>Taux d'occupation Global</TableCell>
                              </Tooltip>
                              <TableCell></TableCell>                        
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          data.map((n,i) => {
                            let dataDate = this.state.dataSem.filter(chunck => chunck.dateStat == n.date);
                            if(i<dateList.length){
                              return (
                              <TableRow key={i}>
                                <TableCell style={{borderRight:'1px solid silver'}}>
                                  {n["date"]}
                                </TableCell>
                                {Object.keys(this.props.applicationStore.OccupationAgences).map((m,k) => {                           
                                  return (
                                    <>
                                      <TableCell className={this.props.classes.tableCurrent}>{n[m+"_aLouer"].toLocaleString('fr')}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}>{n[m+"_occupProp"].toLocaleString('fr')}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}>{(n[m+"_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}>{n[m+"_dispoLocat"].toLocaleString('fr')}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}>{n[m+"_occupLocat"].toLocaleString('fr')}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}>{(n[m+"_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                      {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableCurrent}>{(n[m+"_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                      <TableCell className={this.props.classes.tableCurrent}>{n[m+"_aLouerRestant"].toLocaleString('fr')}</TableCell>
                                      <TableCell className={this.props.classes.tableCurrent}  style={{borderRight:'1px solid silver'}}>{(n[m+"_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                    </>
                                  );
                                })}
                                <TableCell className={this.props.classes.tableTotal}>{n["total_aLouer"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{n["total_occupProp"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{(n["total_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{n["total_dispoLocat"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{n["total_occupLocat"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{(n["total_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableTotal}>{(n["total_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                <TableCell className={this.props.classes.tableTotal}>{n["total_aLouerRestant"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}  style={{borderRight:'1px solid silver'}}>{(n["total_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{n["totalN_aLouer"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{n["totalN_occupProp"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{(n["totalN_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{n["totalN_dispoLocat"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{n["totalN_occupLocat"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld}>{(n["totalN_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableOld}>{(n["totalN_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                <TableCell className={this.props.classes.tableOld}>{n["totalN_aLouerRestant"].toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld}  style={{borderRight:'1px solid silver'}}>{(n["totalN_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                <TableCell className={this.props.classes.tableTotal}>{this.calcPC(n["total_tauxGlobal"],n["totalN_tauxGlobal"])}</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow className={this.props.classes.tableTotal}>
                          <TableCell style={{borderRight:'1px solid silver'}}>
                            Moyenne
                          </TableCell>
                          {data.length !== 0 && (
                          <>
                            {Object.keys(this.props.applicationStore.OccupationAgences).map((m,k) => {
                              return(
                                <>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-3][m+"_aLouer"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-3][m+"_occupProp"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-3][m+"_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-3][m+"_dispoLocat"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-3][m+"_occupLocat"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-3][m+"_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-3][m+"_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-3][m+"_aLouerRestant"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}  style={{borderRight:'1px solid silver'}}>{(data[data.length-3][m+"_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                </>
                              );
                            })}
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-3]["total_aLouer"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-3]["total_occupProp"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-3]["total_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-3]["total_dispoLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-3]["total_occupLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-3]["total_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableTotal}>{(data[data.length-3]["total_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-3]["total_aLouerRestant"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}  style={{borderRight:'1px solid silver'}}>{(data[data.length-3]["total_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-3]["totalN_aLouer"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-3]["totalN_occupProp"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-3]["totalN_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-3]["totalN_dispoLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-3]["totalN_occupLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-3]["totalN_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableOld}>{(data[data.length-3]["totalN_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-3]["totalN_aLouerRestant"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}  style={{borderRight:'1px solid silver'}}>{(data[data.length-3]["totalN_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{this.calcPC(data[data.length-3]["total_tauxGlobal"],data[data.length-3]["totalN_tauxGlobal"])}</TableCell>
                          </>
                          )}
                        </TableRow>
                        <TableRow className={this.props.classes.tableTotal}>
                          <TableCell style={{borderRight:'1px solid silver'}}>
                            Total
                          </TableCell>
                          {data.length !== 0 && (
                          <>
                            {Object.keys(this.props.applicationStore.OccupationAgences).map((m,k) => {
                              return(
                                <>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-2][m+"_aLouer"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-2][m+"_occupProp"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-2][m+"_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-2][m+"_dispoLocat"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-2][m+"_occupLocat"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-2][m+"_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-2][m+"_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                  <TableCell className={this.props.classes.tableCurrent}>{data[data.length-2][m+"_aLouerRestant"].toLocaleString('fr')}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}  style={{borderRight:'1px solid silver'}}>{(data[data.length-2][m+"_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                </>
                              );
                            })}
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-2]["total_aLouer"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-2]["total_occupProp"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-2]["total_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-2]["total_dispoLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-2]["total_occupLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-2]["total_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableTotal}>{(data[data.length-2]["total_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableTotal}>{data[data.length-2]["total_aLouerRestant"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}  style={{borderRight:'1px solid silver'}}>{(data[data.length-2]["total_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-2]["totalN_aLouer"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-2]["totalN_occupProp"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-2]["totalN_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-2]["totalN_dispoLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-2]["totalN_occupLocat"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-2]["totalN_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableOld}>{(data[data.length-2]["totalN_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableOld}>{data[data.length-2]["totalN_aLouerRestant"].toLocaleString('fr')}</TableCell>
                            <TableCell className={this.props.classes.tableOld}  style={{borderRight:'1px solid silver'}}>{(data[data.length-2]["totalN_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{this.calcPC(data[data.length-2]["total_tauxGlobal"],data[data.length-2]["totalN_tauxGlobal"])}</TableCell>
                          </>
                          )}
                          </TableRow>
                        {/*<TableRow className={this.props.classes.tableTotal}>
                          <TableCell style={{borderRight:'1px solid silver'}}>
                            Moyenne départementale
                          </TableCell>
                          {data.length !== 0 && (
                          <>
                            {Object.keys(this.props.applicationStore.OccupationAgences).map((m,k) => {
                              return(
                                <>
                                  <TableCell className={this.props.classes.tableCurrent}></TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}></TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-1][m+"_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}></TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}></TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-1][m+"_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                  {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableCurrent}>{(data[data.length-1][m+"_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                                  <TableCell className={this.props.classes.tableCurrent}></TableCell>
                                  <TableCell className={this.props.classes.tableCurrent}  style={{borderRight:'1px solid silver'}}>{(data[data.length-1][m+"_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                                </>
                              );
                            })}
                            <TableCell className={this.props.classes.tableTotal}></TableCell>
                            <TableCell className={this.props.classes.tableTotal}></TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-1]["total_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}></TableCell>
                            <TableCell className={this.props.classes.tableTotal}></TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{(data[data.length-1]["total_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableTotal}>{(data[data.length-1]["total_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableTotal}></TableCell>
                            <TableCell className={this.props.classes.tableTotal}  style={{borderRight:'1px solid silver'}}>{(data[data.length-1]["total_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}></TableCell>
                            <TableCell className={this.props.classes.tableOld}></TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-1]["totalN_tauxProp"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableOld}></TableCell>
                            <TableCell className={this.props.classes.tableOld}></TableCell>
                            <TableCell className={this.props.classes.tableOld}>{(data[data.length-1]["totalN_tauxLocat"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            {this.props.applicationStore.TauxLocatGlobal && <TableCell className={this.props.classes.tableOld}>{(data[data.length-1]["totalN_tauxLocatGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>}
                            <TableCell className={this.props.classes.tableOld}></TableCell>
                            <TableCell className={this.props.classes.tableOld}  style={{borderRight:'1px solid silver'}}>{(data[data.length-1]["totalN_tauxGlobal"]*100).toLocaleString('fr',{maximumFractionDigits:2})+" %"}</TableCell>
                            <TableCell className={this.props.classes.tableTotal}>{this.calcPC(data[data.length-1]["total_tauxGlobal"],data[data.length-1]["totalN_tauxGlobal"])}</TableCell>
                          </>
                          )}
                        </TableRow>*/}
                      </TableFooter>
                    </Table>
                    <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                    <ExcelExport
                      data={this.dataToExcelData()}
                      fileName="Occupation.xlsx"
                      ref={(exporter) => { this._exporter = exporter; }}
                    >
                      <ExcelExportColumn field="date" title="Semaine du" cellOptions={{format:"dd-mm-yyyy"}}/>
                      {Object.entries(this.props.applicationStore.OccupationAgences).map(n => {
                            return (
                                <ExcelExportColumnGroup title={n[1]} headerCellOptions={{ textAlign: 'center' }}>
                                  <ExcelExportColumn field={n[0]+"_aLouer"} title="Parc actif"/>
                                  <ExcelExportColumn field={n[0]+"_occupProp"} title="Occupation Propriétaire"/>
                                  <ExcelExportColumn field={n[0]+"_tauxProp"} title="Taux d'occupation propriétaire" cellOptions={{format:"##0.0#%"}}/>
                                  <ExcelExportColumn field={n[0]+"_dispoLocat"} title="Disponible à la location"/>
                                  <ExcelExportColumn field={n[0]+"_occupLocat"} title="Loués"/>
                                  <ExcelExportColumn field={n[0]+"_tauxLocat"} title="Loués / Disponible à la location" cellOptions={{format:"##0.0#%"}}/>
                                  {this.props.applicationStore.TauxLocatGlobal && <ExcelExportColumn field={n[0]+"_tauxLocatGlobal"} title="Loués / Parc actif" cellOptions={{format:"##0.0#%"}}/>}
                                  <ExcelExportColumn field={n[0]+"_aLouerRestant"} title="Restant A Louer"/>
                                  <ExcelExportColumn field={n[0]+"_tauxGlobal"} title="Taux Global" cellOptions={{format:"##0.0#%"}}/>
                                </ExcelExportColumnGroup>
                            );
                          })}
                      <ExcelExportColumnGroup title="Total" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field={"total_aLouer"} title="Parc actif"/>
                        <ExcelExportColumn field={"total_occupProp"} title="Occupation Propriétaire"/>
                        <ExcelExportColumn field={"total_tauxProp"} title="Taux d'occupation propriétaire" cellOptions={{format:"##0.0#%"}}/>
                        <ExcelExportColumn field={"total_dispoLocat"} title="Disponible à la location"/>
                        <ExcelExportColumn field={"total_occupLocat"} title="Loués"/>
                        <ExcelExportColumn field={"total_tauxLocat"} title="Loués / Disponible à la location" cellOptions={{format:"##0.0#%"}}/>
                        {this.props.applicationStore.TauxLocatGlobal && <ExcelExportColumn field={"total_tauxLocatGlobal"} title="Loués / Parc actif" cellOptions={{format:"##0.0#%"}}/>}
                        <ExcelExportColumn field={"total_aLouerRestant"} title="Restant A Louer"/>
                        <ExcelExportColumn field={"total_tauxGlobal"} title="Taux Global" cellOptions={{format:"##0.0#%"}}/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Total N-1" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field={"totalN_aLouer"} title="Parc actif"/>
                        <ExcelExportColumn field={"totalN_occupProp"} title="Occupation Propriétaire"/>
                        <ExcelExportColumn field={"totalN_tauxProp"} title="Taux d'occupation propriétaire" cellOptions={{format:"##0.0#%"}}/>
                        <ExcelExportColumn field={"totalN_dispoLocat"} title="Disponible à la location"/>
                        <ExcelExportColumn field={"totalN_occupLocat"} title="Loués"/>
                        <ExcelExportColumn field={"totalN_tauxLocat"} title="Loués / Disponible à la location" cellOptions={{format:"##0.0#%"}}/>
                        {this.props.applicationStore.TauxLocatGlobal && <ExcelExportColumn field={"totalN_tauxLocatGlobal"} title="Loués / Parc actif" cellOptions={{format:"##0.0#%"}}/>}
                        <ExcelExportColumn field={"totalN_aLouerRestant"} title="Restant A Louer"/>
                        <ExcelExportColumn field={"totalN_tauxGlobal"} title="Taux Global" cellOptions={{format:"##0.0#%"}}/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Différence" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="diff" title=" " cellOptions={{format:"+##0.0#%;- ##0.0#%"}}/>
                      </ExcelExportColumnGroup>
                    </ExcelExport>
                  </Paper>
                </Grid>
              ):(
                <Grid style={{margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )) : (
                <Grid item style={{margin:"0 auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
            {this.state.loading <= 0 ? (this.state.data.length> 0 ? Object.keys(this.props.applicationStore.OccupationAgences).map((n,i) => {
              let dataAgence = this.state.data.filter(chunck => chunck.refAgence == n)
              return (
                <>
                  <p>Agence : {this.props.applicationStore.OccupationAgences[n]}</p>
                  <Grid container spacing={24}>
                    <Grid item xs={12} style={{height:'400px'}}>
                      <ResponsiveCalendar
                          data={dataAgence.map(n=>({
                            day:format(n.dateStat, 'YYYY-MM-DD'),
                            value:n.taux
                          }))}
                          from={format(selectedDate1, 'YYYY-MM-DD')}
                          to={format(selectedDate2, 'YYYY-MM-DD')}
                          emptyColor="#eeeeee"
                          colors={[
                              "#f47560",
                              "#f39872",
                              "#FFE0B2",
                              "#B4E6DD",
                              "#97e3d5",
                              "#61cdbb"
                            
                          ]}
                          margin={{
                              "top": 30,
                              "right": 30,
                              "bottom": 60,
                              "left": 30
                          }}
                          yearSpacing={40}
                          monthBorderWidth={5}
                          monthBorderColor="#ffffff"
                          monthLegend={(y, m) => format(new Date(y, m), "MMM", { locale: fr })}
                          monthLegendOffset={10}
                          dayBorderWidth={2}
                          dayBorderColor="#ffffff"   
                          domain={[0,100]} 
                          legends={[
                              {
                                  "anchor": "bottom-left",
                                  "direction": "row",
                                  "translateY": 36,
                                  "itemCount": 4,
                                  "itemWidth": 34,
                                  "itemHeight": 36,
                                  "itemDirection": "top-to-bottom"
                              }
                          ]}
                          tooltipFormat={value => Number(value).toLocaleString('fr',{maximumFractionDigits:2})+'%'}
                      />
                    </Grid>
                  </Grid>
                </>
              );
            }):(
              <Grid style={{height:'400px',textAlign:"center"}}>
                  <Warning color="error"/>
                  <Typography variant="overline" color="error">
                    Aucune donnée
                  </Typography>
              </Grid>
            )):(
              <Grid style={{height:'400px', textAlign:"center"}}>
                  <Sync className={this.props.classes.loader}/>
              </Grid>
            )}
        </>
      )
      }
    </div>
    );
  }
}