import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography
}  from '@material-ui/core';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Save, Sync, Warning } from '@material-ui/icons';

const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableOld:{
    color: 'gray', 
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentWarning:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'darkred',
    textShadow: '0 0 10px #F44336',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentHappy:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: 'darkgreen',
    textShadow: '0 0 10px #4CAF50',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});

@withStyles(styles)
@inject('applicationStore')

export default class Generale extends Component {
  displayName = Generale.name
  state = {
    data: [],
    ModalOpen:false,
    loading:true,
    selectedAgencies:Object.keys(this.props.applicationStore.GeneraleAgences),
    loadingAgencies:false,
    mountLoaded:false
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTitleGenerale(){
    let title = 'Statistiques générales de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.GeneraleAgences).length > 1)
    {
      title = 'Statistiques générales des agences ';
    }
    Object.keys(this.props.applicationStore.GeneraleAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.GeneraleAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.GeneraleAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.GeneraleAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.GeneraleAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.GeneraleAgences[element];
          }
        }
      }

    });
    return title + temp;
  } 
  getGenerale = () => {
    this.setState({
      loading:true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "Generale", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Generale/getStatGenerale', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.GeneraleDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.GeneraleDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.GeneraleDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.GeneraleDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(data => this.setState({data,loading:false}));
  }

  transferToBools = () => {   
    const response = fetch('api/MainData/updateBools', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Alouer: this.props.applicationStore.InAlouer, TauxLocatGlobal: this.props.applicationStore.TauxLocatGlobal,CaTTC: this.props.applicationStore.CaTTC, CaRevenus: this.props.applicationStore.CaRevenus})
    }).then(() => this.getGenerale());
  }

  transferToGeneraleAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    })
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "statgenerale"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getGenerale()
      this.updateAgencies(Agences)
    });
  }

  @action handleBoolChange = event => {
    if(event.target.name === 'InAlouer')
    {
      this.props.applicationStore.InAlouer = event.target.checked;
    }
    this.transferToBools();
  };

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToGeneraleAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.GeneraleAgences = Agences
  };
   
  @action handleDateChange = ranges => {
    this.props.applicationStore.GeneraleDate1 = ranges.selection.startDate;
    this.props.applicationStore.GeneraleDate2 = ranges.selection.endDate;
    this.props.applicationStore.GeneraleDate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.GeneraleDate4 = subYears(ranges.selection.endDate,1);
    this.getGenerale()
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.GeneraleDate3 = ranges.selection.startDate;
    this.props.applicationStore.GeneraleDate4 = ranges.selection.endDate;
    this.getGenerale()
  };
  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getGenerale();
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.GeneraleAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.GeneraleAgences)
          })
        }
      }
    });
  }
  tableChooseCurrent = (val, valN) => {
    let look = this.props.classes.tableCurrent;
    
      if(val > valN)
      {
        if((((val/valN)*100) -100) > this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.tableCurrentHappy;
        } 
      }
      if(valN > val)
      {
        if(((1-(val/valN))*100) > this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.tableCurrentWarning;
        }
      }
   
    return look;
  }

  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_Generale", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData = (d1,d2) => {
    
    let excelData = new Array(14);
    let rows=[/*"C.A."*/"Encaissements"+" TTC",/*"C.A."*/"Encaissements"+" TTC N-1",/*"Revenus"*/"C.A."+" HT",/*"Revenus"*/"C.A."+" HT N-1","Mandats actifs au "+format(d1, 'DD/MM/YYYY'), "Mandats actifs au "+format(d2, 'DD/MM/YYYY'), "Nouveaux mandats", "Nouveaux mandats N-1", "Mandats résiliés", "Mandats résiliés N-1", 
    "Contrats réalisés", "Contrats réalisés N-1", "Jours vendus", "Jours vendus N-1","Taux d'occupation", "Taux d'occupation N-1"];
    rows.push("Durée moyenne", "Durée moyenne N-1");
    rows.map((n,i) => {
      excelData[i]={};
      excelData[i]["row"] = n;
    });
    let rowsData=["ca","caN","revenus","revenusN","nbActifs","nbActifsN","nbOuvertures","nbOuverturesN","nbResiliations","nbResiliationsN","nbContrats","nbContratsN","nbJoursVendus","nbJoursVendusN","taux","tauxN"];
    
    let cumul = 0;
    for(let j=0;j<rowsData.length;j++){
      cumul=0;
      this.state.data.map((n,i) => {
        var value = 0;
        if(rowsData[j].includes("revenus")){
          value = Math.round(n[rowsData[j]]/1.2);
        }else{
          value = n[rowsData[j]];
        }
        excelData[j][n.refAgence]=value;
        cumul += value;
      });
      if(rowsData[j] == "taux"){
        let cumulLoue = 0;
        let cumulAlouer = 0;
        this.state.data.map((n,i) => {
          cumulAlouer += n.aLouer;
          cumulLoue += n.aLouer*n.taux;
        });
        excelData[j]["cumul"] = cumulLoue/cumulAlouer;
      }else if(rowsData[j] == "tauxN"){
        let cumulLoueN = 0;
        let cumulAlouerN = 0;
        this.state.data.map((n,i) => {
          cumulAlouerN += n.aLouerN;
          cumulLoueN += n.aLouerN*n.tauxN;
        });
        excelData[j]["cumul"] =cumulLoueN/cumulAlouerN;
      }else{
        excelData[j]["cumul"] = cumul;
      }
      if(isNaN(excelData[j]["cumul"])){
        excelData[j]["cumul"] = "NaN"
      }
    }
    //Durée moyennes
    cumul = 0;
    this.state.data.map((n,i) => {
      if(!isNaN(n.nbJoursVendusN/n.nbContrats)){
        cumul += (n.nbJoursVendus/n.nbContrats);
        excelData[rowsData.length][n.refAgence]=(n.nbJoursVendus/n.nbContrats);
      }else{
        excelData[rowsData.length][n.refAgence]="NaN"
      }
    });
    excelData[rowsData.length]["cumul"] = (cumul/this.state.data.length);
    if(isNaN(excelData[rowsData.length]["cumul"])){
      cumul = "NaN";
    }
    cumul = 0;
    this.state.data.map((n,i) => {
      if(!isNaN(n.nbJoursVendusN/n.nbContratsN)){
        cumul += (n.nbJoursVendusN/n.nbContratsN);
        excelData[rowsData.length+1][n.refAgence]=(n.nbJoursVendusN/n.nbContratsN);
      }else{
        excelData[rowsData.length+1][n.refAgence]="NaN"
      }
    });
    excelData[rowsData.length+1]["cumul"] = (cumul/this.state.data.length);
    if(isNaN(excelData[rowsData.length+1]["cumul"])){
      cumul = "NaN";
    }
    return excelData
  }

  render() {
    const selectedDate1 = this.props.applicationStore.GeneraleDate1;
    const selectedDate2 = this.props.applicationStore.GeneraleDate2;
    const selectedDate3 = this.props.applicationStore.GeneraleDate3;
    const selectedDate4 = this.props.applicationStore.GeneraleDate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }

    const calculCA = /*'CA'*/'Encaissements'+' = Net Locataire + Frais de dossier (F.D.D.) + Assurance + Menage + Prestations';
    const calculRevenus = /*'Revenus'*/'C.A.'+' = Frais de dossier (F.D.D.) + Assurance + Menage + Prestations + Honoraires + Autres + Remises';
    var calculTaux = "Taux d'occupation = Réservations locataires / Parc actif (Mandats actifs";
    calculTaux += this.props.applicationStore.InAlouer ? " + inactifs)" : ")";
    
    return (
      <div>
        {!this.props.applicationStore.Logged ? (
          <>
          <h1>Connexion requise</h1>
            <Logon/>           
          </>
        ):(
          <>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleGenerale()}</h1>
                <p>Statistiques générales pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"0 10px"}}>
                      <b>Taux d'occupation : </b>Mandats inactifs dans "Parc actif"
                      <Switch id="InAlouer" name="InAlouer" checked={this.props.applicationStore.InAlouer} onChange={this.handleBoolChange}/>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {this.state.loading <= 0 ? (this.state.data.length > 0 ? (
                <Grid item style={{maxWidth:'fit-content'}}>
                  <Paper style={{width:'fit-content'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={this.props.classes.tableTotal}><b>Contenu</b></TableCell>
                          <TableCell className={this.props.classes.tableTotal}><b>Total</b></TableCell>
                          {this.state.data.map((n,i) => {
                            return (
                                <TableCell className={this.props.classes.tableTotal}><b>{this.props.applicationStore.GeneraleAgences[n.refAgence]}</b></TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {this.dataToExcelData(selectedDate2,selectedDate4).map((n,i) => {
                              return (
                                <TableRow key={i}>
                                  {n.row.includes(/* "C.A." */"Encaissements") ? (
                                    <>
                                      <Tooltip title={calculCA} placement='right'>
                                        <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>{n.row}</TableCell>
                                      </Tooltip>
                                      <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>
                                        {n.cumul.toLocaleString('fr',{maximumFractionDigits:2})+' €'}
                                      </TableCell>
                                    </>
                                  ) : n.row.includes(/* "Revenus" */"C.A.") ? (
                                    <>
                                      <Tooltip title={calculRevenus} placement='right'>
                                        <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>{n.row}</TableCell>
                                      </Tooltip>
                                      <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>
                                        {n.cumul.toLocaleString('fr',{maximumFractionDigits:2})+' €'}
                                      </TableCell>
                                    </>
                                  ) : (n.row.includes("Taux d'occupation"))  ? (
                                    <>
                                      <Tooltip title={calculTaux} placement='right'>
                                        <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>{n.row}</TableCell>
                                      </Tooltip>
                                      <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>
                                        {(n.cumul*100).toLocaleString('fr',{maximumFractionDigits:2}) + ' %'}
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>{n.row}</TableCell>
                                      <TableCell className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>
                                        {n.cumul.toLocaleString('fr',{maximumFractionDigits:2}) + (n.row.includes("Durée moyenne") ? ' jours': '')}
                                      </TableCell>
                                    </>
                                  )}
                                  {this.state.data.map((a,k) => {
                                    return (
                                        <TableCell key={k} className={i%2 ==0 ? this.props.classes.tableCurrent : this.props.classes.tableOld}>
                                          {(n.row.includes("Taux d'occupation") ? n[a.refAgence]*100 : n[a.refAgence]).toLocaleString('fr',{maximumFractionDigits:2}) + ((n.row.includes(/*"C.A."*/"Encaissements") || n.row.includes(/*"Revenus"*/"C.A.")) ? ' €' : n.row.includes("Taux d'occupation") ? ' %' : n.row.includes("Durée moyenne") ? ' jours': '')}
                                        </TableCell>
                                    );
                                  })}
                                </TableRow> 
                              );
                          })}
                      </TableBody>
                    </Table>
                    <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                    <ExcelExport
                      data={this.dataToExcelData(selectedDate2,selectedDate4)}
                      fileName="Statistiques générales.xlsx"
                      ref={(exporter) => { this._exporter = exporter; }}
                    >
                      <ExcelExportColumn field="row" title="Contenu"/>
                      <ExcelExportColumn field="cumul" title="Total" cellOptions={{format:/*"[>1]###,##0;[<=0]##0;##0.0#%"*/"##,##0.0#"}}/>
                      {this.state.data.map((n,i) => {
                        return (
                        <ExcelExportColumn field={n.refAgence} title={this.props.applicationStore.GeneraleAgences[n.refAgence]} cellOptions={{format:/*"[>1]###,##0;[<=0]##0;##0.0#%"*/"##,##0.0#"}}/>
                        )
                      })}
                    </ExcelExport>
                  </Paper>
                </Grid>
              ):(
                <Grid style={{margin:"25% auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )) : (
                <Grid item style={{margin:"25% auto"}}>
                    <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </div>
    );
  }
}