import {action, configure, flow, observable} from "mobx";
import {endOfMonth, differenceInDays, startOfMonth, subDays, subMonths, subYears} from "date-fns";

export class Store {
    @observable RefAgence = '';
    @observable Color = '';
    @observable Accent = '';
    @observable ListeAgences = {};
    @observable MandatAgences = {};
    @observable VolumeAgences = {};
    @observable CAAgences = {};
    @observable CAOrigineAgences = {};
    @observable GeneraleAgences = {};
    @observable OccupationAgences = {};
    @observable IDCompte = 0;
    @observable NomUtilisateur = ''; 
    @observable PrenomUtilisateur = '';  
    @observable Logged = false;
    @observable LoadingAg = false;
    @observable seuilAugment = 5;
    @observable seuilReduce = 5;
    @observable InAlouer = false;
    @observable TauxLocatGlobal = false;
    @observable CaTTC = false;
    @observable CaRevenus = false;
    @observable prestationBlacklist = [];
    @observable ListePrestations = [];

    @observable VolumeDate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable VolumeDate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.VolumeDate1),1) : endOfMonth(this.VolumeDate1);
    @observable VolumeDate3 = subYears(this.VolumeDate1, 1);
    @observable VolumeDate4 = subYears(this.VolumeDate2, 1);
    @observable VolumeCP = '';

    @observable MandatDate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable MandatDate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.MandatDate1),1) : endOfMonth(this.MandatDate1);
    @observable MandatDate3 = subYears(this.MandatDate1, 1);
    @observable MandatDate4 = subYears(this.MandatDate2, 1);
    @observable MandatCP = '';

    @observable GeneraleDate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable GeneraleDate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.GeneraleDate1),1) : endOfMonth(this.GeneraleDate1);
    @observable GeneraleDate3 = subYears(this.GeneraleDate1, 1);
    @observable GeneraleDate4 = subYears(this.GeneraleDate2, 1);

    @observable OccupationDate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable OccupationDate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.OccupationDate1),1) : endOfMonth(this.OccupationDate1);
    @observable OccupationDate3 = subYears(this.OccupationDate1, 1);
    @observable OccupationDate4 = subYears(this.OccupationDate2, 1);
    @observable OccupationCP = '';

    @observable CADate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable CADate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.CADate1),1) : endOfMonth(this.VolumeDate1);
    @observable CADate3 = subYears(this.CADate1, 1);
    @observable CADate4 = subYears(this.CADate2, 1);

    @observable CAPODate1 = startOfMonth(subMonths(Date.now(), 1));
    @observable CAPODate2 = (differenceInDays(Date.now(),startOfMonth(Date.now())) <= 0) ? subDays(endOfMonth(this.CAPODate1),1) : endOfMonth(this.CAPODate1);
    @observable CAPODate3 = subYears(this.CAPODate1, 1);
    @observable CAPODate4 = subYears(this.CAPODate2, 1);

    @observable TraceDate1 = subMonths(Date.now(),1);
    @observable TraceDate2 = subDays(Date.now(),0);

    @action chargeMandatAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getMandatAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.MandatAgences = yield responseM.json();
    });

    @action chargeVolumeAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getVolumeAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.VolumeAgences = yield responseM.json();
    });

    @action chargeCAAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getCAAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.CAAgences = yield responseM.json();
    });

    @action chargeCAOrigineAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getCAOrigineAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.CAOrigineAgences = yield responseM.json();
    });

    @action chargeGeneraleAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getGeneraleAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.GeneraleAgences = yield responseM.json();
    });

    @action chargeOccupationAgence = flow(function*(){
      const responseM = yield fetch('api/MainData/getOccupationAgences', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte})
      });
      this.OccupationAgences = yield responseM.json();
    });

    @action chargeSeuils = flow(function*(){
      const responseM = yield fetch('api/MainData/getSeuils/'+this.IDCompte, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      let resultat = yield responseM.json();
      this.seuilAugment = resultat.seuilAugment;
      this.seuilReduce = resultat.seuilReduce;
    });

    @action chargeBools = flow(function*(){
      const responseM = yield fetch('api/MainData/getBools/'+this.IDCompte, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      let resultat = yield responseM.json();
      this.InAlouer = resultat.inAlouer;
      this.TauxLocatGlobal = resultat.tauxLocatGlobal;
      this.CaTTC = resultat.caTTC;
      this.CaRevenus = resultat.caRevenus;
    });

    @action chargePrestationBlacklist = flow(function*(){
      const responseM = yield fetch('api/MainData/getPrestationBlacklist/'+this.IDCompte, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      this.prestationBlacklist = yield responseM.json();
    });

    @action chargePrestations = flow(function*(){
      const responseM = yield fetch('api/MainData/getPrestations', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({IDCompte: this.IDCompte, Agences: Object.keys(this.ListeAgences)})
      });
      this.ListePrestations = yield responseM.json();
    })

    @action chargeCPs = flow(function*(){
      const responseM = yield fetch('api/MainData/getCPs/'+this.IDCompte, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      let resultat = yield responseM.json();
      this.MandatCP = resultat.mandats;
      this.OccupationCP = resultat.occup;
      this.VolumeCP = resultat.volumes;
    })

    @action changeagence=flow(function*(NewRefAgence,Code){
      this.LoadingAg = true;
      this.RefAgence = NewRefAgence;
      const response = yield fetch('api/MainData/getSelectorAgences', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({RefAgence: NewRefAgence, Code})
        });
      if(response.status===401)
      {
        alert('Agence ou code incorrect.');
        return;
      }
      const userTheme = yield fetch('api/MainData/getUserTheme', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(NewRefAgence)
      });

      let userColors = yield userTheme.json();
      this.Color = userColors.color;
      this.Accent = userColors.accent;
      
      let data = yield response.json();
      
      this.ListeAgences = data.listeAgences;
      this.IDCompte = data.idCompte;
      this.NomUtilisateur = data.nom;
      this.PrenomUtilisateur = data.prenom;

      this.LoadingAg = false;
      this.Logged = true;
      
      this.chargeSeuils();  
      this.chargeBools();
      this.chargePrestationBlacklist();
      this.chargeCPs();
      this.chargeMandatAgence();
      this.chargeVolumeAgence();
      this.chargeCAAgence(); 
      this.chargeCAOrigineAgence(); 
      this.chargeGeneraleAgence(); 
      this.chargeOccupationAgence(); 
      this.chargePrestations();
    });

    constructor () {
        configure({ enforceActions: 'observed' })
    }
    
}
