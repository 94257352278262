import React, { Component } from 'react';
import Logon from './Logon';
import {observer, inject} from "mobx-react";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import {action, autorun} from 'mobx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {fr} from 'date-fns/esm/locale';
import {defaultStaticRanges, defaultInputRanges} from './dateRanges';
import {subYears, format} from 'date-fns';
import { ResponsiveLine } from '@nivo/line';
import { Save, Sync, Warning } from '@material-ui/icons';
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export'

const styles = theme => ({
  dateModal: {
    margin : '10% auto',
    width: 'fit-content',
    '&>div':{
      padding: '1%'
    }
  },
  excelButton:{
    margin:'10px',
    backgroundColor:'#00a500',
    color:'#ffffff'
  },
  loader:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "120px",
    height: "120px",
    animation: "spin 2s linear infinite"
  },
  loaderMini:{
    /*border: "16px solid #f3f3f3", 
    borderTop: "16px solid #3498db", 
    borderRadius: "50%",*/
    width: "30px",
    height: "30px",
    animation: "spin 2s linear infinite"
  },
  '@keyframes spin': {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(-360deg)" }
  },
  tableTotal:{
    fontWeight: 'bold',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrent:{
    color: 'darkblue',
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableOld:{
    color: 'gray', 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentWarning:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkred',
    textShadow: '0 0 10px #F44336',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  tableCurrentHappy:{
    backgroundColor: 'rgb(239, 242, 247)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
    color: 'darkgreen',
    textShadow: '0 0 10px #4CAF50',
    paddingRight:'10px',
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});


@withStyles(styles)
@inject('applicationStore')
export default class Mandats extends Component {
  displayName = Mandats.name
  state = {
    data: [],
    graphData: [],
    ModalOpen:false,
    loading:true,
    graphLoading:true,
    selectedAgencies:Object.keys(this.props.applicationStore.MandatAgences),
    loadingAgencies:false,
    mountLoaded:false,
    dataGlobal: {},
    cp: this.props.applicationStore.MandatCP,
    loadingGlobal: false
  };

  handleModalOpen = () => {
    this.setState({
      ModalOpen: true
    })
  }

  handleModalClose = () => {
    this.setState({
      ModalOpen: false
    })
  }

  getTitleMandats(){
    let title = 'Mandats de l\'agence ';
    let temp = '';
    let i = 0;
    if(Object.keys(this.props.applicationStore.MandatAgences).length > 1)
    {
      title = 'Mandats des agences ';
    }
    Object.keys(this.props.applicationStore.MandatAgences).forEach(element => {
      i++;
      if(temp == '')
      {
        temp = this.props.applicationStore.MandatAgences[element];
      } else
      {
        if(i==4){
          temp = temp + ' et '+ (Object.keys(this.props.applicationStore.MandatAgences).length-3) +' autres';
        }else if(i<4){
          if(Object.keys(this.props.applicationStore.MandatAgences).length == i)
          {
            temp = temp + ' et ' + this.props.applicationStore.MandatAgences[element];
          } else 
          {
            temp = temp + ', ' + this.props.applicationStore.MandatAgences[element];
          }
        }
      }

    });
    return title + temp;
  }
  getMandats = () => {
    this.setState({
      loading: true,
      graphLoading: true
    })
    fetch('api/Trace/updateTrace', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({Cible: "Mandats", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
    })
    fetch('api/Mandats/getStatMandats', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.MandatDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.MandatDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.MandatDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.MandatDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(data => this.setState({data,loading:false}));
    fetch('api/Mandats/getStatMandatsGraph', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Date1: format(this.props.applicationStore.MandatDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.MandatDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.MandatDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.MandatDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(graphData => this.setState({graphData,graphLoading:false}));
  }

  getMandatsGlobal = () => {
    this.setState({
      loadingGlobal: true
    })
    fetch('api/Mandats/getStatMandatsGlobal', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({CP: this.props.applicationStore.MandatCP, Date1: format(this.props.applicationStore.MandatDate1, 'YYYY-MM-DD'), Date2: format(this.props.applicationStore.MandatDate2, 'YYYY-MM-DD'), Date3: format(this.props.applicationStore.MandatDate3, 'YYYY-MM-DD'), Date4: format(this.props.applicationStore.MandatDate4, 'YYYY-MM-DD')})
    }).then(data => data.json()).then(dataGlobal => this.setState({dataGlobal,loadingGlobal: false}));
    
  }
  
  transferToMandatAgences = (Agences) => {
    this.setState({
      loadingAgencies: true
    }) 
    const response = fetch('api/MainData/updateAgences', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, Selected: Object.keys(Agences), Agences: Object.keys(this.props.applicationStore.ListeAgences),Table: "mandats"})
    }).then(() => {
      this.setState({
        loadingAgencies: false
      })
      this.getMandats()
      this.updateAgencies(Agences)
    });
  }

  transferCP = () => {
    const response = fetch('api/MainData/updateCP', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({IDCompte: this.props.applicationStore.IDCompte, CP: this.props.applicationStore.MandatCP, Table: "mandats"})
    }).then(() => {
      this.getMandatsGlobal()
    });
  }

  @action handleAgencyChange = event => {
    this.setState({
      selectedAgencies: event.target.value
    })
  };

  @action handleAllAgencies = () => {
    this.setState({
      selectedAgencies: Object.keys(this.props.applicationStore.ListeAgences)
    })
  };

  @action handleNoAgencies = () => {
    this.setState({
      selectedAgencies:[]
    })
  };

  @action handleRefresh = () => {
    let Agences = {};
    this.state.selectedAgencies.forEach(ag =>{
      Agences[ag] = this.props.applicationStore.ListeAgences[ag]
    })
    this.transferToMandatAgences(Agences);
  };

  @action updateAgencies = (Agences) => {
    this.props.applicationStore.MandatAgences = Agences
  };
  
  @action handleDateChange = ranges => {
    this.props.applicationStore.MandatDate1 = ranges.selection.startDate;
    this.props.applicationStore.MandatDate2 = ranges.selection.endDate;
    this.props.applicationStore.MandatDate3 = subYears(ranges.selection.startDate,1);
    this.props.applicationStore.MandatDate4 = subYears(ranges.selection.endDate,1);
    this.getMandats()
    this.getMandatsGlobal()
  };
  @action handleDateChangeN = ranges => {
    this.props.applicationStore.MandatDate3 = ranges.selection.startDate;
    this.props.applicationStore.MandatDate4 = ranges.selection.endDate;
    this.getMandats()
    this.getMandatsGlobal()
  };
  /*@action handleCPChange = () => {
    this.props.applicationStore.MandatCP = this.state.cp
    this.transferCP()
    this.getMandatsGlobal()
  }
  @action CPCheck = event => {
    var vals = event.target.value.split(/[\s,;/.]/)
    var cp = vals.map(val => val.substr(0,2)).join(',')
    this.setState({cp})
  }*/
  componentDidMount = () => {
    autorun(() => {
      if(this.props.applicationStore.Logged){
        if(!this.state.mountLoaded){
          this.getMandats()
          this.setState({
            mountLoaded: true
          })
        }
        if(Object.keys(this.props.applicationStore.MandatAgences).length > 0){
          this.setState({
            selectedAgencies:Object.keys(this.props.applicationStore.MandatAgences)
          })
        }
        if(this.props.applicationStore.MandatCP != ""){
          this.setState({
            cp:this.props.applicationStore.MandatCP
          })
          this.getMandatsGlobal()
        }
      }
    });
  }
  calcPC = (val, valN) => {
    let pourcent = '';
    if(valN != 0)
    {
      if(val >= valN)
      {
        pourcent = "+"+(((val/valN)*100) -100).toLocaleString('fr',{maximumFractionDigits:1}) +'%';
      }
      if(valN > val)
      {
        pourcent = "-"+((1-(val/valN))*100).toLocaleString('fr',{maximumFractionDigits:1}) +'%';
      }
    }else{
      pourcent = "NaN"
    }
    return pourcent;
  }
  tableChooseCurrent = (val, valN) => {
    let look = this.props.classes.tableCurrent;
    
      if(val > valN)
      {
        if((((val/valN)*100) -100) > this.props.applicationStore.seuilAugment)
        {
            look = this.props.classes.tableCurrentHappy;
        } 
      }
      if(valN > val)
      {
        if(((1-(val/valN))*100) > this.props.applicationStore.seuilReduce)
        {
            look = this.props.classes.tableCurrentWarning;
        }
      }
   
    return look;
  }

  _exporter;
  export = () => {
      fetch('api/Trace/updateTrace', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({Cible: "export_Mandats", Agences: this.state.selectedAgencies.length, listeAgences: Object.keys(this.props.applicationStore.ListeAgences).length})
      })
      this._exporter.save();
  }

  dataToExcelData = () => {
    const Data = this.state.data.map(n => ({
      refAgence: n.refAgence,
      Agence: this.props.applicationStore.MandatAgences[n.refAgence],
      nbMandatsDebut: n.nbMandatsDebut,
      nbMandatsDebutN: n.nbMandatsDebutN,
      nbMandatsFin: n.nbMandatsFin,
      nbMandatsFinN: n.nbMandatsFinN,
      nbActifs: n.nbActifs,
      nbActifsN: n.nbActifsN,
      nbInactifs: n.nbInactifs,
      nbInactifsN: n.nbInactifsN,
      nbResiliations: n.nbResiliations,
      nbResiliationsN: n.nbResiliationsN,
      nbOuvertures: n.nbOuvertures,
      nbOuverturesN: n.nbOuverturesN,
    }));
    const excelData = [...Data];
    const l = Data.length;
    excelData.map(e => {
      e.ecartMandatsDebut = this.calcPC(e.nbMandatsDebut, e.nbMandatsDebutN);
      e.ecartMandatsFin = this.calcPC(e.nbMandatsFin, e.nbMandatsFinN);
      e.ecartActifs = this.calcPC(e.nbActifs, e.nbActifsN);
      e.ecartInactifs = this.calcPC(e.nbInactifs, e.nbInactifsN);
      e.ecartResiliations = this.calcPC(e.nbResiliations, e.nbResiliationsN);
      e.ecartOuvertures = this.calcPC(e.nbOuvertures, e.nbOuverturesN);
    });
    excelData[l] = {
      Agence:'Moyennes',
      nbMandatsDebut:Math.round((Data.reduce((t,n) => t+n.nbMandatsDebut,0))/l),
      nbMandatsDebutN:Math.round((Data.reduce((t,n) => t+n.nbMandatsDebutN,0))/l),
      ecartMandatsDebut:this.calcPC(((Data.reduce((t,n) => t+n.nbMandatsDebut,0))/l), ((Data.reduce((t,n) => t+n.nbMandatsDebutN,0))/l)),
      nbMandatsFin:Math.round((Data.reduce((t,n) => t+n.nbMandatsFin,0))/l),
      nbMandatsFinN:Math.round((Data.reduce((t,n) => t+n.nbMandatsFinN,0))/l),
      ecartMandatsFin:this.calcPC(((Data.reduce((t,n) => t+n.nbMandatsFin,0))/l), ((Data.reduce((t,n) => t+n.nbMandatsFinN,0))/l)),
      nbActifs:Math.round((Data.reduce((t,n) => t+n.nbActifs,0))/l),
      nbActifsN:Math.round((Data.reduce((t,n) => t+n.nbActifsN,0))/l),
      ecartActifs:this.calcPC(((Data.reduce((t,n) => t+n.nbActifs,0))/l), ((Data.reduce((t,n) => t+n.nbActifsN,0))/l)),
      nbInactifs:Math.round((Data.reduce((t,n) => t+n.nbInactifs,0))/l),
      nbInactifsN:Math.round((Data.reduce((t,n) => t+n.nbInactifsN,0))/l),
      ecartInactifs:this.calcPC(((Data.reduce((t,n) => t+n.nbInactifs,0))/l), ((Data.reduce((t,n) => t+n.nbInactifsN,0))/l)),
      nbResiliations:Math.round((Data.reduce((t,n) => t+n.nbResiliations,0))/l),
      nbResiliationsN:Math.round((Data.reduce((t,n) => t+n.nbResiliationsN,0))/l),
      ecartResiliations:this.calcPC(((Data.reduce((t,n) => t+n.nbResiliations,0))/l), ((Data.reduce((t,n) => t+n.nbResiliationsN,0))/l)),
      nbOuvertures:Math.round((Data.reduce((t,n) => t+n.nbOuvertures,0))/l),
      nbOuverturesN:Math.round((Data.reduce((t,n) => t+n.nbOuverturesN,0))/l),
      ecartOuvertures:this.calcPC(((Data.reduce((t,n) => t+n.nbOuvertures,0))/l), ((Data.reduce((t,n) => t+n.nbOuverturesN,0))/l))
      
    }
    excelData[(l+1)] = {
      Agence:'Total',
      nbMandatsDebut:((Data.reduce((t,n) => t+n.nbMandatsDebut,0))),
      nbMandatsDebutN:((Data.reduce((t,n) => t+n.nbMandatsDebutN,0))),
      ecartMandatsDebut:this.calcPC(((Data.reduce((t,n) => t+n.nbMandatsDebut,0))), ((Data.reduce((t,n) => t+n.nbMandatsDebutN,0)))),
      nbMandatsFin:((Data.reduce((t,n) => t+n.nbMandatsFin,0))),
      nbMandatsFinN:((Data.reduce((t,n) => t+n.nbMandatsFinN,0))),
      ecartMandatsFin:this.calcPC(((Data.reduce((t,n) => t+n.nbMandatsFin,0))), ((Data.reduce((t,n) => t+n.nbMandatsFinN,0)))),
      nbActifs:((Data.reduce((t,n) => t+n.nbActifs,0))),
      nbActifsN:((Data.reduce((t,n) => t+n.nbActifsN,0))),
      ecartActifs:this.calcPC(((Data.reduce((t,n) => t+n.nbActifs,0))), ((Data.reduce((t,n) => t+n.nbActifsN,0)))),
      nbInactifs:((Data.reduce((t,n) => t+n.nbInactifs,0))),
      nbInactifsN:((Data.reduce((t,n) => t+n.nbInactifsN,0))),
      ecartInactifs:this.calcPC(((Data.reduce((t,n) => t+n.nbInactifs,0))), ((Data.reduce((t,n) => t+n.nbInactifsN,0)))),
      nbResiliations:((Data.reduce((t,n) => t+n.nbResiliations,0))),
      nbResiliationsN:((Data.reduce((t,n) => t+n.nbResiliationsN,0))),
      ecartResiliations:this.calcPC(((Data.reduce((t,n) => t+n.nbResiliations,0))), ((Data.reduce((t,n) => t+n.nbResiliationsN,0)))),
      nbOuvertures:((Data.reduce((t,n) => t+n.nbOuvertures,0))),
      nbOuverturesN:((Data.reduce((t,n) => t+n.nbOuverturesN,0))),
      ecartOuvertures:this.calcPC(((Data.reduce((t,n) => t+n.nbOuvertures,0))), ((Data.reduce((t,n) => t+n.nbOuverturesN,0))))
    }
    /*excelData[l+2] = Object.keys(this.state.dataGlobal).length>0 ? this.state.dataGlobal : excelData[l]
    excelData[l+2]['Agence'] = 'Moyenne locale'
    excelData[l+2]['ecartMandatsDebut'] = this.calcPC(excelData[l+2]['nbMandatsDebut'],excelData[l+2]['nbMandatsDebutN'])
    excelData[l+2]['ecartMandatsFin'] = this.calcPC(excelData[l+2]['nbMandatsFin'],excelData[l+2]['nbMandatsFinN'])
    excelData[l+2]['ecartActifs'] = this.calcPC(excelData[l+2]['nbActifs'],excelData[l+2]['nbActifsN'])
    excelData[l+2]['ecartInactifs'] = this.calcPC(excelData[l+2]['nbInactifs'],excelData[l+2]['nbInactifsN'])
    excelData[l+2]['ecartResiliations'] = this.calcPC(excelData[l+2]['nbResiliations'],excelData[l+2]['nbResiliationsN'])
    excelData[l+2]['ecartOuvertures'] = this.calcPC(excelData[l+2]['nbOuvertures'],excelData[l+2]['nbOuverturesN'])
    */
    return excelData
  }

  render() {
    const selectedDate1 = this.props.applicationStore.MandatDate1;
    const selectedDate2 = this.props.applicationStore.MandatDate2;
    const selectedDate3 = this.props.applicationStore.MandatDate3;
    const selectedDate4 = this.props.applicationStore.MandatDate4;
    const selectionRange = {
			startDate: selectedDate1,
			endDate: selectedDate2,
			key: 'selection',
    }
    const selectionRangeN = {
			startDate: selectedDate3,
			endDate: selectedDate4,
      key: 'selection',
      color: 'gray',
    }
    return (
      <div>
        {!this.props.applicationStore.Logged ? (
          <>
          <h1>Connexion requise</h1>
            <Logon/>           
          </>
        ):(
          <>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <h1>{this.getTitleMandats()}</h1>
                <p>Mandats fin de saison - ecart Mandats Fin de saison, Mandats résiliés pour la période du <b>{selectedDate1.toLocaleDateString()}</b> au <b>{selectedDate2.toLocaleDateString()}</b> avec comparaison n-1 du <b>{selectedDate3.toLocaleDateString()}</b> au <b>{selectedDate4.toLocaleDateString()}</b>.</p>
                <Modal disableRestoreFocus open={this.state.ModalOpen} onBackdropClick={this.props.backdropClosesModal} onClose={this.handleModalClose} className={this.props.classes.dateModal}>
                  <Paper>
                    <Grid container spacing={24}>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={this.handleDateChange}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item width={400}>
                        <DateRangePicker
                        ranges={[selectionRangeN]}
                        onChange={this.handleDateChangeN}
                        locale={fr}
                        staticRanges={defaultStaticRanges}
                        inputRanges={defaultInputRanges}
                        />
                      </Grid>
                      <Grid item>
                        <Button variant='contained' color='secondary' onClick={this.handleModalClose}>Fermer</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Modal>
                <Grid container spacing={24}>
                  <Grid item>
                    <Button variant='contained' color='primary' onClick={this.handleModalOpen}>Modifier les dates</Button>
                  </Grid>
                  <Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        {Object.entries(this.props.applicationStore.ListeAgences).length > 0 ? (
                          <>
                            <Grid item style={{padding:"8px 4px"}}>
                              Agences : &nbsp;
                            </Grid>
                            <Grid item>
                              <Select multiple value={this.state.selectedAgencies} onChange={this.handleAgencyChange} renderValue={selected => selected.map(s => this.props.applicationStore.ListeAgences[s]).join(', ')} style={{width:"300px"}}>
                              {Object.entries(this.props.applicationStore.ListeAgences).map(agence => {
                                return(
                                  <MenuItem key={agence[0]} value={agence[0]}>
                                    <Checkbox checked={this.state.selectedAgencies.indexOf(agence[0]) > -1} />
                                    <ListItemText primary={agence[1]} />
                                  </MenuItem>
                                )
                                })}
                              </Select>
                            </Grid>
                            {Object.keys(this.props.applicationStore.ListeAgences).length > 10 ? (
                              <>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleAllAgencies}>Toutes</Button>
                                </Grid>
                                <Grid item>
                                  <Button variant='contained' color='primary' onClick={this.handleNoAgencies}>Aucune</Button>
                                </Grid>
                              </>
                            ):""}
                            <Grid item>
                              <Button variant='contained' color='secondary' onClick={this.handleRefresh}>Actualiser</Button>
                            </Grid>
                            <Grid item style={{textAlign:"center",visibility:this.state.loadingAgencies ? "visible" : "hidden"}}>
                              <Sync className={this.props.classes.loaderMini}/>
                            </Grid>
                          </>
                        ):(
                          <Grid item style={{textAlign:"center"}}>
                            <Sync className={this.props.classes.loader}/>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  {/*<Grid item>
                    <Paper style={{padding:"2px 10px"}}>
                      <Grid container spacing={8} style={{margin:"0"}}>
                        <Grid item style={{padding:"8px 4px"}}>
                          Code Département (Moyenne locale) : &nbsp;
                        </Grid>
                        <Grid item>
                          <TextField id="CP" name="CP" type='text' onChange={this.CPCheck} value={this.state.cp} style={{ marginRight: '5px' }}/>
                          <Button variant='contained' color='secondary' onClick={this.handleCPChange}>Actualiser</Button>
                        </Grid>
                        <Grid item style={{textAlign:"center",visibility:this.state.loadingGlobal ? "visible" : "hidden"}}>
                          <Sync className={this.props.classes.loaderMini}/>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {this.state.loading <= 0 ? (this.state.data.length > 0 ? (
                <Grid item xs={12} style={{maxWidth:'fit-content'}}>
                  <Paper style={{width:'fit-content'}}>
                    <Table padding='dense'>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{borderBottom:'none'}}></TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none', borderRight: '1px solid silver', borderLeft: '1px solid silver'}}>Total Mandats</TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none', borderRight: '1px solid silver', borderLeft: '1px solid silver'}}>Resiliations</TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none', borderRight: '1px solid silver', borderLeft: '1px solid silver'}}>Nouveaux Mandats</TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none', borderRight: '1px solid silver', borderLeft: '1px solid silver'}}>Potentiel Mandats</TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none', borderRight: '1px solid silver'}}>Mandats Actifs</TableCell>
                          <TableCell colSpan={3} align='center' style={{borderBottom:'none'}}>Mandats Inactifs</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{borderRight: '1px solid silver'}}>Agence</TableCell>
                          <TableCell align='right'>{format(selectedDate1, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right'>{format(selectedDate3, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right' style={{borderRight: '1px solid silver'}}>Ecart</TableCell>
                          <TableCell align='right'>N</TableCell>
                          <TableCell align='right'>N-1</TableCell>
                          <TableCell align='right' style={{borderRight: '1px solid silver'}}>Ecart</TableCell>
                          <TableCell align='right'>N</TableCell>
                          <TableCell align='right'>N-1</TableCell>
                          <TableCell align='right' style={{borderRight: '1px solid silver'}}>Ecart</TableCell>                        
                          <TableCell align='right'>{format(selectedDate2, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right'>{format(selectedDate4, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right' style={{borderRight: '1px solid silver'}}>Ecart</TableCell>
                          <TableCell align='right'>{format(selectedDate2, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right'>{format(selectedDate4, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right' style={{borderRight: '1px solid silver'}}>Ecart</TableCell>
                          <TableCell align='right'>{format(selectedDate2, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right'>{format(selectedDate4, 'DD/MM/YYYY')}</TableCell>
                          <TableCell align='right'>Ecart</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.dataToExcelData().map((n,i) => {
                          if(i<this.state.data.length){
                            return (
                              <TableRow key={i}>
                                <TableCell style={{borderRight: '1px solid silver'}}>
                                  {n.Agence}
                                </TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbMandatsDebut, n.nbMandatsDebutN)} align='right'>{n.nbMandatsDebut.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbMandatsDebutN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartMandatsDebut.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableCurrent} align='right'>{n.nbResiliations.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbResiliationsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartResiliations.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableCurrent} align='right'>{n.nbOuvertures.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbOuverturesN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartOuvertures.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbMandatsFin, n.nbMandatsFinN)} align='right'>{n.nbMandatsFin.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbMandatsFinN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartMandatsFin.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbActifs, n.nbActifsN)} align='right'>{n.nbActifs.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbActifsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartActifs.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbInactifs, n.nbInactifsN)} align='right'>{n.nbInactifs.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbInactifsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.ecartInactifs.toLocaleString('fr')}</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                      <TableFooter>
                      {this.dataToExcelData().map((n,i) => {
                          if(i>=this.state.data.length){
                            return (
                              <TableRow key={i} className={i>this.state.data.length ? this.props.classes.tableTotal:''}>
                                <TableCell style={{borderRight: '1px solid silver'}}>
                                  {n.Agence}
                                </TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbMandatsDebut, n.nbMandatsDebutN)} align='right'>{n.nbMandatsDebut.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbMandatsDebutN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartMandatsDebut.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableCurrent} align='right'>{n.nbResiliations.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbResiliationsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartResiliations.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableCurrent} align='right'>{n.nbOuvertures.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbOuverturesN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartOuvertures.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbMandatsFin, n.nbMandatsFinN)} align='right'>{n.nbMandatsFin.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbMandatsFinN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartMandatsFin.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbActifs, n.nbActifsN)} align='right'>{n.nbActifs.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbActifsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right' style={{borderRight: '1px solid silver'}}>{n.ecartActifs.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.tableChooseCurrent(n.nbInactifs, n.nbInactifsN)} align='right'>{n.nbInactifs}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.nbInactifsN.toLocaleString('fr')}</TableCell>
                                <TableCell className={this.props.classes.tableOld} align='right'>{n.ecartInactifs.toLocaleString('fr')}</TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableFooter>
                    </Table>
                    <Button onClick={this.export} className={this.props.classes.excelButton}><Save/>&nbsp;Excel</Button>
                    <ExcelExport
                      data={this.dataToExcelData()}
                      fileName="Mandats.xlsx"
                      ref={(exporter) => { this._exporter = exporter; }}
                    >
                      <ExcelExportColumn field="Agence" title="Agence"/>
                      <ExcelExportColumnGroup title="Total Mandats" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbMandatsDebut" title={format(selectedDate1, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="nbMandatsDebutN" title={format(selectedDate3, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="ecartMandatsDebut" title="Ecart"/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Resiliations" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbResiliations" title='N'/>
                        <ExcelExportColumn field="nbResiliationsN" title='N-1'/>
                        <ExcelExportColumn field="ecartResiliations" title="Ecart"/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Nouveaux Mandats" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbOuvertures" title='N'/>
                        <ExcelExportColumn field="nbOuverturesN" title='N-1'/>
                        <ExcelExportColumn field="ecartOuvertures" title="Ecart"/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Potentiel Mandats" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbMandatsFin" title={format(selectedDate2, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="nbMandatsFinN" title={format(selectedDate4, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="ecartMandatsFin" title="Ecart"/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Mandats Actifs" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbActifs" title={format(selectedDate2, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="nbActifsN" title={format(selectedDate4, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="ecartActifs" title="Ecart"/>
                      </ExcelExportColumnGroup>
                      <ExcelExportColumnGroup title="Mandats Inactifs" headerCellOptions={{ textAlign: 'center' }}>
                        <ExcelExportColumn field="nbInactifs" title={format(selectedDate2, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="nbInactifsN" title={format(selectedDate4, 'DD/MM/YYYY')}/>
                        <ExcelExportColumn field="ecartInactifs" title="Ecart"/>
                      </ExcelExportColumnGroup>
                    </ExcelExport>
                  </Paper>
                </Grid>
              ):(
                <Grid style={{margin:"0 auto",textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{margin:'0 auto'}}>
                  <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={24}>
              {this.state.graphLoading <= 0 ? (this.state.graphData.length > 0 ? (
                <Grid item xs={12} style={{height:'440px'}}>
                  <ResponsiveLine
                      data={[
                      {
                        id:"Mandats N-1",                      
                        data:this.state.graphData.map(n=>({
                          x:n.dateStat,
                          y:n.nbMandatsN
                        }))
                      },
                      {
                        id:"Mandats",                      
                        data:this.state.graphData.map(n=>({
                          x:n.dateStat,
                          y:n.nbMandats
                        }))
                      }
                      ]
                      }
                      margin={{
                          "top": 20,
                          "right": 110,
                          "bottom": 80,
                          "left": 60
                      }}
                      xScale={{
                          "type": "point"
                      }}
                      yScale={{
                          "type": "linear",
                          "stacked": false,
                          "min": 0,
                          "max": "auto"
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                          "orient": "bottom",
                          "tickSize": 5,
                          "tickPadding": 5,
                          "tickRotation": -60,
                          "legend": " ",
                          "legendOffset": 36,
                          "legendPosition": "middle"
                      }}
                      axisLeft={{
                          "orient": "left",
                          "tickSize": 5,
                          "tickPadding": 5,
                          "tickRotation": 0,
                          "legend": "Mandats",
                          "legendOffset": -40,
                          "legendPosition": "middle"
                      }}
                      dotSize={10}
                      dotColor="inherit:darker(0.3)"
                      dotBorderWidth={2}
                      dotBorderColor="#ffffff"
                      enableDotLabel={true}
                      dotLabel={e => e.y.toLocaleString('fr')}
                      dotLabelYOffset={-12}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      colors={[
                        "#CCCCCC",
                        "#3D91FF"                     
                      ]}
                      curve="monotoneX"
                      legends={[
                          {
                              "anchor": "top-right",
                              "direction": "column",
                              "justify": false,
                              "translateX": 100,
                              "translateY": 0,
                              "itemsSpacing": 0,
                              "itemDirection": "left-to-right",
                              "itemWidth": 80,
                              "itemHeight": 20,
                              "itemOpacity": 0.75,
                              "symbolSize": 12,
                              "symbolShape": "circle",
                              "symbolBorderColor": "rgba(0, 0, 0, .5)",
                              "effects": [
                                  {
                                      "on": "hover",
                                      "style": {
                                          "itemBackground": "rgba(0, 0, 0, .03)",
                                          "itemOpacity": 1
                                      }
                                  }
                              ]
                          }
                      ]}
                      tooltipFormat={value => Number(value).toLocaleString('fr')}
                  />
                </Grid>
              ):(
                <Grid style={{height:'440px',margin:'0 auto',textAlign:"center"}}>
                    <Warning color="error"/>
                    <Typography variant="overline" color="error">
                      Aucune donnée
                    </Typography>
                </Grid>
              )):(
                <Grid item style={{height:'440px',margin:'0 auto'}}>
                  <Sync className={this.props.classes.loader}/>
                </Grid>
              )}
            </Grid>
          </>
        )
        }
      </div>
      
    );
  }
}
